import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { Card, Container } from "react-bootstrap";
import CommonTable from "../../components/tables/CommonTable";
import { NavLink, useLocation } from "react-router-dom";
import * as queryString from "query-string";

const FavouritePage = () => {

  const [resultCount, setResultCount] = useState(0);
  const location = useLocation();
  const { type } = queryString.parse(location.search);

  const columns = [
    {
      Header: "",
      accessor: "icon",
      type: "icon",
      disableSortBy: true
    },
    {
      Header: "Name",
      accessor: "compoundName",
      type: "link",
      link: `/${type}/edit`,
      stateId: "id"
    },
    {
      Header: "Reference",
      accessor: "reference",
      visibilityName: "reference",
    },
    {
      Header: `${type === "zones" ? "Contents" : "Last Seen"}`,
      accessor: `${type === "zones" ? "contentsCount" : "lastSeen"}`,
      type: `${type === "zones" ? "" : "dateFromNow"}`
    },
    {
      Header: "Hashtags",
      accessor: "hashTags",
      Cell: cell => (<div>
        {cell.value && cell.value.map((tag, i) =>  <NavLink key={i} to={{pathname: '/globalSearch', search: `?searchString=${encodeURIComponent('#' + tag)}`}}><span className='badge bg-primary me-1 my-1 cursor-pointer'>{tag}</span></NavLink>)}
      </div>),
      disableSortBy: true
    }
  ];
  const toFirstLetterUpperCase = (str) => {
    return str.slice(0, 1).toUpperCase() + str.slice(1, str.length);
  };
  return  <React.Fragment>
    <Helmet defer={false}  title={`Favourite ${toFirstLetterUpperCase(type)}`} />
    <Container fluid className="p-0">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h1 className="h3 mb-0">Favourite {toFirstLetterUpperCase(type)} {typeof(resultCount) === 'string' && `(${resultCount})`}</h1>
      </div>
      <Card className="bg-white p-3">
        <CommonTable setCountParent={setResultCount} apiName={`accounts/favourites/${type}`} columns={columns}/>
      </Card>
    </Container>
  </React.Fragment>
}
export default FavouritePage