import { Helmet } from "react-helmet-async";
import CommonTable from "../tables/CommonTable";
import moment from "moment-timezone";
import { NavLink } from "react-router-dom";
import { useState } from "react";
import { Card, Container } from "react-bootstrap";
import InfoBox from "../InfoBox";

const AttendanceHistory = ({ mode }) => {
    const [resultCount, setResultCount] = useState(0);

    const getDuration = (time) => {
        if (time <= 0) return 'Unknown'
        let currentTime = moment.duration(time, "seconds");
        if (currentTime.days() > 0) {
            let hours = currentTime.days() * 24 + currentTime.hours();
            let min = currentTime.minutes();
            return hours + ":" + min;
        }
        return moment({
            h: currentTime.hours(),
            m: currentTime.minutes(),
            s: currentTime.seconds()
        }).format("HH:mm");
    };

    const columns = [[
        {
            Header: "Name",
            accessor: "childName",
            Cell: cell => (
                <div>
                    <NavLink to={{ pathname: `/${cell.row.original.childType === 'person' ? 'people' : `${cell.row.original.childType}s`}/edit`, search: `?id=${cell.row.original.childId}` }}>
                        {cell.row.original.childName + `${cell.row.original.childReference ? ` (${cell.row.original.childReference})` : ''}`}
                    </NavLink>
                    {cell.row.original.childHashTags && cell.row.original.childHashTags.map(item => <span key={item} className="ms-1 badge bg-primary me-1 align-items-center">{item}</span>)}
                </div>
            )
        },
        {
            Header: "Location",
            accessor: "parentName",
            Cell: cell => (
                <div>
                    <NavLink to={{ pathname: `/${cell.row.original.parentType === 'person' ? 'people' : `${cell.row.original.parentType}s`}/edit`, search: `?id=${cell.row.original.parentId}` }}>
                        {cell.row.original.parentName + `${cell.row.original.parentReference ? ` (${cell.row.original.parentReference})` : ''}`}
                    </NavLink>
                    {cell.row.original.parentHashTags && cell.row.original.parentHashTags.map(item => <span key={item} className="ms-1 badge bg-primary me-1 align-items-center">{item}</span>)}
                </div>
            )
        },
        {
            Header: "Start",
            accessor: "start",
            Cell: cell => (
                <span>{moment(cell.value).format("DD/MM/YY HH:mm")}</span>
            )
        },
        {
            Header: "End",
            accessor: "end",
            Cell: cell => (
                <span>{moment(cell.value).format("DD/MM/YY HH:mm")}</span>
            )
        },
        {
            Header: "Duration",
            accessor: "duration",
            Cell: cell => (
                <span>{getDuration(cell.value)}</span>
            )
        }
    ], [
        {
            Header: "Name",
            accessor: "childName",
            Cell: cell => (
                <div>
                    <NavLink to={{ pathname: `/${cell.row.original.childType === 'person' ? 'people' : `${cell.row.original.childType}s`}/edit`, search: `?id=${cell.row.original.childId}` }}>
                        {cell.row.original.childName + `${cell.row.original.childReference ? ` (${cell.row.original.childReference})` : ''}`}
                    </NavLink>
                    {cell.row.original.childHashTags && cell.row.original.childHashTags.map(item => <span key={item} className="ms-1 badge bg-primary me-1 align-items-center">{item}</span>)}
                </div>
            )
        },
        {
            Header: "Location",
            accessor: "parentName",
            Cell: cell => (
                <div>
                    <NavLink to={{ pathname: `/${cell.row.original.parentType === 'person' ? 'people' : `${cell.row.original.parentType}s`}/edit`, search: `?id=${cell.row.original.parentId}` }}>
                        {cell.row.original.parentName + `${cell.row.original.parentReference ? ` (${cell.row.original.parentReference})` : ''}`}
                    </NavLink>
                    {cell.row.original.parentHashTags && cell.row.original.parentHashTags.map(item => <span key={item} className="ms-1 badge bg-primary me-1 align-items-center">{item}</span>)}
                </div>
            )
        },
        {
            Header: "Start",
            accessor: "start",
            Cell: cell => (
                <span>{moment(cell.value).format("DD/MM/YY HH:mm")}</span>
            )
        }
    ]];

    const title = (mode === 'hist') ? 'Attendance History Report' : 'Live Attendance Report';

    return <>
        <Helmet defer={false} title={title} />
        <Container fluid className="p-0">
            <div className="d-flex justify-content-between align-items-center mb-3">
                <h3 className="mb-3">{title} ({resultCount})</h3>
                <InfoBox title="Info">
                    {mode === 'hist' && <>
                        <p>An attendance is the record created that shows presence of an asset or a person within a zone or onboard a vehicle. An attendance is automatically completed when the asset or person leaves zone or vehicle and this is detected by the system.</p>
                        <p>This report includes all completed attendances for assets and people within all zones or vehicles which start within, end within or span the selected date range.</p>
                    </>}
                    {mode === 'live' && <>
                        <p>An attendance is the record created that shows presence of an asset or a person within a zone or onboard a vehicle. An attendance remains live while the asset or person stays within the zone or onboard the vehicle.</p>
                        <p>This report includes all current live attendances for assets and people.</p>
                    </>}
                </InfoBox>
            </div>
            <Card className="bg-white p-3">
                {mode === 'hist' && <CommonTable showExport showRange={true} initDate={true} setCountParent={setResultCount} columns={columns[0]} apiName='reports/allattendancehist'></CommonTable>}
                {mode === 'live' && <CommonTable showExport setCountParent={setResultCount} columns={columns[1]} apiName='reports/allattendancelive'></CommonTable>}
            </Card>
        </Container>
    </>
}

export default AttendanceHistory;
