import React, { useEffect, useState } from "react";
import { THEME_PALETTE_LIGHT as palette } from "../../constants";
import { Line } from "react-chartjs-2";
import { Card, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import api from "../../api";
import moment from "moment-timezone";
import LoadingSpin from "../loadingSpin";
import { Maximize } from "react-feather";
import { useNavigate } from "react-router-dom";
import useSrlHelper from "../../hooks/useSrlHelper";

const AssetBatteryVoltage = ({ assetId, asset }) => {

  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [duration, setDuration] = useState('');
  const [assetName, setAssetName] = useState("");
  const navigate = useNavigate();
  const { itemDisplayName } = useSrlHelper();

  useEffect(() => {
    if (asset)
    {
      setAssetName(itemDisplayName(asset));
    }
    getData(1);
  }, [assetId]);

  const getData = (page, value) => {
    let labelArr = [];
    let dataArr = [];
    let res = [];
    let startDate = "";
    let endDate = "";
    setLoading(true)
    if (value === "twoWeek") {
      startDate = moment().subtract(14, "day").startOf("days").tz("Europe/London").format("YYYY-MM-DD HH:mm");
      endDate = moment().endOf("days").tz("Europe/London").format("YYYY-MM-DD HH:mm");
    } else if (value === "month") {
      startDate = moment().subtract(30, "day").startOf("days").tz("Europe/London").format("YYYY-MM-DD HH:mm");
      endDate = moment().endOf("days").tz("Europe/London").format("YYYY-MM-DD HH:mm");
    } else {
      startDate = moment().subtract(7, "day").startOf("days").tz("Europe/London").format("YYYY-MM-DD HH:mm");
      endDate = moment().endOf("days").tz("Europe/London").format("YYYY-MM-DD HH:mm");
    }
    setDuration(value)
    api.get(`assets/supply/${assetId}?page=${page}&startDate=${startDate}&endDate=${endDate}`).then(r => {
      res = r.data;
      res.sort((a, b) => b.dateTime - a.dateTime);
      //res.reverse();
      res.forEach(item => {
        // labelArr.push(moment(item.dateTime).format("YYYY-MM-DD HH:mm:ss"));
        labelArr.push(moment(item.dateTime));
        dataArr.push(item.supply);
      });
      let data = {
        type: "line",
        labels: labelArr,
        datasets: [{
          label: 'supply',
          data: dataArr,
          borderColor: palette.primary,
          pointRadius: 0.3,
          borderWidth: 1
        }]
      };
      setResult(r.data && r.data.length > 0 ? data : null);
      setLoading(false)
    }).catch(() => setLoading(false))
  };

  const options = {
    responsive: true,
    legend: {
      display: false,
      labels: {
        display: true,
        boxWidth: 12,
        fontColor: "#A9ACB3"
      }
    },
    maintainAspectRatio: false,
    scales: {
      yAxes: [
        {
          gridLines: {
            display: true
          },
          ticks: {
            beginAtZero: true,
          },
        }
      ],
      xAxes: [
        {
          type: "time",
          gridLines: {
            display: true
          },
          time: {
            unit: "day",
            unitStepSize: 1,
            displayFormats: {
              millisecond: 'DD MMM',
              second: 'DD MMM',
              minute: 'DD MMM',
              hour: 'DD MMM',
              day: 'DD MMM',
              week: 'DD MMM',
              month: 'DD MMM',
              quarter: 'DD MMM',
              year: 'DD MMM',
            }
          }
        }
      ]
    }
  };

  return <React.Fragment>
    <Card className="border flex-grow-1">
      <Card.Header className="pb-0">
        <Card.Title>
          <div className="d-flex justify-content-between align-items-center">
            <span>Asset Battery Voltage</span>
            <Form.Select
              size={'sm'}
              style={{ width: '120px' }}
              defaultValue="oneWeek"
              onChange={(e) => getData(1, e.target.value)}
            >
              <option value="oneWeek">Last 7 days</option>
              <option value="twoWeek">Last 14 days</option>
              <option value="month">Last 30 days</option>
            </Form.Select>
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip id="button-tooltip-1">Expand</Tooltip>}
            >
              <Maximize size={18} className="cursor-pointer ms-2" onClick={() => navigate(`/batteryVoltage?id=${assetId}&name=${encodeURIComponent(assetName.toString())}&date=${encodeURIComponent(duration)}`)} />
            </OverlayTrigger>
          </div>
        </Card.Title>
      </Card.Header>
      <Card.Body className="p-1" style={{ minHeight: '200px' }}>
        {!result && !loading && <div className="h-100 w-100 d-flex align-items-center justify-content-center">Waiting for data</div>}
        {result && !loading && <Line data={result} height={200} options={options} />}
        {loading && <div className="d-flex h-100 w-100 justify-content-center align-items-center"><LoadingSpin loading={loading} /></div>}
      </Card.Body>
    </Card>
  </React.Fragment>;
};
export default AssetBatteryVoltage;