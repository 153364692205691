import React, { useContext, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Button, Card, Container, Modal } from "react-bootstrap";
import CommonTable from "../../components/tables/CommonTable";
import api from "../../api";
import NotyfContext from "../../contexts/NotyfContext";
import moment from "moment-timezone";
import ScheduleTaskModal from "./ScheduleTaskModal";
import { Square, X } from "react-feather";
import LogTask from "./LogTask";
import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";

const TaskScheduled = ({type}) => {
  const navigate = useNavigate();
  const [resultCount, setResultCount] = useState(0);
  const [current, setOld] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [optionsStr, setOptionStr] = useState([]);
  const duration = [moment().startOf('days'), moment().add(90, "day").endOf('days'),];
  const [completeModal, setCompleteModal] = useState(false);
  const [currentAction, setCurrent] = useState("");
  const childRef = useRef();
  const notify = useContext(NotyfContext);
  const {user} = useAuth()

  useEffect(() => {
    if(type && user && !user[type]) {
      navigate('/')
    }
  }, [user, type])

  const columns = [
    {
      Header: "Name",
      accessor: "name",
      Cell: cell => (
        <span onClick={() => btnClick('Edit', cell.row.original)} className="text-primary cursor-pointer">{cell.value}</span>
      )
    },
    {
      Header: "Description",
      accessor: "description",
      Cell: (cell) => <div className="text-nowrap overflow-hidden" title={cell.value}
                           style={{ textOverflow: "ellipsis", minWidth: 0 }}>{cell.value}</div>
    },
    {
      Header: "Due Date",
      accessor: "due",
      Cell: (cell) => <div>
        <span>{moment(cell.value).format("DD/MM/YY HH:mm")}</span>
      </div>
    },
    {
      Header: "Asset/Zone",
      accessor: "parentName",
      accessorName: "parentName",
      type: "linkStatus",
      navigateId: 'parentId',
      typeName: 'parentType',
      reference: 'parentReference',
    },
    {
      Header: "Assigned to",
      accessor: "childName",
      accessorName: "childName",
      type: "linkStatus",
      navigateId: 'childId',
      reference: 'childReference',
      typeName: 'childType',
    }
  ];

  const btnClick = (name, id) => {
    setOld(id);
    setCurrent("View");
    setShowModal(true);
  };

  const handleEdit = () => {
    setCurrent('Edit')
  }

  const handleComplete = () => {
    setCompleteModal(false);
    api.post(`tasks/complete`, {
      id: current?.id,
      statusStrings: optionsStr,
      completed: moment().format()
    }).then(res => {
      notify.open({
        type: "success",
        message: "Changes Saved"
      });
      childRef.current.updateTable(1);
    });
  };

  const pickOption = (str) => {
    setOptionStr(prevState => {
      let arr = prevState.concat([])
      if (arr.filter(item => item === str).length === 0) arr.push(str);
      return arr;
    })
  }

  const onDeleteOption = (str) => {
    setOptionStr(prevState => {
      let arr = prevState.concat([])
      return arr.filter(item => item !== str);
    })
  }

  const handleSuccess = () => {
    notify.open({
      type: "success",
      message: "Changes Saved"
    });
    if (currentAction === "Edit") {
      childRef.current.updateTable();
    } else {
      childRef.current.updateTable(1);
    }
    setShowModal(false);
  };


  return (
    <React.Fragment>
      <Helmet defer={false}  title="Scheduled Tasks" />
      <Container fluid className="p-0">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h1 className="h3 mb-0"><Square className='me-2' />Scheduled Tasks {typeof(resultCount) === 'string' && `(${resultCount})`}</h1>
          <div>
            <LogTask/>
            {user?.scheduleTasks && <Button onClick={() => {
              setOld(null);
              setCurrent("Create");
              setShowModal(true);
            }}>Schedule a Task</Button>}
          </div>
        </div>
        <Card className="bg-white p-3">
          <CommonTable sort={[{id: 'due', desc: false}]} duration={duration} showRange cssClass="table-fixed"  ref={childRef} setCountParent={setResultCount} btnClick={btnClick}
                       apiName="tasks/scheduled" columns={columns} />
        </Card>
      </Container>
      <ScheduleTaskModal handleComplete={() => {
        setShowModal(false);
        setOptionStr([]);
        setCompleteModal(true);
      }} handleEdit={handleEdit} handleSuccess={handleSuccess} closeModal={() => setShowModal(false)} show={showModal}
                         action={currentAction} current={current} apiName="tasks/schedule" />
      <Modal show={completeModal} onHide={() => setCompleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Complete a Task</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>{`Please select completed options:`}</span>
          <div className="mt-2">
            {current?.optionStrings && current?.optionStrings.map((item, index) => <Button variant="light" onClick={() => pickOption(item)} key={index} className="me-2">{item}</Button>)}
          </div>
          {optionsStr.length > 0 && <div className="mt-4">
            <h6>Completed Options:</h6>
            <div className="d-flex">
              {optionsStr.map((item, index) => <div key={index} style={{borderRadius: '2px'}} className="position-relative me-1 pt-1 pb-1 ps-3 pe-3 bg-success text-black">
                <span>{item}</span>
                <X size="12" onClick={() => {onDeleteOption(item)}} className="position-absolute text-dark cursor-pointer" style={{top: '2px', right: '2px'}} />
              </div>)}
            </div>
          </div>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={() => handleComplete()}>Complete</Button>
          <Button variant="secondary" onClick={() => setCompleteModal(false)}>Cancel</Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};
export default TaskScheduled;