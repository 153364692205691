import React from "react";
import { Outlet } from "react-router-dom";

import Wrapper from "../components/Wrapper";
import Sidebar from "../components/sidebar/Sidebar";
import Main from "../components/Main";
import Navbar from "../components/navbar/Navbar";
import Content from "../components/Content";
import Footer from "../components/Footer";

import dashboardItems from "../components/sidebar/dashboardItems";
import dashboardRootItems from "../components/sidebar/dashboardRootListItem";
import useAuth from "../hooks/useAuth";

// layout after login
const Dashboard = ({ children }) => {
  const { isAuthenticated, isInitialized, user } = useAuth();
  return (
    <React.Fragment>
      <Wrapper>
        {/* Root users will have a different menu */}
        {isInitialized && isAuthenticated && user.role && user.role === 'Root' && <Sidebar items={dashboardRootItems}/>}
        {/* for Admin and normal user */}
        {isInitialized && isAuthenticated && user.role && user.role !== 'Root' && <Sidebar items={dashboardItems} />}
        {!isInitialized || !isAuthenticated && <Sidebar items={[]} />}
        <Main>
          <Navbar />
          <Content>
            {children}
            <Outlet />
          </Content>
          <Footer />
        </Main>
      </Wrapper>
    </React.Fragment>
  );
};

export default Dashboard;
