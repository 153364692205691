import { useEffect, useState } from "react";
import { globalConfig } from "../config";

function useSettingsState(key, initialValue) {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    // Set data attribute on body element
    document.body.dataset[key] = value;

    // Replace style sheet if dark theme gets toggled
    if (key === "theme") {
      // const theme = value === "dark" ? "dark" : "light";
      const stylesheet = document.querySelector(".js-stylesheet");
      stylesheet.setAttribute("href", Object.keys(globalConfig.themeList).includes(value) ? `/css/${value}.css` : `/css/default.css`);
    }
  }, [value, key]);

  return [value, setValue];
}

export default useSettingsState;
