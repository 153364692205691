import { useCallback, useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { Button, Col, Row, Stack, Spinner, Alert, Form, Container } from "react-bootstrap";
import Webcam from "react-webcam";
import { globalConfig } from "../config";

const ANPR = ({ onMatch }) => {
    const [base64Image, setBase64Image] = useState(''); // Your base64 image data
    const [plateNumber, setPlateNumber] = useState('');
    //    const [manualPlateNumber, setManualPlateNumber] = useState('');
    const [manualEntry, setManualEntry] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showRescanMsg, setShowRescanMsg] = useState(false);
    const [error, setError] = useState('');
    const [recaptured, setRecaptured] = useState(false);
    const [yesPressed, setYesPressed] = useState(false);
    const webcamRef = useRef(null);
    const inputRef = useRef(null);

    const formDataFromImage = useCallback((imageSrc) => {
        // Convert base64 to Blob
        const byteCharacters = window.atob(imageSrc.split(',')[1]);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'image/jpeg' }); // Adjust the type accordingly

        // Create FormData and append the Blob as a file
        const formData = new FormData();
        formData.append("imageFile", blob, "file");

        return formData;
    }, []);

    const submitCapture = useCallback((imageSrc) => {
        const matchNumberPlate = (foundStrings) => {
            const regEx = /(?<Current>^[A-Z]{2}[0-9]{2}[A-Z]{3}$)|(?<Prefix>^[A-Z][0-9]{1,3}[A-Z]{3}$)|(?<Suffix>^[A-Z]{3}[0-9]{1,3}[A-Z]$)|(?<DatelessLongNumberPrefix>^[0-9]{1,4}[A-Z]{1,2}$)|(?<DatelessShortNumberPrefix>^[0-9]{1,3}[A-Z]{1,3}$)|(?<DatelessLongNumberSuffix>^[A-Z]{1,2}[0-9]{1,4}$)|(?<DatelessShortNumberSufix>^[A-Z]{1,3}[0-9]{1,3}$)|(?<DatelessNorthernIreland>^[A-Z]{1,3}[0-9]{1,4}$)|(?<DiplomaticPlate>^[0-9]{3}[DX]{1}[0-9]{3}$)/g;

            const checked = foundStrings.reduce((p, v, i, a) => {
                const noWhitespace = v.replaceAll(/\s/g, '');
                // const e = regEx.exec(noWhitespace);
                const result = [...noWhitespace.matchAll(regEx)];
                if (result[0] !== undefined) {
                    p.matched.push(noWhitespace);
                }
                else {
                    p.noMatch.push(noWhitespace);
                }
                return p;
            }, { matched: [], noMatch: [] });

            const swapChar = (v, i) => {
                switch (i) {
                    case 0:
                    case 1:
                    case 4:
                    case 5:
                    case 6:
                        if (v === '0') return 'O';
                        else return v;

                    case 2:
                    case 3:
                        if (v === 'I') return '1';
                        else if (v === 'O') return '0';
                        else return v;

                    default:
                        return v;
                }
            };

            // Check the "noMatch" items to see if we can automatically correct misreads, i.e. change O to 0 or I to 1
            if (checked.noMatch.length > 0) {
                checked.noMatch.forEach(element => {
                    if (element.length === 7) {
                        var charArrayIn = element.split('');
                        var charArryrOut = charArrayIn.map((v, i) => {
                            return swapChar(v, i);
                        });
                        var newValue = charArryrOut.join('');

                        // check the corrected value against the regex for current number plates
                        const result = [...newValue.matchAll(/^[A-Z]{2}[0-9]{2}[A-Z]{3}$/g)];
                        if (result[0] !== undefined) {
                            checked.matched.push(newValue);
                        }
                    }
                });
            }

            if (checked.matched.length === 1) {
                setPlateNumber(checked.matched[0])
            } else {
                setShowRescanMsg(true);
            }
        };

        try {
            setLoading(true);
            setPlateNumber('');
            setError('');

            fetch('https://api.cloudmersive.com/ocr/photo/toText',
                {
                    method: 'POST',
                    headers: {
                        'recognitionMode': 'Normal',
                        'language': 'ENG',
                        'preprocessing': 'Auto',
                        'Apikey': globalConfig.cloudmersiveApiKey,
                    },
                    body: formDataFromImage(imageSrc),
                    mimeType: 'multipart/form-data',
                    mode: 'cors',
                })
                .then(response => {
                    if (!response.ok) { throw response; }
                    return response.json();
                })
                .then(data => {
                    setLoading(false);
                    if (data.TextResult !== undefined) {
                        matchNumberPlate(data.TextResult.split('\n'));
                    }
                    else {
                    }
                })
                .catch(error => {
                    console.error('Error:', error);
                    setLoading(false);
                    setError(error);
                });
        } catch (error) {
            setLoading(false);
            setError(error);
        }

    }, [formDataFromImage, base64Image]);

    const capture = useCallback(
        (e) => {
            const imageSrc = webcamRef.current.getScreenshot({ width: 320, height: 320 });

            setBase64Image(imageSrc);
            setPlateNumber('');
            submitCapture(imageSrc);
        },
        [webcamRef, submitCapture]
    );

    const resetCapture = useCallback(() => {
        setBase64Image('');
        // setFound([]);
        setShowRescanMsg(false);
        setPlateNumber('');
        setManualEntry(false);
        setRecaptured(true);
    }, [setBase64Image]);

    const selectPlate = (plate) => {
        onMatch(plate, base64Image);
    };

    useEffect(() => {
        if (manualEntry && inputRef.current) {
            inputRef.current.focus();
        }
    }, [manualEntry]);

    return (
        <>
            <Container fluid className="p-1">
                <Row className="m-0">
                    <Col className="" lg={{ span: 8, offset: 2 }} xl={{ span: 6, offset: 3 }}>
                        <Stack gap={2}>
                            {loading ?
                                <div className="d-flex justify-content-center">
                                    <Spinner animation="border" size="lg" />
                                </div>
                                :
                                <>
                                    {base64Image === '' && plateNumber === '' && !manualEntry &&
                                        <>
                                            <Webcam
                                                audio={false}
                                                ref={webcamRef}
                                                screenshotFormat="image/jpeg"
                                                screenshotQuality={0.4}
                                                forceScreenshotSourceSize={true}
                                                disablePictureInPicture={true}
                                                videoConstraints={{
                                                    width: 320,
                                                    height: 320,
                                                    facingMode: "environment"
                                                }}
                                            />
                                            <Button size="lg" onClick={capture}>Capture number plate</Button>
                                            <Button
                                                variant="danger"
                                                onClick={
                                                    (e) => {
                                                        setManualEntry(true);
                                                    }
                                                }>
                                                Manually enter
                                            </Button>
                                        </>
                                    }
                                    {(plateNumber !== '' || manualEntry) &&
                                        <div className="d-flex justify-content-center">
                                            <div className="number-plate rounded ">
                                                &nbsp;{plateNumber}&nbsp;
                                            </div>
                                        </div>
                                    }
                                    {manualEntry &&
                                        <div className="d-flex justify-content-center">
                                            <Form.Group className="mb-3"
                                                controlId="manualPlateNumber"
                                                style={{ "fontFamily": "monospace", "width": "340px" }}>
                                                <Form.Control
                                                    type="text"
                                                    value={plateNumber}
                                                    maxLength={7}
                                                    placeholder="Enter registration plate"
                                                    style={{ "fontSize": "1.5em", "textAlign": "center" }}
                                                    ref={inputRef}
                                                    onChange={
                                                        (e) => {
                                                            const newValue = e.target.value.toUpperCase();
                                                            if (/^([A-Z0-9]{0,7})$/.test(newValue))
                                                                setPlateNumber(newValue);
                                                        }
                                                    }
                                                />
                                            </Form.Group>
                                        </div>
                                    }
                                    {(showRescanMsg && !manualEntry) &&
                                        <div className="d-flex justify-content-center">
                                            <div>No registration plate found, please recapture or manually enter.</div>
                                        </div>
                                    }
                                    {plateNumber !== '' &&
                                        <div className="d-flex justify-content-center">
                                            <div>Is this registration plate correct?</div>
                                        </div>
                                    }
                                    <div className="d-flex justify-content-around">
                                        {(base64Image !== '' && !recaptured) &&
                                            <>
                                                <Button variant="danger" onClick={resetCapture} disabled={loading}>Recapture</Button>
                                                {/* <img src={base64Image} alt="Preview" /> */}
                                            </>
                                        }
                                        {(base64Image !== '' && !manualEntry) &&
                                            <>
                                                <Button
                                                    variant="danger"
                                                    onClick={
                                                        (e) => {
                                                            setManualEntry(true);
                                                        }
                                                    }>
                                                    Manually enter
                                                </Button>
                                            </>
                                        }
                                        {plateNumber !== '' && <Button variant="success" disabled={yesPressed} onClick={(e) => { setYesPressed(true); selectPlate(plateNumber); }} >Yes</Button>}
                                    </div>
                                    {error !== '' &&
                                        <>
                                            <Alert variant="danger">{error}</Alert>
                                        </>
                                    }
                                </>
                            }
                        </Stack>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default ANPR;
