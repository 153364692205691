import React from "react";
import { Card, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { globalConfig } from "../../config";

const Call = () => {
  const supportPhone = globalConfig.supportPhone;
  const supportPhoneRef = "tel:" + globalConfig.supportPhone.replace(/ /g,"");

  return <React.Fragment>
    <Col lg={4} className="mb-4">
      <Card className="flex-fill w-100 h-100 p-3">
        <h4>Call Us</h4>
        <div>
          <p className="mb-0">Telephone support is available in UK business hours.</p>
          <div>
            <a className="btn btn-default" href={supportPhoneRef}><FontAwesomeIcon className="text-primary" icon={faPhone} />&nbsp;{supportPhone} »</a>
          </div>
        </div>
      </Card>
    </Col>
  </React.Fragment>;
};
export default Call;