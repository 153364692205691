import React, { useEffect, useRef, useState } from "react";
import { Alert, Card, Col, Table } from "react-bootstrap";
import api from "../../api";
import { Pie } from "react-chartjs-2";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare } from "@fortawesome/free-solid-svg-icons";

const TagAssignmentCountWidget = ({ name, width }) => {
    const [tableData, setTableData] = useState();
    const [pieData, setPieData] = useState();
    const [error, setError] = useState(undefined);
    const loadPromise = useRef(null);
    const controller = new AbortController();

    const loadData = () => {
        if (loadPromise.current === null) {
            // setTableData([]);
            // setPieData({});
            setError(undefined);
            loadPromise.current = api.get('reports/tagassignmentcount', { signal: controller.signal })
                .then((response) => {
                    if (response.status === 200) {
                        loadTableData(response.data);
                        loadPieData(response.data);
                    }
                    else {
                        setError(response.message);
                    }
                })
                .catch((reason) => {
                    setError(reason.message ?? reason ?? "error");
                })
                .finally(() => {
                    loadPromise.current = null;
                });
        }
    };

    const loadTableData = (data) => {
        let tableDataIn = [];
        tableDataIn.push({ name: "Assigned", count: data.assignedCount, showLegend: true });
        tableDataIn.push({ name: "Unassigned", count: data.unassignedCount, showLegend: true });
        tableDataIn.push({ name: "Total", count: data.assignedCount + data.unassignedCount, showLegend: false });

        setTableData(tableDataIn);
    };

    const loadPieData = (data) => {
        var labelArr = [];
        var dataArr = [];
        var backgroundColorArr = [];

        labelArr.push("Assigned");
        dataArr.push(data.assignedCount);
        backgroundColorArr.push("#3B82EC");

        labelArr.push("Unassigned");
        dataArr.push(data.unassignedCount);
        backgroundColorArr.push("#F0AD4E");

        var pieDataIn = {
            labels: labelArr,
            datasets: [
                {
                    data: dataArr,
                    backgroundColor: backgroundColorArr,
                }
            ]
        };
        setPieData(pieDataIn);
    };

    useEffect(() => {
        loadData();

        let intervalId = setInterval(() => { loadData(); }, 5000);

        return (() => {
            controller.abort();

            if (intervalId) {
                clearInterval(intervalId);
            }
        });
    }, []);

    const options = {
        tooltips: {
            callbacks: {
                label: function (tooltipItem, data) {
                    let dataset = data.datasets[tooltipItem.datasetIndex];
                    let name = data.labels[tooltipItem.index];
                    let currentValue = dataset.data[tooltipItem.index];
                    let percentage = (parseFloat(currentValue) / parseFloat(tableData[2].count) * 100.0).toFixed(1);
                    return name + ': ' + percentage + '% (' + currentValue + ')';
                }
            }
        },
        animation: {
            animateRotate: true,
            animateScale: false,
        },
        plugins: {
            datalabels: {
                display: false
            }
        },
        maintainAspectRatio: false,
        cutoutPercentage: 70,
        legend: {
            display: false
        }
    };

    const getNavLinkAttributes = (name) => {
        let assignmentLinkValue = name.toLowerCase();
        if (assignmentLinkValue === "total") assignmentLinkValue = undefined;

        if (assignmentLinkValue) {
            return ({
                to: `/tags?assignment=${assignmentLinkValue}`
            });
        }
        else {
            return ({
                to: "/tags"
            });
        }
    };

    return (
        <>
            <Col key={name} lg={(width / 100 * 12).toFixed(0)} className="mb-4">
                <Card className="flex-fill w-100 h-100" style={{ minHeight: "210px" }}>
                    <Card.Title className="p-3 my-0"><h4 className="m-0">Tag Assignment Count</h4></Card.Title>
                    <Card.Text className="p-3 pt-0 mt-0" as="div">
                        {
                            error ?
                                <>
                                    <Alert variant="danger">
                                        <Alert.Heading>Unable to load data</Alert.Heading>&nbsp;
                                        <div>({error})</div>
                                    </Alert>
                                </>
                                :
                                tableData && pieData &&
                                <>
                                    <div className="chart chart-xs pb-3">
                                        <Pie data={pieData} options={options} />
                                    </div>
                                    <Table striped responsive="sm" className="dataTable">
                                        <thead>
                                            <tr role="row">
                                                <th>Assigned / Unassigned</th>
                                                <th>Count</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {tableData.map((row, i) => {
                                                return (
                                                    <tr key={row.name} className="default_row">
                                                        <td>
                                                            {row.showLegend &&
                                                                <>
                                                                    <FontAwesomeIcon icon={faSquare} style={{ color: pieData.datasets[0].backgroundColor[i] }} />
                                                                    &nbsp;
                                                                </>
                                                            }
                                                            <NavLink {...getNavLinkAttributes(row.name)}>{row.name}</NavLink>
                                                        </td>
                                                        <td>{row.count}</td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </Table>
                                </>
                        }
                    </Card.Text>
                </Card>
            </Col>
        </>
    );
};

export default TagAssignmentCountWidget;
