import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { globalConfig } from "../config";
import SearchBox from "../pages/maps/components/SearchBox";
import GoogleMapReact from "google-map-react";
import { imageSet } from "../pages/icons/AssetIcon/IconList";
import { getUrl } from "../utils/staticMethods";
import defaultImg from "../assets/img/icon/Aircraft/Airliner_Black.png";
import api from "../api";
import NotyfContext from "../contexts/NotyfContext";
// import GroupSelector from "./groupSelector/GroupSelector";

const EditPosition = ({ onHideModal, show, data, apiName }) => {
  const [mapInstance, setInstance] = useState(null);
  const [mapApi, setApi] = useState(null);
  const [loading, setLoading] = useState(false);
  const searchBox = useRef();
  const notify = useContext(NotyfContext);
  const [originalData, setOriginalData] = useState({});
  const [showDialog, setShowDialog] = useState(false);
  // const [center, setCenter] = useState(null);

  useEffect(() => {
    if (data?.lastPosition?.latitude && data?.lastPosition?.longitude && mapInstance) {
      setOriginalData({ latitude: Number(data.lastPosition.latitude), longitude: Number(data.lastPosition.longitude) });
      mapInstance.panTo({ lat: Number(data.lastPosition.latitude), lng: Number(data.lastPosition.longitude) });
      mapInstance.setZoom(16);
    }
  }, [mapInstance]);

  useEffect(() => {
    if (!show) {
      setInstance(null);
      setApi(null);
      setOriginalData({});
    }
  }, [show]);

  const getMapOptions = (maps) => {
    if (mapInstance && mapApi) return;
    return {
      mapId: "90f87356969d889c",
      fullscreenControl: true,
      mapTypeControl: true,
      mapTypeId: maps.MapTypeId.ROADMAP,
      scaleControl: true,
      heading: 0,
      gestureHandling: "cooperative",
      streetViewControl: true,
      minZoom: 1,
      mapTypeControlOptions: {
        position: maps.ControlPosition.LEFT_BOTTOM
      }
    };
  };

  const apiHasLoaded = (map, maps) => {
    if (!map || !maps) return;
    setInstance(map);
    setApi(maps);
    maps.event.addListener(map, "mousedown", function (e) {
      if (e?.domEvent?.which === 1) {
        onCreate(e.latLng.lat(), e.latLng.lng());
      }
    });
  };

  const onCreate = (lat, lng) => {
    setOriginalData({ latitude: lat, longitude: lng });
  };

  const onSave = () => {
    setLoading(true);
    setShowDialog(false);
    api.put(`${apiName}/positions/update`, {
      latitude: originalData.latitude.toString(),
      longitude: originalData.longitude.toString(),
      elementId: data?.id
    }).then(r => {
      setLoading(false);
      onHideModal();
      notify.open({
        type: "success",
        message: "Changes Saved"
      });
    });
  };

  // when select a person/asset/zone, change the current marker, show the selected info
  // const onSelect = (e) => {
  //   if ((e.type === "assets" || e.type === "people") && e.lastPosition && e.lastPosition.latitude && e.lastPosition.longitude && mapApi && mapInstance) {
  //     mapInstance.panTo({ lat: Number(e.lastPosition.latitude), lng: Number(e.lastPosition.longitude) });
  //     //setCenter({ lat: Number(e.lastPosition.latitude), lng: Number(e.lastPosition.longitude) });
  //     mapInstance.setZoom(18);
  //   } else if (e.type === "zones" && e.longitude && e.latitude) {
  //     mapInstance.panTo({ lat: Number(e.latitude), lng: Number(e.longitude) });
  //     //setCenter({ lat: Number(e.latitude), lng: Number(e.longitude) });
  //     mapInstance.setZoom(18);
  //   }
  // };

  return <React.Fragment>
    <Modal show={show} onHide={() => onHideModal()}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Position</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {show &&
          <div style={{ width: "100%" }} className="position-relative">
            {/* {mapApi && <div className="position-absolute mobile-style z-100">
              <GroupSelector onSelect={onSelect} />
            </div>} */}
            <GoogleMapReact
              id={"map-google-changePosition"}
              options={getMapOptions}
              style={{ height: "60vh", position: "relative" }}
              bootstrapURLKeys={{
                key: globalConfig.googleMapKey,
                libraries: ["places", "geometry", "drawing", "visualization"]
              }}
              defaultCenter={[
                51.5288684709715,
                -0.10159865243033028
              ]}
              defaultZoom={1}
              yesIWantToUseGoogleMapApiInternals
              onGoogleApiLoaded={({ map, maps }) => apiHasLoaded(map, maps)}
            >
              {mapInstance && mapApi &&
                <SearchBox ref={searchBox} map={mapInstance} mapApi={mapApi} />}
              {mapInstance && mapApi && originalData?.longitude && originalData?.latitude &&
                <GeoMarker item={data}
                  img={imageSet[getUrl(data.icon)] ? require("../assets/img/icon/" + imageSet[getUrl(data.icon)]).default : defaultImg}
                  lat={originalData.latitude} lng={originalData.longitude} />}
            </GoogleMapReact>
          </div>}
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={(originalData?.latitude && originalData?.latitude.toString() === data?.lastPosition?.latitude && originalData?.longitude.toString() === data?.lastPosition?.longitude) || loading}
          variant="primary" onClick={() => setShowDialog(true)}>{loading ? "Saving..." : "Save"}</Button>
        <Button variant="secondary" onClick={() => onHideModal()}>Cancel</Button>
      </Modal.Footer>
    </Modal>
    <Modal
      show={showDialog}
      onHide={() => setShowDialog(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title>Please Confirm</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure that you wish to manually create a new position for this asset? This will alter its location and is not reversible.</p>
        <p>Manually created positions do not include additional information recorded by TracLogik devices, such as speed, battery and supply voltage.</p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => onSave()}>Continue</Button>
        <Button variant="secondary" onClick={() => setShowDialog(false)}>Cancel</Button>
      </Modal.Footer>
    </Modal>
  </React.Fragment>;
};

const GeoMarker = ({ item, img, plan }) => (
  <div style={{ marginLeft: "-12px", marginTop: "-12px" }}>
    <img
      alt={"marker"}
      style={{ height: "24px", width: "24px", cursor: "pointer" }}
      src={img} />
    <div
      className={`position-absolute marker-position-bottom ps-1 pe-1 radius-3 text-nowrap ${plan ? "text-black bg-primary" : "marker-color-black"}`}
      style={{ fontSize: "14px", fontWeight: "bold", transform: "translate(-50%, 50%)" }}>{item?.compoundName}</div>
  </div>);

export default EditPosition;