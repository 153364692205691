import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import CommonTable from "../tables/CommonTable";
import useSrlHelper from "../../hooks/useSrlHelper";

// show assets/people when clicking marker cluster
const MarkerModal = ({tableData, setTableData}) => {
  const { getColumnsForMarkerModal } = useSrlHelper();

  const [tableColumns] = useState(getColumnsForMarkerModal(setTableData));

  return <Modal size="lg" show={tableData} onHide={() => setTableData(null)}>
    <Modal.Header closeButton>
      <Modal.Title>Total Count: {tableData && tableData.length}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <CommonTable data={tableData} columns={tableColumns} />
    </Modal.Body>
    <Modal.Footer>
      <Button onClick={() => setTableData(null)} variant={"secondary"}>Close</Button>
    </Modal.Footer>
  </Modal>
}
export default MarkerModal