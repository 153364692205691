import { useLocation } from "react-router-dom";
import * as queryString from "query-string";
import AssetsEdit from "./AssetsEdit";

const ArchivedAssetsEdit = (props) => {
    const location = useLocation();
    const { id, direct } = queryString.parse(location.search);
  
    return <AssetsEdit id={id} direct={direct} {...props}></AssetsEdit>
};

export default ArchivedAssetsEdit;
