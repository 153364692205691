import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Button, FormControl, InputGroup, Modal, Nav, Tab } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import api from "../../api";
import NotyfContext from "../../contexts/NotyfContext";
import CommonTable from "../../components/tables/CommonTable";
import * as queryString from "query-string";
import useAuth from "../../hooks/useAuth";
import { string } from "yup";

const ContactEdit = () => {

  const location = useLocation();
  const { id } = queryString.parse(location.search);
  const [contactGroup, setContactGroup] = useState();
  const [modalTitle, setModalTitle] = useState(null);
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [team, setTeam] = useState("");
  const notify = useContext(NotyfContext);
  const [name, setName] = useState("");
  const [showDelete, setShowDelete] = useState(null);
  const [curTab, setTab] = useState("email");
  const { user } = useAuth();

  useEffect(() => {
    if (user && !user.showEmailAlerts && user.showSmsAlerts) { setTab('mobile') }
    else if (user && user.showEmailAlerts && !user.showSmsAlerts) { setTab('email') }
    else if (user && !user.showEmailAlerts && !user.showSmsAlerts && user.showTeamsAlerts) { setTab('teams') }
  }, [user])

  const columnsEmail = [
    {
      Header: "Email",
      accessor: "email",
      type: "singleString",
      disableSortBy: true
    },
    {
      Header: "",
      accessor: "id",
      type: "action",
      action: [{ name: "Remove", color: "danger", disabled: !user?.editContacts }],
      disableSortBy: true
    }
  ];

  const columnsMobile = [
    {
      Header: "Mobile",
      accessor: "mobile",
      type: "singleString",
      disableSortBy: true
    },
    {
      Header: "",
      accessor: "id",
      type: "action",
      action: [{ name: "Remove", color: "danger", disabled: !user?.editContacts }],
      disableSortBy: true
    }
  ];

  const columnsURL = [
    {
      Header: "URl",
      accessor: "url",
      type: "singleString",
      disableSortBy: true
    },
    {
      Header: "",
      accessor: "id",
      type: "action",
      action: [{ name: "Remove", color: "danger", disabled: !user?.editContacts }],
      disableSortBy: true
    }
  ];


  useEffect(() => {
    getDetail();
  }, [id]);

  const getDetail = () => {
    api.get(`contactgroups/${id}`).then(res => {
      setContactGroup(res.data);
      setName(res.data.groupName);
    });
  };

  const onEdit = () => {
    const obj = JSON.parse(JSON.stringify(contactGroup));
    obj.groupName = name;
    api.put(`contactgroups`, obj).then(res => {
      notify.open({
        type: "success",
        message: "Changes Saved"
      });
      setModalTitle(null);
      setContactGroup(res.data);
    });
  };
  const onDelete = () => {
    api.delete(`contactgroups/${contactGroup.id}`)
      .then(() => {
        setModalTitle(null);
        window.history.back();
      });
  };

  const btnClick = (name, paramId) => {
    setShowDelete(paramId);
  };

  const onDeleteItem = () => {
    if (curTab === 'teams') {
      api.delete(`contactgroups/${id}/${curTab}/${encodeURIComponent(showDelete)}`)
        .then(() => {
          notify.open({
            type: "success",
            message: "Changes Saved"
          });
          setShowDelete(null);
          getDetail();
        });
      return
    }
    api.delete(`contactgroups/${id}/${curTab}/${showDelete}`)
      .then(() => {
        notify.open({
          type: "success",
          message: "Changes Saved"
        });
        setShowDelete(null);
        getDetail();
      });
  };

  const onSave = (type) => {
    let param = null;
    if (type === "email") {
      if (!string().email().isValidSync(email)) {
        notify.open({
          type: "error",
          message: "Please input a correct email address"
        });
        return;
      }
      param = email;
    } else if (type === "mobile" && (mobile.length === 0 || mobile.match(/^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s/0-9]*$/g) === null)) {
      notify.open({
        type: "error",
        message: "Please input a correct mobile number"
      });
      return;
    } else if (type === "teams" && team.length === 0) {
      notify.open({
        type: "error",
        message: "Please input a correct team url"
      });
      return;
    }
    if (type === "mobile") {
      param = mobile;
    } else if (type === "teams") {
      param = encodeURIComponent(team);
    }
    api.post(`contactgroups/${id}/${type}/${param}`).then(() => {
    }).then(() => {
      notify.open({
        type: "success",
        message: "Changes Saved"
      });
      setEmail("");
      setMobile("");
      setTeam("");
      getDetail();
    });
  };

  return (
    <React.Fragment>
      <Helmet defer={false} title={contactGroup?.groupName || 'Contact Group'} />
      <h3 className="mb-3">{contactGroup?.groupName}</h3>
      <div className="d-flex">
        {user?.editContacts && <Button onClick={() => {
          setModalTitle("Edit Contact Group");
        }}>Edit Name</Button>}
        {user?.deleteContacts && <Button variant="danger" className="ms-1" onClick={() => {
          setModalTitle("Delete Contact Group");
        }}>Delete</Button>}
      </div>
      {(user?.showEmailAlerts || user?.showSmsAlerts || user?.showEmailAlerts) && <div className="tab mt-3">
        <Tab.Container id="left-tabs-example" defaultActiveKey={curTab} activeKey={curTab} onSelect={(e) => setTab(e)}>
          <Nav variant="tabs">
            {user?.showEmailAlerts && <Nav.Item>
              <Nav.Link eventKey="email">Email Address</Nav.Link>
            </Nav.Item>}
            {user?.showSmsAlerts && <Nav.Item>
              <Nav.Link eventKey="mobile">Mobile Numbers</Nav.Link>
            </Nav.Item>}
            {user?.showTeamsAlerts && <Nav.Item>
              <Nav.Link eventKey="teams">Microsoft Teams</Nav.Link>
            </Nav.Item>}
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="email">
              <h4>Email Addresses</h4>
              {user?.editContacts && <InputGroup className="mb-3 mt-4">
                <FormControl
                  placeholder="New Email Address"
                  value={email}
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                />
                <Button onClick={() => onSave("email")}>Save Email</Button>
              </InputGroup>}
              {contactGroup &&
                <CommonTable hideSearch btnClick={btnClick} data={contactGroup.emailAddresses} columns={columnsEmail} />}
            </Tab.Pane>
            <Tab.Pane eventKey="mobile">
              <h4>Mobile Numbers</h4>
              {user?.editContacts && <InputGroup className="mb-3 mt-4">
                <FormControl
                  placeholder="New Mobile Number"
                  value={mobile}
                  type="mobile"
                  onChange={(e) => setMobile(e.target.value)}
                />
                <Button onClick={() => onSave("mobile")}>Save Mobile</Button>
              </InputGroup>}
              {contactGroup &&
                <CommonTable hideSearch btnClick={btnClick} data={contactGroup.mobileNumbers} columns={columnsMobile} />}
            </Tab.Pane>
            <Tab.Pane eventKey="teams">
              <h4>Teams WebHook URLs</h4>
              {user?.editContacts && <InputGroup className="mb-3 mt-4">
                <FormControl
                  placeholder="New URL"
                  value={team}
                  type="text"
                  onChange={(e) => setTeam(e.target.value)}
                />
                <Button onClick={() => onSave("teams")}>Save URL</Button>
              </InputGroup>}
              {contactGroup &&
                <CommonTable hideSearch btnClick={btnClick} data={contactGroup.teamsWebHooks} columns={columnsURL} />}
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>}
      <Modal show={modalTitle} onHide={() => setModalTitle(null)}>
        <Modal.Header closeButton><Modal.Title>{modalTitle && modalTitle.indexOf("Edit") > -1 ? modalTitle : 'Please Confirm'}</Modal.Title></Modal.Header>
        <Modal.Body>
          {modalTitle && modalTitle.indexOf("Edit") > -1 ? <>
            <div>Contact Group Name</div>
            <FormControl className="mb-4" type="text" value={name} onChange={(e) => setName(e.target.value)} /></> :
            <div>
              <p>Are you sure that you wish to permanently delete this Contact Group?</p>
              <p>Deletions are not reversible.</p>
            </div>}
        </Modal.Body>
        <Modal.Footer>
          {modalTitle && modalTitle.indexOf("Edit") > -1 ?
            <Button variant="primary" onClick={() => onEdit()}>Save</Button> :
            <Button onClick={() => onDelete()} variant="danger">Delete</Button>}
          <Button variant={'secondary'} onClick={() => setModalTitle(null)}>Cancel</Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showDelete} onHide={() => setShowDelete(null)}>
        <Modal.Header closeButton><Modal.Title>Please Confirm</Modal.Title></Modal.Header>
        <Modal.Body>
          <p>Are you sure that you wish to permanently delete this {curTab} ?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => onDeleteItem()} variant="danger">Delete</Button>
          <Button variant={'secondary'} onClick={() => setShowDelete(null)}>Cancel</Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default ContactEdit;