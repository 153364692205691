import React from "react";
import { Card, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckSquare,
  faClock,
  faExchangeAlt,
  faGlobe,
  faHourglassHalf,
  faTachometerAlt
} from "@fortawesome/free-solid-svg-icons";
import { imageSet } from "../../pages/icons/AssetIcon/IconList";
import defaultImg from "../../assets/img/icon/Aircraft/Airliner_Black.png";
import { NavLink } from "react-router-dom";
import { dateFromNow, getUrl } from "../../utils/staticMethods";
import useSrlHelper from "../../hooks/useSrlHelper";

const MapDetail = ({ profile, following, onChangeToggle }) => {

  const item = profile.lastPosition;

  const { itemDisplayName, renderBattery, renderSupply } = useSrlHelper();

  let speed;
  if (item?.speed) {
    speed = (
      <div data-toggle="tooltip" title="" data-placement="right">
        <span className="m-2">|</span>
        <FontAwesomeIcon icon={faTachometerAlt} fixedWidth
          className="me-1" /> {`${Math.round((item.speed * 0.6214))} mph`}
        {item.battery && <>
          <span className="m-2">|</span>
          {renderBattery(item.battery)}
        </>}
      </div>
    );
  };

  return (
    <Card className="mb-0">
      <Card.Body className="p-3 ps-2">
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center flex-wrap">
            <div className="text-nowrap">
              <img alt="profile" style={{ width: '25px' }} src={imageSet[getUrl(profile.icon)] ? require("../../assets/img/icon/" + imageSet[getUrl(profile.icon)]).default : defaultImg} />
              <NavLink to={{ pathname: `/${profile.type}/edit`, search: `?id=${profile.id}` }} className="ms-1 me-1">{itemDisplayName(profile)}</NavLink>
              {profile.hashTags && Array.isArray(profile.hashTags) && profile.hashTags.map(item => <NavLink key={item} to={{ pathname: '/globalSearch', search: `?searchString=${encodeURIComponent('#' + item)}` }}><span key={item} className='badge bg-primary me-1 my-1'>{item}</span></NavLink>)}
            </div>
            {profile.type !== 'zones' && item && <>
              <span className="m-2">|</span>
              <div data-toggle="tooltip" title="" data-placement="right" className="text-nowrap">
                <FontAwesomeIcon icon={faClock} fixedWidth className="me-1" /> Reported: {dateFromNow(item.dateTime)}
              </div>
              <span className="m-2">|</span>
              <div data-toggle="tooltip" title="" data-placement="right" className="text-nowrap">
                <FontAwesomeIcon icon={faGlobe} fixedWidth className="me-1" /> Fix: {item?.type === 'manual' ? ' manual' : dateFromNow(item?.lastFix)}
              </div>
              {speed}
              <span className="m-2">|</span>{renderSupply(item.supply)}
              {profile.lastCommand && <div className="text-nowrap">
                <span className="m-2">|</span>
                <FontAwesomeIcon icon={faExchangeAlt} fixedWidth className="me-1 text-nowrap" /> {profile.lastCommand.commandName} &nbsp;
                {profile.lastCommand.status === "Pending" && <FontAwesomeIcon icon={faHourglassHalf} className="me-1 text-nowrap" />}
                {profile.lastCommand.status === "Confirmed" && <FontAwesomeIcon icon={faCheckSquare} className="me-1 text-nowrap" />}
              </div>}
            </>}
          </div>
          {!item && profile.type !== 'zones' && <span>No GPS Position</span>}
          {!item && profile.type === 'zones' && (!profile.latitude || !profile.longitude) && <span>No Geofence</span>}
          {item && profile.type !== 'zones' && <Form.Check type="switch" checked={following} onChange={e => onChangeToggle(e)} label="Follow" />}
        </div>
      </Card.Body>
    </Card>
  );
};

export default MapDetail;
