import { faHeart, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Button, Container } from "react-bootstrap";
import { Package } from "react-feather";
import { Helmet } from "react-helmet-async";
import { NavLink } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import SrlAssetSummary from "../components/SrlAssetSummary";
import SrlCommonTable from "../components/SrlCommonTable";
import SrlNewAsset from "../components/SrlNewAsset";
import { SRL_CATEGORIES } from "../../constants";
import useSrlHelper from "../../hooks/useSrlHelper";

const SrlAssets = () => {
    const [resultCount, setResultCount] = useState(0);
    const [showModal, setShow] = useState(false);
    const [selectedCategoryName, setSelectedCategoryName] = useState(null);
    const [srlCategories] = useState(SRL_CATEGORIES);
    const { user } = useAuth();
    const { getAssetColumnsForCommonTable } = useSrlHelper();
    const [columns] = useState(getAssetColumnsForCommonTable());
    const [sort] = useState([{ id: "category", desc: false }]);

    return (
        <>
            <Helmet defer={false} title="SRL Assets" />
            <Container fluid className="p-0">
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <div className="d-flex align-items-center">
                        <h1 className="h3 mb-0">
                            <Package className="me-2" />&nbsp;{(selectedCategoryName === null) ? "SRL Assets" : selectedCategoryName}{typeof (resultCount) === 'string' && <>&nbsp;{`(${resultCount})`}</>}
                        </h1>
                        {
                            user?.role !== "Root" &&
                            <NavLink to={{ pathname: '/favourite', search: '?type=assets' }}>
                                <FontAwesomeIcon className="ms-4 me-2 text-primary cursor-pointer" icon={faHeart} /><span>Favourites</span>
                            </NavLink>
                        }
                    </div>
                    {
                        (
                            (user?.role === "Admin" || user?.role === "Root")
                            // || user?.createAssets
                        ) &&
                        <Button className="text-nowrap" onClick={() => setShow(true)}>
                            <FontAwesomeIcon icon={faPlus} fixedWidth className="me-1" /> Create New
                        </Button>
                    }
                </div>
                <SrlAssetSummary categories={srlCategories} selectedCategoryName={selectedCategoryName} setSelectedCategoryName={setSelectedCategoryName} apiName="assets" />
                <SrlCommonTable setCountParent={setResultCount} apiName="assets" columns={columns} showExport={true} category={selectedCategoryName} sort={sort} />
            </Container>
            <SrlNewAsset showModal={showModal} closeModal={() => setShow(false)} categories={srlCategories} />
        </>
    );
};

export default SrlAssets;
