import React from "react";

// import { THEME } from "../constants";
import useSettingsState from "../hooks/useSettingsState";
import { globalConfig } from "../config";

const initialState = {
  theme: globalConfig.applicationTheme,
};

const ThemeContext = React.createContext(initialState);

function ThemeProvider({ children }) {
  var initialTheme = globalConfig.applicationTheme;

  const [theme, setTheme] = useSettingsState("theme", initialTheme);

  return (
    <ThemeContext.Provider
      value={{
        theme,
        setTheme,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
}

export { ThemeProvider, ThemeContext };
