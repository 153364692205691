import React, { useEffect, useRef, useState } from "react";
import { Button, Form, FormControl, InputGroup, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import api from "../../api";
import DatetimePicker from "react-bootstrap-daterangepicker";
import moment from "moment-timezone";

const weekList = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
const hourList = ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"];
const minuteList = ["00", "05", "10", "15", "20", "25", "30", "35", "40", "45", "50", "55"];
const graceMinute = ["00", "05", "10", "15", "20", "25", "30"];
const AddRecurringModal = ({ rule, show, onHide, zones, onSuccess, type, data }) => {
  const [dayOfWeek, setDay] = useState(weekList[0]);
  const [startHour, setStartHour] = useState("00");
  const [startMinute, setStartMinute] = useState("00");
  const [endHour, setEndHour] = useState("00");
  const [endMinute, setEndMinute] = useState("00");
  const [rate, setRate] = useState("0");
  const [inEarly, setInEarly] = useState("");
  const [inLate, setInLate] = useState("");
  const [outEarly, setOutEarly] = useState("");
  const [outLate, setOutLate] = useState("");
  const [noShow, setNoShow] = useState("");
  const [duration, setDuration] = useState([moment().startOf("days"), moment().endOf("days")]);

  const [showInEarly, setShowInEarly] = useState(false);
  const [showInLate, setShowInLate] = useState(false);
  const [showOutEarly, setShowOutEarly] = useState(false);
  const [showOutLate, setShowOutLate] = useState(false);
  const [showNoShow, setShowNoShow] = useState(false);
  const [during, setDuring] = useState(true);

  const [gracePeriod, setGrace] = useState("00");
  const [elementId, setElement] = useState();
  const dateRef = useRef();

  useEffect(() => {
    if (data) {
      setDay(data.dayOfWeek);
      setStartHour(moment(data.start).format("HH"));
      setStartMinute(moment(data.start).format("mm"));
      setEndHour(moment(data.end).format("HH"));
      setEndMinute(moment(data.end).format("mm"));
      setRate(data.rate);
      setDuration([moment(data.start), moment(data.end)]);
      dateRef.current && dateRef.current.setStartDate(moment(data.start));
      dateRef.current && dateRef.current.setEndDate(moment(data.end));
      setShowInEarly(data.inEarly);
      setShowInLate(data.inLate);
      setShowOutEarly(data.outEarly);
      setShowOutLate(data.outLate);
      setShowNoShow(data.noShow);
      setDuring(data.during);
      setGrace(data.gracePeriod);
      setElement(data.elementId);
    }
  }, [data]);

  useEffect(() => {
    window.moment = moment;
    if (zones.length > 0) setElement(zones[0].id);
  }, [zones]);

  const saveChange = () => {
    let data1 = {
      ruleId: rule.id,
      elementId,
      gracePeriod,
      inEarly: showInEarly,
      inLate: showInLate,
      outEarly: showOutEarly,
      outLate: showOutLate,
      noShow: showNoShow,
      rate,
      during
    };
    if (type === 1) {
      data1.dayOfWeek = dayOfWeek;
      let start = `2001-01-01T${startHour}:${startMinute}:00`;
      let end = `2001-01-01T${endHour}:${endMinute}:00`;
      data1.start = moment(start).tz("Europe/London").format("YYYY-MM-DDTHH:mm:ss");
      data1.end = moment(end).tz("Europe/London").format("YYYY-MM-DDTHH:mm:ss");
    } else {
      data1.start = duration[0].tz("Europe/London").format("YYYY-MM-DDTHH:mm:ss");
      data1.end = duration[1].tz("Europe/London").format("YYYY-MM-DDTHH:mm:ss");
    }
    if (data) {
      data1.id = data.id;
      api.put(`shifts`, data1).then(() => {
        reset();
        onSuccess();
      });
    } else {
      api.post(`shifts`, data1).then(() => {
        reset();
        onSuccess();
      });
    }
  };

  const reset = () => {
    setDay(weekList[0]);
    setStartHour("00");
    setStartMinute("00");
    setEndHour("00");
    setEndMinute("00");
    setRate("0");
    setShowOutEarly(false);
    setShowNoShow(false);
    setShowInEarly(false);
    setShowInLate(false);
    setShowOutLate(false);
    setDay(weekList[0]);
    setInLate("");
    setInEarly("");
    setOutEarly("");
    setOutLate("");
    setNoShow("");
    setDuration([moment().startOf("days"), moment().endOf("days")]);
    setGrace("00");
    setDuring(true);
  };

  const onApply = (start, end) => {
    setDuration([start, end]);
  };

  return <Modal show={show} onHide={() => {
    reset();
    onHide();
  }} className="unique-class">
    <Modal.Header
      closeButton><Modal.Title>{type === 1 ? "Add a Recurring Period" : "Add a Calendar Period"}</Modal.Title></Modal.Header>
    <Modal.Body>
      {zones && zones.length > 0 && <>
        <div>Destination</div>
        <Form.Select
          className="d-inline-block w-100 mb-3"
          value={elementId}
          onChange={(e) => {
            setElement(e.target.value);
          }}
        >
          {zones.map((item, index) => <option key={item.id} value={item.id}>{item.compoundName}</option>)}
        </Form.Select></>}
      {type === 1 ? <>
        <div>Day of Week</div>
        <Form.Select
          className="d-inline-block w-100"
          value={dayOfWeek}
          onChange={(e) => {
            setDay(e.target.value);
          }}
        >
          {weekList.map((item, index) => <option key={index} value={item}>{item}</option>)}
        </Form.Select>
        <div className="mt-3">Start</div>
        <div className="d-flex align-items-center">
          <Form.Select
            className="d-inline-block w-25"
            value={startHour}
            onChange={(e) => setStartHour(e.target.value)}
          >
            {hourList.map((item, index) => <option key={index} value={item}>{item}</option>)}
          </Form.Select>
          <span>&nbsp; : &nbsp;</span>
          <Form.Select
            className="d-inline-block w-25"
            value={startMinute}
            onChange={(e) => setStartMinute(e.target.value)}
          >
            {minuteList.map((item, index) => <option key={index} value={item}>{item}</option>)}
          </Form.Select>
        </div>
        <div className="mt-3">End</div>
        <div className="d-flex align-items-center">
          <Form.Select
            className="d-inline-block w-25"
            value={endHour}
            onChange={(e) => setEndHour(e.target.value)}
          >
            {hourList.map((item, index) => <option key={index} value={item}>{item}</option>)}
          </Form.Select>
          <span>&nbsp; : &nbsp;</span>
          <Form.Select
            className="d-inline-block w-25"
            value={endMinute}
            onChange={(e) => setEndMinute(e.target.value)}
          >
            {minuteList.map((item, index) => <option key={index} value={item}>{item}</option>)}
          </Form.Select>
        </div>
      </> : <>
        <div>Start and End</div>
        <DatetimePicker
          ref={dateRef}
          initialSettings={{
            parentEl: ".unique-class",
            timePicker: true,
            timePicker24Hour: true,
            startDate: duration[0],
            endDate: duration[1],
            locale: {
              format: "DD/MM/YYYY HH:mm"
            }
          }}
          onCallback={onApply}
        >
          <input type="text" className="form-control w-100" />
        </DatetimePicker></>}

      {rule.type === "attendance" && <>
        <div className="mt-3">Hourly Rate</div>
        <InputGroup className="mb-3">
          <InputGroup.Text id="basic-addon1">£</InputGroup.Text>
          <FormControl
            placeholder="Input a number"
            type="number"
            value={rate}
            onChange={(e) => setRate(e.target.value)}
          />
        </InputGroup>
        <div className="text-black mt-4 mb-2">
          <FontAwesomeIcon icon={faBell}></FontAwesomeIcon>
          <span className="ms-1">Alerts</span>
        </div>
        <InputGroup className="mb-3">
          <InputGroup.Checkbox checked={showInEarly} onChange={e => setShowInEarly(e.target.checked)} />
          <FormControl
            className="calendar-disable"
            placeholder="Early Arrival"
            value={inEarly}
            disabled
            onChange={(e) => setInEarly(e.target.value)}
          />
        </InputGroup>
        <InputGroup className="mb-3">
          <InputGroup.Checkbox checked={showInLate} onChange={e => setShowInLate(e.target.checked)} />
          <FormControl
            className="calendar-disable"
            placeholder="Late Arrival"
            value={inLate}
            disabled
            onChange={(e) => setInLate(e.target.value)}
          />
        </InputGroup>
        <InputGroup className="mb-3">
          <InputGroup.Checkbox checked={showOutEarly} onChange={e => {
            setShowOutEarly(e.target.checked);
          }} />
          <FormControl
            className="calendar-disable"
            placeholder="Early Departure"
            value={outEarly}
            disabled
            onChange={(e) => setOutEarly(e.target.value)}
          />
        </InputGroup>
        <InputGroup className="mb-3">
          <InputGroup.Checkbox checked={showOutLate} onChange={e => setShowOutLate(e.target.checked)} />
          <FormControl
            className="calendar-disable"
            placeholder="Late Departure"
            value={outLate}
            disabled
            onChange={(e) => setOutLate(e.target.value)}
          />
        </InputGroup>
        <InputGroup className="mb-3">
          <InputGroup.Checkbox checked={showNoShow} onChange={e => setShowNoShow(e.target.checked)} />
          <FormControl
            className="calendar-disable"
            placeholder="Non Attendance"
            value={noShow}
            disabled
            onChange={(e) => setNoShow(e.target.value)}
          />
        </InputGroup>
        <div>Grace Period (Mins)</div>
        <Form.Select
          className="d-inline-block w-100"
          value={gracePeriod}
          onChange={(e) => {
            setGrace(e.target.value);
          }}
        >
          {graceMinute.map((item, index) => <option key={index} value={item}>{item}</option>)}
        </Form.Select>
      </>}
      {rule.type !== "attendance" && <>
        <div className="text-black mt-4 mb-2">
          <FontAwesomeIcon icon={faBell}></FontAwesomeIcon>
          <span className="ms-1">Alerts</span>
        </div>
        <div>
          <Form.Check checked={during} onChange={(e) => setDuring(e.target.checked)} type="radio"
                      label="During this period" />
          <Form.Check checked={!during} onChange={e => setDuring(!e.target.checked)} type="radio"
                      label="Outside this period" />
        </div>
      </>}
      <div className="mt-3 mb-2">
        <Button variant="primary" onClick={() => saveChange()}>Save</Button>
        <Button className="ms-2" variant="secondary" onClick={() => onHide()}>Cancel</Button>
      </div>
    </Modal.Body>
  </Modal>;
};
export default AddRecurringModal;