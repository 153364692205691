import React, { useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Button, Card, Container } from "react-bootstrap";
import { faBatteryEmpty, faBatteryFull, faBatteryHalf, faBatteryQuarter, faBatteryThreeQuarters, faHeart, faHourglassHalf, faPlug, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useAuth from "../../hooks/useAuth";
import NewObject from "../../components/detail/NewObject";
import CommonTable from "../../components/tables/CommonTable";
import { MapPin, Moon, Package } from "react-feather";
import moment from "moment-timezone";
import { NavLink } from "react-router-dom";
import { dateFromNow } from "../../utils/staticMethods";

function Assets() {
  const [resultCount, setResultCount] = useState(0);
  const [showModal, setShow] = useState(false);
  const curTime = useRef(moment())
  const { user } = useAuth();

  const columns = [];
  columns.push({
    Header: "",
    accessor: "icon",
    type: "icon",
    disableSortBy: true
  });
  columns.push({
    Header: "Name",
    accessor: "compoundName",
    type: "link",
    link: "/assets/edit",
    stateId: "id"
  });
  columns.push({
    Header: "Reference",
    accessor: "reference",
    visibilityName: "reference",
  });
  columns.push({
    Header: "Last Seen",
    accessor: "lastSeen",
    visibilityName: "last-seen",
    Cell: (cell) => {
      if (cell.value && cell.value.indexOf("0001-01-01") === -1) {
        return <><MapPin width={14} height={14} className="me-1" />{curTime.current ? dateFromNow(cell.value, curTime.current) : dateFromNow(cell.value)}</>;
      }
      return <span><Moon width={14} height={14} className="me-1" /> Waiting...</span>;
    },
  });
  columns.push({
    Header: "Asset Battery",
    accessor: "lastPosition.supply",
    visibilityName: "supply",
    Cell: (cell) => {
      let supply = cell.row?.original?.lastPosition?.supply;

      if (typeof (supply) === "number") {
        let level = '';

        if (supply === 0 || supply === 500) {
          level = 'Disconnected';
        } else if (supply === 1) {
          level = 'Connected';
        } else if (supply > 12250) {
          level = 'High';
        } else if (supply > 11500 && supply <= 12250) {
          level = 'Medium';
        } else if (supply > 0 && supply <= 11500) {
          level = 'Low';
        } else {
          level = 'Waiting for data';
        }

        return (
          <>
            {level === "High" && <FontAwesomeIcon icon={faBatteryFull} className="srl-green" />}
            {level === "Medium" && <FontAwesomeIcon icon={faBatteryHalf} className="srl-canary" />}
            {level === "Low" && <FontAwesomeIcon icon={faBatteryQuarter} className="srl-red" />}
            {level === "Connected" && <FontAwesomeIcon icon={faPlug} className="grey-500" />}
            {level === "Disconnected" && <FontAwesomeIcon icon={faPlug} className="grey-500" />}
            {level === "Waiting for data" && <FontAwesomeIcon icon={faHourglassHalf} className="grey-500" />}
            <span>&nbsp;{level}</span>
            {(["Low", "Medium", "High"].includes(level)) && supply && <span>&nbsp;({supply} Mv)</span>}
          </>
        );
      }
      else {
        return <></>;
      }
    },
    // disableSortBy: true,
  });
  columns.push({
    Header: "Device Battery",
    accessor: "lastPosition.battery",
    visibilityName: "battery",
    Cell: (cell) => {
      let battery = cell.row?.original?.lastPosition?.battery;

      if (typeof(battery) === "number") {
        const getBatteryIcon = (battery) => {
          if (battery >= 10 && battery < 25) {
            return faBatteryQuarter;
          } else if (battery < 10) {
            return faBatteryEmpty;
          } else if (battery >= 25 && battery <= 50) {
            return faBatteryHalf;
          } else if (battery > 50 && battery <= 75) {
            return faBatteryThreeQuarters;
          } else {
            return faBatteryFull;
          }
        };

        return (<>
          <FontAwesomeIcon icon={getBatteryIcon(battery)} fixedWidth className="me-1" /> {`${battery} %`}
        </>);
      } else {
        return (<></>)
      };

    },
    // disableSortBy: true,
  });
  if (user?.role === "Root") {
    columns.push({
      Header: "Customer",
      accessor: "customerName",
      show: user?.role === "Root",
      disableSortBy: true,
      Cell: cell => (<NavLink
        to={{ pathname: `/customers/edit`, search: `?id=${cell.row?.original?.customerId}` }}>{cell.value}</NavLink>)
    });
  }
  columns.push({
    Header: "Hashtags",
    accessor: "hashTags",
    visibilityName: "hash-tags",
    disableSortBy: true,
    Cell: cell => (<div>
      {cell.value && cell.value.map((tag, i) => <NavLink key={tag} to={{ pathname: '/globalSearch', search: `?searchString=${encodeURIComponent('#' + tag)}` }}><span key={i} className='badge bg-primary me-1 my-1 cursor-pointer'>{tag}</span></NavLink>)}
    </div>)
  });


  return (
    <React.Fragment>
      <Helmet defer={false} title="Assets" />
      <Container fluid className="p-0">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <div className="d-flex align-items-center">
            <h1 className="h3 mb-0"><Package className="me-2" /> Assets {typeof (resultCount) === 'string' && `(${resultCount})`}</h1>
            {user?.role !== "Root" && <NavLink to={{ pathname: '/favourite', search: '?type=assets' }}><FontAwesomeIcon className="ms-4 me-2 text-primary cursor-pointer" icon={faHeart} /><span>Favourites</span></NavLink>}
          </div>
          {(user?.role === "Admin" || user?.createAssets) &&
            <Button className="text-nowrap" onClick={() => setShow(true)}><FontAwesomeIcon icon={faPlus} fixedWidth className="me-1" />Create New</Button>}
        </div>
        <Card className="bg-white p-3">
          <CommonTable setCountParent={setResultCount} apiName="assets" columns={columns} showExport={true} visibilityName="assets" />
        </Card>
      </Container>
      <NewObject showModal={showModal} closeModal={() => setShow(false)} apiName="assets" />
    </React.Fragment>
  );
}
export default Assets;
