import React, { useEffect, useState } from "react";
import { Badge, Button, Card, Col, Collapse, Image, ListGroup, Row } from "react-bootstrap";
import api from "../../api";
import { ChevronDown, ChevronUp } from "react-feather";
import useLocalStorage from "../../hooks/useLocalStorage";

const SrlAssetSummary = (props) => {
    const { categories, selectedCategoryName, setSelectedCategoryName, apiName } = props;

    const [categoryGroups, setCategoryGroups] = useState([]);
    const [loadingCounts, setLoadingCounts] = useState(0);
    const [open, setOpen] = useLocalStorage("assetSummaryOpen", true);

    useEffect(() => {
        var categoryGroups = [];

        if (categories && categories.length > 0) {
            categories.forEach((value, index, array) => {
                var foundIndex = categoryGroups.findIndex((v) => { return v.category === value.category });
                if (foundIndex === -1) {
                    var newGroup = {
                        "category": value.category,
                        "categories": [{ ...value, "count": null }],
                        "highlightCategoryIndex": 0
                    }
                    categoryGroups.push(newGroup);
                }
                else {
                    categoryGroups[foundIndex].categories.push({ ...value, "count": null });
                }
            });

            setLoadingCounts(0);
            setCategoryGroups(categoryGroups);
        }
    }, [categories]);

    useEffect(() => {
        if (loadingCounts === 0) {
            var categoryNames = [];
            categoryGroups.forEach((categoryGroup) => {
                categoryGroup.categories.forEach((category) => {
                    categoryNames.push(category.name);
                });
            });

            api.get(`${apiName}/categoriescount`, { params: { categories: categoryNames } })
                .then((response) => {
                    if (response.status === 200) {

                        var countData = response.data;

                        setCategoryGroups((prevCategoryGroups) => {
                            return prevCategoryGroups.map((value) => {
                                return {
                                    ...value,
                                    "categories": value.categories.map((cat) => {
                                        var foundCountIndex = countData.findIndex((v) => { return v.item1 === cat.name; });
                                        if (foundCountIndex !== -1) {
                                            return {
                                                ...cat,
                                                "count": countData[foundCountIndex].item2
                                            };
                                        }
                                        else {
                                            return cat;
                                        }
                                    })
                                };
                            });
                        });
                        setLoadingCounts(1);
                    }
                    else {
                        setLoadingCounts(2);
                    }

                }, (reason) => {
                    console.log({ message: "ERROR: assets/categoriescount ", reason });
                });
        }
    }, [categoryGroups]);

    const handleMouseEnter = (categoryGroupIndex, categoryIndex) => {
        if (categoryGroups) {
            setCategoryGroups((prevCategoryGroups) => {
                return prevCategoryGroups.map((value, index, array) => {
                    if (categoryGroupIndex === index) {
                        return { ...value, "highlightCategoryIndex": categoryIndex };
                    }
                    else {
                        return value;
                    }
                });
            });
        }
    };

    const handleMouseLeave = (categoryGroupIndex, categoryIndex) => {
        if (categoryGroups) {
            setCategoryGroups((prevCategoryGroups) => {
                return prevCategoryGroups.map((value, index, array) => {
                    if (categoryGroupIndex === index) {
                        // Check if this group has the selected category
                        var selectedCategoryIndex = -1;
                        value.categories.forEach((catValue, catIndex) => {
                            if (catValue.name === selectedCategoryName) {
                                selectedCategoryIndex = catIndex;
                            }
                        });

                        if (selectedCategoryIndex === -1) {
                            return { ...value, "highlightCategoryIndex": categoryIndex };
                        }
                        else {
                            return { ...value, "highlightCategoryIndex": selectedCategoryIndex };
                        }
                    }
                    else {
                        return value;
                    }
                });
            });
        }
    };

    return (
        <>
            <Card className="p-0">
                <Card.Header className="p-0">
                    <div className="d-flex justify-content-between align-items-end bg-primary p-2">
                        <div><h3 className="text-white">Asset Summary</h3></div>
                        <Button
                            variant="primary"
                            onClick={() => setOpen(!open)}
                            aria-controls="example-collapse-text"
                            aria-expanded={open}
                        >
                            &nbsp;{!open ? <ChevronDown></ChevronDown> : <ChevronUp></ChevronUp>}&nbsp;
                        </Button>
                    </div>
                </Card.Header>
                <Collapse in={open}>
                    <Card.Body>
                        <Row xs={1} md={2} xxl={4}>
                            {
                                categoryGroups.map((categoryGroup, categoryGroupIndex) => {
                                    return (
                                        <Col key={categoryGroupIndex} className="px-1">
                                            <div className="d-flex p-0 m-0">
                                                <div className="flex-shrink-0">
                                                    <Image width={"80px"} src={require(`../../assets/img/srl/category/${categoryGroup.categories[categoryGroup.highlightCategoryIndex].image}`).default} fluid />
                                                </div>
                                                <div className="flex-grow-1">
                                                    <Card >
                                                        <Card.Body>
                                                            <Card.Title>{categoryGroup.category}</Card.Title>
                                                        </Card.Body>

                                                        <ListGroup className="list-group-flush">
                                                            {
                                                                categoryGroup.categories.map((category, categoryIndex) => {
                                                                    return (
                                                                        <ListGroup.Item key={categoryIndex}
                                                                            className="px-1 py-2"
                                                                            style={{ cursor: "pointer" }}
                                                                            variant={categoryIndex === categoryGroup.highlightCategoryIndex ? "info" : ""}
                                                                            active={category.name === selectedCategoryName}
                                                                            onMouseEnter={(event) => { handleMouseEnter(categoryGroupIndex, categoryIndex); }}
                                                                            onMouseLeave={(event) => { handleMouseLeave(categoryGroupIndex, categoryIndex); }}
                                                                            onClick={(event) => { (selectedCategoryName === category.name) ? setSelectedCategoryName(null) : setSelectedCategoryName(category.name); }}
                                                                        >
                                                                            {category.name}
                                                                            <Badge bg="warning" text="dark" className="float-end">{category.count ?? "-"}</Badge>
                                                                        </ListGroup.Item>
                                                                    );
                                                                })
                                                            }
                                                        </ListGroup>
                                                    </Card>
                                                </div>
                                            </div>
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                    </Card.Body>
                </Collapse>

            </Card>

        </>
    );
};

export default SrlAssetSummary;
