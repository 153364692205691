import React, { useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Button, Card, Container } from "react-bootstrap";
import CommonTable from "../../components/tables/CommonTable";
import NewObject from "../../components/detail/NewObject";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart, faPlus } from "@fortawesome/free-solid-svg-icons";
import useAuth from "../../hooks/useAuth";
import { NavLink } from "react-router-dom";
import { Disc } from "react-feather";

const Zones = () => {
  const [resultCount, setResultCount] = useState(0);
  const [showModal, setShow] = useState(false);
  const childRef = useRef();
  const { user } = useAuth();

  const columns = [];
  columns.push({
    Header: "",
    accessor: "icon",
    type: "icon",
    disableSortBy: true
  });
  columns.push({
    Header: "Name",
    accessor: "compoundName",
    type: "link",
    stateId: "id",
    link: "/zones/edit"
  });
  columns.push({
    Header: "Reference",
    accessor: "reference",
    visibilityName: "reference",
  });
  columns.push({
    Header: "Contents",
    accessor: "contentsCount",
    visibilityName: "contents-count",
    disableSortBy: true
  });
  if (user?.role === "Root") {
    columns.push({
      Header: "Customer",
      accessor: "customerName",
      show: user?.role === "Root",
      disableSortBy: true,
      Cell: cell => (<NavLink
        to={{ pathname: `/customers/edit`, search: `?id=${cell.row?.original?.customerId}` }}>{cell.value}</NavLink>)
    });
  }
  columns.push({
    Header: "Hashtags",
    accessor: "hashTags",
    visibilityName: "hash-tags",
    disableSortBy: true,
    Cell: cell => (<div>
      {cell.value && cell.value.map((tag, i) => <NavLink key={tag} to={{ pathname: '/globalSearch', search: `?searchString=${encodeURIComponent('#' + tag)}` }}><span key={i} className='badge bg-primary me-1 my-1 cursor-pointer'>{tag}</span></NavLink>)}
    </div>)
  });

  return (
    <React.Fragment>
      <Helmet defer={false} title="Zones" />
      <Container fluid className="p-0">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <div className="d-flex align-items-center">
            <h1 className="h3 mb-0"><Disc className='me-2' />Zones {typeof (resultCount) === 'string' && `(${resultCount})`}</h1>
            {user?.role !== 'Root' && <NavLink to={{ pathname: '/favourite', search: '?type=zones' }}><FontAwesomeIcon className="ms-4 me-2 text-primary cursor-pointer" icon={faHeart} /><span>Favourites</span></NavLink>}
          </div>
          {(user?.role === "Admin" || user?.createZones) &&
            <Button className="text-nowrap" onClick={() => setShow(true)}><FontAwesomeIcon icon={faPlus} fixedWidth className="me-1" />Create New</Button>}
        </div>
        <Card className="bg-white p-3">
          <CommonTable ref={childRef} setCountParent={setResultCount} apiName="zones" columns={columns} showExport={true} />
        </Card>
      </Container>
      <NewObject showModal={showModal} closeModal={() => setShow(false)} apiName="zones" />
    </React.Fragment>
  );
};
export default Zones;