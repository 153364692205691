import React, { useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Card, Container, Form } from "react-bootstrap";
import CommonTable from "../../components/tables/CommonTable";
import { useLocation } from "react-router-dom";
import * as queryString from "query-string";
import useSrlHelper from "../../hooks/useSrlHelper";

const BatteryLevelPage = () => {

  const [resultCount, setResultCount] = useState(0);
  const location = useLocation();
  const childRef = useRef();
  const { level } = queryString.parse(location.search);
  const [curLevel, setCurrent] = useState(level);
  const { getAssetColumnsForBatteryLevelPage } = useSrlHelper();
  const [columns] = useState(getAssetColumnsForBatteryLevelPage());

  const highThreshold = () => {
    switch (curLevel) {
      case 'High':
        return 40000;
      case 'Medium':
        return 12250;
      case 'Low':
        return 11749;
      case 'Disconnected':
        return 500;
      default:
        return 0;
    }
  }

  const lowThreshold = () => {
    switch (curLevel) {
      case 'High':
        return 12251;
      case 'Medium':
        return 11750;
      case 'Low':
        return 501;
      case 'Disconnected':
        return 0;
      default:
        return 0;
    }
  }


  return <React.Fragment>
    <Helmet defer={false} title={`Asset Battery Level`} />
    <Container fluid className="p-0">
      <div className="d-flex align-items-center mb-3">
        <h1 className="h3 mb-0">Asset Battery Level {typeof (resultCount) === 'string' && `(${resultCount})`}</h1>
        <Form.Select defaultValue={level} onChange={(e) => {
          setCurrent(e.target.value)
          setTimeout(() => {
            childRef.current && childRef.current.updateTable(1)
          }, 100)
        }} className="w-25 ms-4">
          <option value={'High'}>High</option>
          <option value={'Medium'}>Medium</option>
          <option value={'Low'}>Low</option>
          <option value={'Disconnected'}>Disconnected</option>
        </Form.Select>
      </div>
      <Card className="bg-white p-3">
        <CommonTable ref={childRef} secFilterId={'highThreshold'} secId={highThreshold()} id={lowThreshold()} filterId={'lowThreshold'} setCountParent={setResultCount} apiName={`reports/assetsbysupply`} columns={columns} />
      </Card>
    </Container>
  </React.Fragment>
}
export default BatteryLevelPage