import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Button, Card, Col, Container, Nav, Row, Tab } from "react-bootstrap";
import api from "../../api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Disc, Wifi } from "react-feather";
import * as queryString from "query-string";
import LastLocation from "../../components/LastLocation";
import LastAttendance from "../../components/LastAttendance";
import NotyfContext from "../../contexts/NotyfContext";
import DeviceDetail from "../../components/detail/DeviceDetail";
import Diagnostics from "../../components/detail/Diagnostics";
import moment from "moment-timezone";
import { faHeartbeat } from "@fortawesome/free-solid-svg-icons";
import GeoLocation from "../maps/components/GeoLocation";
import RootTopBar from "../../components/navbar/RootTopBar";
import DeviceScan from "../../components/deviceInfo/DeviceScan";

let interval = null
const ReaderEdit = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { id, direct, fId } = queryString.parse(location.search);
  const [thing, setThing] = useState(null);
  const [device, setDevice] = useState(null);
  const [assetLoaded, setAssetLoaded] = useState(false);
  const [assetType, setAssetType] = useState(null);
  const [tab, setTab] = useState('details')
  const [showVideo, setShowVideo] = useState(false);
  const notify = useContext(NotyfContext);

  useEffect(() => {
    getDevice();
    interval && clearInterval(interval)
    interval = setInterval(() => {
      getDevice();
    }, 5000);
    if (direct) {
      setTab(direct);
    }
  }, [id]);

  useEffect(() => {
    return () => {
      clearInterval(interval)
    };
  }, []);

  const getDevice = () => {
    api.get(`readers/${id}`).then(res => {
      setDevice(res.data);
      if (res.data.zoneId) {
        getAsset(res.data.zoneId);
        setAssetType('zones')
      } else {
        setAssetLoaded(true);
        setThing(null);
        setAssetType(null);
      }
    })
  }

  const getAsset = (assetId) => {
    api.get(`zones/` + assetId)
      .then(res => {
        setThing(res.data);
        setAssetLoaded(true);
      });
  };

  const onChangeLocation = (lat, lng, alt, w, wn, thr, ttl) => {
    let data = JSON.parse(JSON.stringify(device));
    data.latitude = lat.toString();
    data.longitude = lng.toString();
    data.altitude = alt.toString();
    data.wirepas = w;
    data.wirepasNetworkId = wn
    data.threshold = Number(thr)
    data.ttl = Number(ttl)
    api.put(`readers`, data).then(res => {
      setDevice(res.data);
      notify.open({
        type: "success",
        message: "Changes Saved"
      });
    })
  }

  const setReaderId = (newId) => {

    const { pathname } = location;
    const newSearch = new URLSearchParams(location.search);
    newSearch.set('id', newId); // Set your desired query parameter

    // Navigate to the new URL with updated search
    navigate(`${pathname}?${newSearch.toString()}`);
    setShowVideo(false);
  };

  return (
    <React.Fragment>
      <Helmet defer={false} title={device?.serial || 'Reader'} />
      <RootTopBar data={device} />
      <Container fluid className="p-0">
        {device?.serial && <h1 className="h3 mb-3 d-flex align-items-center">
          <Wifi size={18} />
          <span className="ms-2">{device.serial}</span>
        </h1>}
        {assetType && <h3 className="mb-3 h5">
          {assetType === 'zones' && <Disc size={18} />}
          <NavLink to={{ pathname: `/${assetType}/edit`, search: `?id=${thing?.id}` }} className="ms-2">{thing?.compoundName}</NavLink>
        </h3>}
        <Row>
          <Col md="4" xl="3">
            {device?.lastReport && <Card className="mb-0 border-bottom">
              <Card.Body>
                <FontAwesomeIcon icon={faHeartbeat} size={'sm'} />
                <span className="ms-2">{moment(device?.lastReport).format("DD/MM/YYYY HH:mm:ss")}</span>
              </Card.Body>
            </Card>}
            {thing?.lastPosition ? (<LastLocation profile={thing} />) : null}
            {thing?.lastAttendance ? (<LastAttendance profile={thing} />) : null}
            <Card className="mb-0 border-bottom">
              <Card.Body>
                <div className="d-flex justify-content-center">
                  <div className="d-flex align-items-start flex-column mb-3">
                    <Button className="my-2 mx-2" disabled={showVideo} onClick={() => { setShowVideo(true); }}>Scan Reader</Button>
                    {
                      showVideo &&
                      <>
                        <DeviceScan setReaderId={setReaderId} />
                        <Button className="my-2 mx-2" onClick={() => { setShowVideo(false); }}>Cancel</Button>
                      </>
                    }
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col md="8" xl="9" className="mt-3 mt-sm-0">
            <div className="tab">
              <Tab.Container id="left-tabs-example" activeKey={tab} onSelect={e => setTab(e)}>
                <Nav variant="tabs">
                  <Nav.Item>
                    <Nav.Link eventKey="details">Details</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="diagnostics">Raw Data</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="floorplans">Settings</Nav.Link>
                  </Nav.Item>
                </Nav>
                <Tab.Content>
                  <Tab.Pane eventKey="details">
                    <DeviceDetail setThing={(e) => setDevice(e)} thing={device} assetType={assetType} type={'readers'} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="floorplans" >
                    {device && <GeoLocation fId={fId} tab={tab} onChangeLocation={onChangeLocation} floorplan reader={device} />}
                  </Tab.Pane>
                  <Tab.Pane eventKey="diagnostics">
                    {device !== null && assetLoaded && <Diagnostics tags={[]} root trackers={[]} readers={[device]} type={2} />}
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default ReaderEdit;
