import React, { useContext } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { Alert, Button, Card, Form } from "react-bootstrap";
import api from "../../api";
import NotyfContext from "../../contexts/NotyfContext";

const NewAdmin = ({apiName, type='create', data, onUpdated, onCancelEdit, id, onDelete}) => {

  const notify = useContext(NotyfContext);
  let sendType = null;

  return (
    <Card>
      <Card.Body>
        <Formik
          enableReinitialize
          initialValues={{
            secondaryName: data?.secondaryName || '',
            primaryName: data?.primaryName || '',
            reference: data?.reference || '',
            email: data?.email || '',
            submit: false
          }}
          validationSchema={Yup.object().shape({
            primaryName: Yup.string()
              .max(255)
              .required("Primary Name is required"),
            email: Yup.string()
              .email("Invalid email")
              .required("Email is required"),
            reference: Yup.string().max(255),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              if(type === 'create') {
                api.post(`accounts/admin`, {
                  customerId: id,
                  primaryName: values.primaryName,
                  secondaryName: values.secondaryName,
                  reference: values.reference,
                  email: values.email,
                  sendemail: sendType === 1
                }).then(res => {
                  onUpdated && onUpdated();
                  notify.open({
                    type: "success",
                    message: sendType === 1 ? 'Email has been sent !' : "Changes Saved"
                  });
                  onCancelEdit && onCancelEdit();
                })
              } else {
                api.put('accounts/admin', {
                  customerId: id,
                  primaryName: values.primaryName,
                  secondaryName: values.secondaryName,
                  reference: values.reference,
                  email: values.email,
                  id: data.id,
                  sendemail: sendType === 1 && !data.registered
                })
                  .then(res => {
                    notify.open({
                      type: "success",
                      message: (sendType === 1 && !data.registered) ? 'Email has been sent !' : "Changes Saved"
                    });
                    onUpdated && onUpdated()
                    onCancelEdit && onCancelEdit()
                  })
              }

            } catch (error) {
              const message = error.message || "Something went wrong";
              setStatus({ success: false });
              setErrors({ submit: message });
              setSubmitting(false);
            }
          }}
        >
          {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
            <Form onSubmit={handleSubmit}>
              {errors.submit && (
                <Alert className="my-3" variant="danger">
                  <div className="alert-message">{errors.submit}</div>
                </Alert>
              )}

              <Form.Group className="mb-3">
                <Form.Label>Primary Name</Form.Label>
                <Form.Control
                  size="md"
                  type="text"
                  name="primaryName"
                  value={values.primaryName}
                  isInvalid={Boolean(touched.primaryName && errors.primaryName)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {!!touched.primaryName && (
                  <Form.Control.Feedback type="invalid">
                    {errors.primaryName}
                  </Form.Control.Feedback>
                )}
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Secondary Name</Form.Label>
                <Form.Control
                  size="md"
                  type="text"
                  name="secondaryName"
                  value={values.secondaryName}
                  isInvalid={Boolean(touched.secondaryName && errors.secondaryName)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {!!touched.secondaryName && (
                  <Form.Control.Feedback type="invalid">
                    {errors.secondaryName}
                  </Form.Control.Feedback>
                )}
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Reference</Form.Label>
                <Form.Control
                  size="md"
                  type="text"
                  name="reference"
                  value={values.reference}
                  isInvalid={Boolean(touched.reference && errors.reference)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {!!touched.reference && (
                  <Form.Control.Feedback type="invalid">
                    {errors.reference}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  size="md"
                  type="text"
                  name="email"
                  value={values.email}
                  isInvalid={Boolean(touched.email && errors.email)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {!!touched.email && (
                  <Form.Control.Feedback type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <div className="mt-3 d-flex justify-content-end">
                {!data?.registered && <Button type="submit" onClick={() => sendType = 1}>{ type === 'edit' ? 'Save and re-send registration email' : 'Save and send registration email'}</Button>}
                <Button className="ms-1" type="submit" onClick={() => sendType = 2} variant="success">{ type === 'edit' ? 'Save' : 'Save and send later'}</Button>
                {type === 'edit' && <Button className="ms-1" variant={'danger'} onClick={() => onDelete()}>Delete</Button>}
                <Button className="ms-1" variant="secondary" onClick={() => onCancelEdit()}>Cancel</Button>
              </div>
            </Form>
          )}
        </Formik>
      </Card.Body>
    </Card>
  );
}

export default NewAdmin;