import React, { useContext } from "react";
import { Helmet } from "react-helmet-async";
import { globalConfig } from "../../config";
import AuthWrapper from "../../components/auth/AuthWrapper";
import { Formik } from "formik";
import * as Yup from "yup";
import api from "../../api";
import NotyfContext from "../../contexts/NotyfContext";
import { useNavigate } from "react-router-dom";
import { Alert, Button, Form } from "react-bootstrap";

const Licence = () => {
    const notify = useContext(NotyfContext);
    const navigate = useNavigate();

    return (<>
        <Helmet defer={false} title={globalConfig.productName.toUpperCase().concat(" Set Licence Key")} />
        <AuthWrapper title={"Licence Key"} subTitle={"Enter Licence Key"}>
            <div className="m-sm-1">
                <div className="mb-4">Please enter the Licence Key as given to you by Traclogik Admin.</div>
                <Formik
                    initialValues={{
                        licenceKey: "",
                        submit: false,
                    }}
                    validationSchema={Yup.object().shape({
                        licenceKey: Yup.string()
                            .required("Licence Key is required"),
                    })}
                    onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                        try {
                            api.put(`accounts/licence?licenceKey=${values.licenceKey}`, { }).then(() => {
                                navigate("/auth/sign-in");
                                notify.open({
                                    type: 'success',
                                    message: 'Successfully updated'
                                })
                            }).catch(error => {
                                const message = error?.title || error?.message || error || "Something went wrong";

                                setStatus({ success: false });
                                setErrors({ submit: message });
                                setSubmitting(false);
                            });
                        } catch (error) {
                            const message = error?.title || error?.message || error || "Something went wrong";

                            setStatus({ success: false });
                            setErrors({ submit: message });
                            setSubmitting(false);
                        }
                    }}
                >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        touched,
                        values,
                    }) => (
                        <Form onSubmit={handleSubmit}>
                            {errors.submit && (
                                <Alert className="my-3" variant="danger">
                                    <div className="alert-message">{errors.submit}</div>
                                </Alert>
                            )}
                            <Form.Group className="mb-3">
                                <Form.Label>Licence Key</Form.Label>
                                <Form.Control
                                    className="no-background-input"
                                    as="textarea"
                                    name="licenceKey"
                                    label="Licence Key"
                                    value={values.licenceKey}
                                    isInvalid={Boolean(touched.licenceKey && errors.licenceKey)}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                />
                                {!!touched.licenceKey && (
                                    <Form.Control.Feedback type="invalid">
                                        {errors.licenceKey}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                            <div className="mt-3">
                                <Button
                                    type="submit"
                                    variant="primary"
                                    size="lg"
                                    disabled={isSubmitting}
                                >
                                    Submit Licence Key
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </AuthWrapper>
    </>);
};

export default Licence;
