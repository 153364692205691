import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row, Spinner } from "react-bootstrap";
import { globalConfig } from "../../config";

const WidgetDetail = ({widgets, onGetOne, switchPlace, loading, apiName}) => {
  const options = apiName.indexOf('assetwidgets') > -1 ? globalConfig.assetWidgets : globalConfig.widgetsConfig.concat(globalConfig.reportWidgets);
  const [data, setData] = useState([]);
  const [end, setEnd] = useState(null);
  const [current, setCurrent] = useState(null);

  useEffect(() => {
    if(widgets) {
      setData(widgets.sort(sortArray));
    }
  }, [widgets]);


  const sortArray = (a, b) => {
    return a?.position - b?.position
  }

  const onSelect = (props) => {
    if(!widgets) return
    onGetOne && onGetOne(props)
  }

  const onDragStart =(index) => {
    setCurrent(index)
  }
  const onDragOver =(index, e) => {
    setEnd(index)
    e.preventDefault()
  }
  const onDragEnd =() => {
    switchPlace(current, end)
    setCurrent(null)
    setEnd(null)
  }

  return (
    <React.Fragment>
      <Container fluid className="p-0 position-relative" onDragOver={e => e.preventDefault()}>
        <Row className="pb-4" onDragOver={e => e.preventDefault()} onDragEnter={(e) => e.preventDefault()}>
          {data.map((item, index) =>
            <Col title={'drag to sort'} key={item.id} lg={(item.width / 100 * 12).toFixed(0)} className="mb-4" draggable onDragStart={()=>onDragStart(index)}
                 onDragOver={(e)=>onDragOver(index, e)}
                 onDragEnd={()=>onDragEnd(index)}>
              <Card className={`flex-fill w-100 h-100 border cursor-grab ${(end === index && end !== current) ? 'drag-up' : ''}`} onClick={() => onSelect(item)} style={{minHeight: '200px'}}>
                <Card.Header>
                  {options.filter(o => o.name === item.name).length > 0 && <Card.Title>{options.filter(o => o.name === item.name)[0].label}</Card.Title>}
                </Card.Header>
                <Card.Body>
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <p>Width: {item.width}%</p>
                      <p className="text-primary">Click to edit</p>
                    </div>
                    {options.filter(o => o.name === item.name).length > 0 && <img alt={'widgets'} className="w-35" src={require("./../../assets/img/widgets/" + options.filter(o => o.name === item.name)[0].src).default} />}
                  </div>
                </Card.Body>
              </Card>
            </Col>
          )}
        </Row>
        {loading && <div className="position-absolute bg-white opacity-50 top-0 w-100 h-100 d-flex align-items-center justify-content-center"><Spinner animation="border" /></div>}
      </Container>
    </React.Fragment>
  );
}
export default WidgetDetail