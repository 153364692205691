import React, { useEffect, useState } from "react";
import { ArrowUp } from "react-feather";
import { Button } from "react-bootstrap";

const ScrollButton = () =>{

  const [visible, setVisible] = useState(false)

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300){
      setVisible(true)
    }
    else if (scrolled <= 300){
      setVisible(false)
    }
  };

  const scrollToTop = () =>{
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisible);
    return () => {
      window.removeEventListener('scroll', toggleVisible)
    }
  }, [])

  return (
    <Button className="fixed-button" style={{display: visible ? 'inline' : 'none'}}>
      <ArrowUp onClick={scrollToTop} />
    </Button>
  );
}

export default ScrollButton;