import { Accordion, Button, Card, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const AttendanceHistWidget = ({ width, name }) => {
    const navigate = useNavigate();

    return (
        <>
            <Col key={name} lg={(width / 100 * 12).toFixed(0)} className="mb-4">
                <Card className="flex-fill w-100 h-100" style={{ minHeight: "210px" }}>
                    <Card.Title className="p-3">Attendance History Report</Card.Title>
                    <Card.Text className="p-3" as="div">
                        <Accordion defaultActiveKey="">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>Run a report to see historical attendances for all assets and people between a date range of your choice.</Accordion.Header>
                                <Accordion.Body>
                                    <p>An attendance is the record created that shows presence of an asset or a person within a zone or onboard a vehicle. An attendance is automatically completed when the asset or person leaves zone or vehicle and this is detected by the system.</p>
                                    <p>This report includes all completed attendances for assets and people within all zones or vehicles which start within, end within or span the selected date range.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Card.Text>
                    <Button variant="primary" onClick={() => navigate("/report/attendancehist")}>Show report</Button>
                </Card>
            </Col>
        </>
    );
};

export default AttendanceHistWidget;
