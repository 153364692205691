import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import GoogleMapReact from "google-map-react";
import SearchBox from "../maps/components/SearchBox";
import useAuth from "../../hooks/useAuth";
import { globalConfig } from "../../config";
//import { getDistance, radiusZoomLevel } from "../../utils/staticMethods";

let selectShape = null;
let listener1 = null;
let drawingManager = null;

const PauseMap = ({ rule, onUpdate }) => {
  const [mapInstance, setInstance] = useState();
  const [mapApi, setApi] = useState();
  const [showDelete, setShowDelete] = useState(false);
  const { user } = useAuth();

  console.log("INIT <<<");

  const initDrawingManager = () => {
    let polyOptions = {
      strokeWeight: 0,
      fillOpacity: 0.45,
      editable: true,
      draggable: true,
    };

    drawingManager && drawingManager.setDrawingMode(null)
    drawingManager && drawingManager.setMap(null);
    drawingManager = null;

    drawingManager = new mapApi.drawing.DrawingManager({
      drawingModes: [
        mapApi.drawing.OverlayType.POLYGON,
      ],
      drawingControlOptions: {
        position: mapApi.ControlPosition.TOP_CENTER,
        drawingModes: [
          mapApi.drawing.OverlayType.POLYGON
        ],
      },
      polylineOptions: {
        editable: true,
        draggable: true
      },
      circleOptions: polyOptions,
      polygonOptions: polyOptions,
      map: mapInstance
    });
  };

  const getPolygonBounds = () => {
    let v = selectShape.getPath();
    let pathArray = [];
    let bounds = new mapApi.LatLngBounds();
    for (let i = 0; i < v.getLength(); i++) {
      let xy = v.getAt(i);
      let filter = pathArray.filter(item => {
        let spit = item.split(",");
        return spit[0] === xy.lng().toString() && spit[1] === xy.lat().toString();
      });
      if (filter.length === 0) {
        pathArray.push(xy.lng() + "," + xy.lat());
        bounds.extend(new mapApi.LatLng(xy.lat(), xy.lng()));
      }
    }
    let end = pathArray[0].split(",");
    bounds.extend(new mapApi.LatLng(end[1], end[0]));

    return bounds;
    // let latitude = bounds.getCenter().lat();
    // let longitude = bounds.getCenter().lng();
  };

  const initPolygon = () => {
    if (selectShape) {
      selectShape.setMap(null);
      selectShape = null;
    }

    if (!rule.polygon) return;

    // let firstItem = rule.polygon[0].split(",");
    // let minLat = { lat: Number(firstItem[1]), lng: Number(firstItem[0]) },
    //   maxLat = { lat: Number(firstItem[1]), lng: Number(firstItem[0]) },
    //   minLng = { lat: Number(firstItem[1]), lng: Number(firstItem[0]) },
    //   maxLng = { lat: Number(firstItem[1]), lng: Number(firstItem[0]) };
    let arr = rule.polygon.map(item => {
      let me = item.split(",");
      let lng = Number(me[0]);
      let lat = Number(me[1]);
      // if (lat > maxLat.lat) maxLat = { lat, lng };
      // if (lat < minLat.lat) minLat = { lat, lng };
      // if (lng > maxLng.lng) maxLng = { lat, lng };
      // if (lng < minLng.lng) minLng = { lat, lng };
      return { lng, lat };
    });
    // let point1 = new mapApi.LatLng(minLat);
    // let point2 = new mapApi.LatLng(maxLat);
    // let point3 = new mapApi.LatLng(minLng);
    // let point4 = new mapApi.LatLng(maxLng);
    // let d1 = getDistance(point1, point2);
    // let d2 = getDistance(point3, point4);
    // let d = d1 > d2 ? d1 : d2;

    setSelectShape(new mapApi.Polygon({
      paths: arr,
      strokeColor: "#000000",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#242424",
      fillOpacity: 0.3,
      editable: true,
      draggable: true,
      type: "polygon"
    }));

    // mapInstance.panTo(bounds.getCenter());

    // let keys = Object.keys(radiusZoomLevel).reverse();
    // let value = 1000000;
    // let index = 0;
    // for (let i = 0; i < keys.length; i++) {
    //   let v = Math.abs(radiusZoomLevel[keys[i]] - d);
    //   if (v < value) {
    //     value = v;
    //     index = keys[i];
    //   }
    // }
    // // addCenterPointForPolygon(Number(rule.latitude), Number(rule.longitude));
    // mapInstance.setZoom(Number(index));
  };

  const setSelectShape = (shape) => {
    // Remove existing shape from map
    selectShape && selectShape.setMap(null);

    selectShape = shape;
    selectShape.setMap(mapInstance);

    var bounds = getPolygonBounds();
    mapInstance.fitBounds(bounds);

    listener1 = selectShape.addListener("dragend", (e) => {
      onShapeDragend(e);
    });
  };

  const initDrawPolygon = () => {
    // if (drawingManager) {
    //   drawingManager.setDrawingMode(null);
    //   drawingManager.setMap(null);
    //   drawingManager = null;
    // }
    // let polyOptions = {
    //   strokeWeight: 0,
    //   fillOpacity: 0.45,
    //   editable: true,
    //   draggable: true
    // };
    // drawingManager = mapApi.drawing.DrawingManager && new mapApi.drawing.DrawingManager({
    //   drawingModes: [
    //     mapApi.drawing.OverlayType.CIRCLE,
    //     mapApi.drawing.OverlayType.POLYGON,
    //     mapApi.drawing.OverlayType.RECTANGLE
    //   ],
    //   drawingControlOptions: {
    //     position: mapApi.ControlPosition.TOP_CENTER,
    //     drawingModes: [
    //       mapApi.drawing.OverlayType.CIRCLE,
    //       mapApi.drawing.OverlayType.POLYGON
    //     ]
    //   },
    //   polylineOptions: {
    //     editable: true,
    //     draggable: true
    //   },
    //   circleOptions: polyOptions,
    //   polygonOptions: polyOptions,
    //   map: mapInstance
    // });

    initDrawingManager();

    mapApi.event.addListener(drawingManager, "overlaycomplete", function (e) {
      drawingManager.setDrawingMode(null);
      // centerPoint && centerPoint.setMap(null);
      // centerPoint = null;
      if (e.type !== mapApi.drawing.OverlayType.MARKER) {
        // Switch back to non-drawing mode after drawing a shape.
        let newShape = e.overlay;
        newShape.type = e.type;
        setSelectShape(newShape);
        // selectShape = newShape;
        // if (newShape.type === mapApi.drawing.OverlayType.POLYGON) {
        //   addCenterPointForPolygon();
        //   listener1 = selectShape.addListener("dragend", (e) => {
        //     addCenterPointForPolygon();
        //   });
        // } else {
        //   mapApi.event.removeListener(listener1);
        //   listener1 = null;
        // }
      }
    });
  };

  const onShapeDragend = (e) => {
    setSelectShape(selectShape);
  };

  useEffect(() => {
    console.log("INIT EFFECT");

    if (mapApi && mapInstance) {
      initDrawingManager();
      initPolygon();
      initDrawPolygon();
    }

    return () => {
      mapApi && mapApi.event.removeListener(listener1);
      listener1 = null;
      drawingManager && drawingManager.setDrawingMode(null);
      drawingManager && drawingManager.setMap(null);
      drawingManager = null;
      console.log("DONE EFFECT");
    }
  }, [mapApi]);

  const apiHasLoaded = (map, maps) => {
    console.log("HAS LOADED");
    if (!map || !maps) return;
    setInstance(map);
    setApi(maps);
  };

  const getMapOptions = (maps) => {
    return {
      fullscreenControl: true,
      mapTypeControl: true,
      mapTypeId: maps.MapTypeId.ROADMAP,
      scaleControl: true,
      draggableCursor: 'default',
      gestureHandling: "cooperative",
      streetViewControl: true,
      mapTypeControlOptions: {
        position: maps.ControlPosition.LEFT_BOTTOM,
      },
    };
  };

  const onCreate = (lat, lng) => {

  }

  const onSave = () => {
    let obj = JSON.parse(JSON.stringify(rule));
    if (selectShape && selectShape.type === "polygon") {
      let v = selectShape.getPath();
      let bounds = new mapApi.LatLngBounds();
      let pathArray = [];
      for (let i = 0; i < v.getLength(); i++) {
        let xy = v.getAt(i);
        let filter = pathArray.filter(item => {
          let spit = item.split(",");
          return spit[0] === xy.lng().toString() && spit[1] === xy.lat().toString();
        });
        if (filter.length === 0) {
          pathArray.push(xy.lng() + "," + xy.lat());
          bounds.extend(new mapApi.LatLng(xy.lat(), xy.lng()));
        }
      }
      let end = pathArray[0].split(",");
      bounds.extend(new mapApi.LatLng(end[1], end[0]));
      obj.polygon = pathArray;
    }
    onUpdate(obj);
  }

  const onCancel = () => {
    if (mapApi && mapInstance) {
      initDrawingManager();
      initPolygon();
      initDrawPolygon();
    }
  }

  const onDelete = () => {
    setShowDelete(true);
  }

  const handleDelete = () => {
    let obj = JSON.parse(JSON.stringify(rule));
    if (selectShape) {
      selectShape.setMap(null);
      selectShape = null;
    }
    obj.polygon = null;
    try {
      onUpdate(obj);
    } catch (error) {
    }
    setShowDelete(false);
  }

  return <React.Fragment>
    {user?.editRules && <div className="mt-2 mb-2 d-flex align-items-center">
      <span>Click on the map to add or move this area.</span>
      <Button onClick={() => onSave()} className="ms-1">Save</Button>
      <Button onClick={() => onCancel()} className="ms-2" variant="secondary">Cancel</Button>
      <Button onClick={() => onDelete()} className="ms-2" variant="danger">Delete</Button>
    </div>}
    <div style={{ height: 500, width: "100%" }}>
      <GoogleMapReact
        options={getMapOptions}
        onClick={(ev) => {
          ev.event.preventDefault();
          onCreate(ev.lat, ev.lng)
        }}
        bootstrapURLKeys={{
          key: globalConfig.googleMapKey,
          libraries: ["places", "geometry", "drawing", "visualization"]
        }}
        center={{
          lat: 50,
          lng: 10
        }}
        defaultZoom={5}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => apiHasLoaded(map, maps)}
      >
        {mapInstance && mapApi && <SearchBox map={mapInstance} mapApi={mapApi} />}
      </GoogleMapReact>
    </div>
    <Modal show={showDelete} onHide={() => setShowDelete(false)}>
      <Modal.Header closeButton><Modal.Title>Please Confirm</Modal.Title></Modal.Header>
      <Modal.Body>
        <p>Are you sure that you wish to permanently delete this Geofence?</p>
        <p>Deletions are not reversible.</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={() => handleDelete()}>Delete</Button>
        <Button variant="secondary" onClick={() => setShowDelete(false)}>Cancel</Button>
      </Modal.Footer>
    </Modal>
  </React.Fragment>
}
export default PauseMap