import GoogleMapReact from "google-map-react";
import { useState } from "react";
import { globalConfig } from "../../config";
import { Button, Card, Col, Container, Form, Image, ListGroup, Row } from "react-bootstrap";
// import { googlecode } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { useEffect } from "react";
import logo from "../../assets/img/tl_logo_a_rgb_01_old.png";
import { Formik } from "formik";
import api from "../../api";
import { Map } from "react-feather";
import moment from "moment-timezone";

const K_WIDTH = 36;
const K_HEIGHT = 28;

const markerStyle = {
    // initially any map object has left top corner at lat lng coordinates
    // it's on you to set object origin to 0,0 coordinates
    position: 'absolute',
    width: K_WIDTH,
    height: K_HEIGHT,
    left: -K_WIDTH / 2,
    top: -K_HEIGHT / 2,

    border: '3px solid #f44336',
    borderRadius: 8,
    backgroundColor: '#4448',
    textAlign: 'center',
    color: '#EEEF',
    fontSize: "1.7em",
    fontWeight: '800',
    padding: 0,
};

const AssetMarker = ({ text }) => <div style={markerStyle}>{text}</div>;

const MultiAssetMapPage = () => {
    const [mapInstance, setInstance] = useState();
    const [mapApi, setApi] = useState();
    const [isPrinting, setIsPrinting] = useState(false);
    const printRef = useRef(null);
    const [markers, setMarkers] = useState([]);

    // We store the resolve Promise being used in `onBeforeGetContent` here
    const promiseResolveRef = useRef(null);

    const fitBounds = () => {
        if (mapInstance && mapApi && markers.length > 0) {
            const bounds = new mapApi.LatLngBounds();
            markers.forEach(marker => {
                if (marker.latitude && marker.longitude) {
                    bounds.extend(new mapApi.LatLng(marker.latitude, marker.longitude));
                }
            });
            if (!bounds.isEmpty()) {
                mapInstance.fitBounds(bounds);
            }
            // else {
            //     mapInstance.fitBounds(mapApi.LatLngBounds.MAX_BOUNDS);
            // }
        }
    };

    const getMapOptions = (maps) => {
        return {
            //mapId: "90f87356969d889c",
            fullscreenControl: true,
            draggableCursor: "default",
            mapTypeControl: true,
            mapTypeId: maps.MapTypeId.SATELLITE,
            scaleControl: true,
            heading: 0,
            gestureHandling: "cooperative",
            streetViewControl: false,
            mapTypeControlOptions: {
                position: maps.ControlPosition.LEFT_BOTTOM
            }
        };
    };

    useEffect(() => {
        fitBounds();
    }, [mapInstance, mapApi, markers]);

    // CNS - Uncomment below to fit bouns to markers when resizing the window
    // useEffect(() => {
    //     function handleResize() {
    //         fitBounds();
    //     }

    //     // Attach the event listener to the window object
    //     window.addEventListener('resize', handleResize);

    //     // Remove the event listener when the component unmounts
    //     return () => {
    //         window.removeEventListener('resize', handleResize);
    //     };
    // }, []);

    // We watch for the state to change here, and for the Promise resolve to be available
    useEffect(() => {
        if (isPrinting && promiseResolveRef.current) {
            // Resolves the Promise, letting `react-to-print` know that the DOM updates are completed
            promiseResolveRef.current();
        }
    }, [isPrinting]);

    const handlePrint = useReactToPrint({
        content: () => printRef.current,
        onBeforeGetContent: () => {
            return new Promise((resolve) => {
                promiseResolveRef.current = resolve;
                setIsPrinting(true);
            });
        },
        onAfterPrint: () => {
            // Reset the Promise resolve so we can print again
            promiseResolveRef.current = null;
            setIsPrinting(false);
        }
    });

    const apiHasLoaded = (map, maps) => {
        if (!map || !maps) return;
        setInstance(map);
        setApi(maps);
    };

    const cardStyle = isPrinting ? { backgroundColor: "white", color: "black" } : {};

    return <>
        <Container fluid className="p-0">
            <div className="d-flex justify-content-between align-items-center mb-3">
                <div className="d-flex align-items-center">
                    <h1 className="h3 mb-0"><Map className="me-1" />Multi Asset Search</h1>
                </div>
            </div>
            <Card className="bg-white p-3">

                {
                    markers.length === 0 &&
                    <Formik
                        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                            try {
                                const data = values.assetList.split('\n').filter((a) => a.length > 0);
                                const response = await api.put('maps/multiasset', data);
                                if (response.status === 200) {
                                    var newMarkers = [];
                                    response.data.forEach((marker, index) => {
                                        var status = "";
                                        if (marker.type === "missing") {
                                            status = "(not found)";
                                        }
                                        else if (!marker.latitude) {
                                            status = "(no location)";
                                        }
                                        newMarkers.push(
                                            {
                                                ...marker,
                                                index: (index + 1).toString(),
                                                status: status,
                                            });
                                    });
                                    setMarkers(newMarkers);
                                }
                                else {
                                    setStatus(response.statusText);
                                }
                                //console.log(response);
                                setSubmitting(false);
                            } catch (error) {
                                //console.error(error);
                                setSubmitting(false);
                            }
                        }}
                        initialValues={{ assetList: "" }}
                    >
                        {({
                            errors,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            isSubmitting,
                            touched,
                            values,
                        }) => (
                            <Form onSubmit={handleSubmit}>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Enter / paste, a list of vehicle registrations, (one per line):</Form.Label>
                                            <Form.Control as="textarea" rows={6} name="assetList" onBlur={handleBlur} onChange={handleChange} value={values.assetList} />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Button type="submit" disabled={isSubmitting}>Search</Button>
                                    </Col>
                                </Row>
                            </Form>
                        )}
                    </Formik>
                }

                {
                    (markers.length > 0) &&
                    <div ref={printRef}>
                        <style type="text/css" media="print">{"@page {size: landscape;}"}</style>
                        <Card style={cardStyle}>
                            <Card.Body>
                                <Card.Text as="div">
                                    {
                                        !isPrinting &&
                                        <Row>
                                            <Col>
                                                <div className="d-grid gap-2 col-6 mx-auto mt-0 mb-2">
                                                    <Button variant="success" onClick={handlePrint}>Print map and list</Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    }
                                    {
                                        isPrinting &&
                                        <div class="d-flex justify-content-between">
                                            <div>Multi Asset Search</div>
                                            <Image src={logo} height={"96px"} style={{ margin: "auto" }}></Image>
                                            <div>{moment().format("DD/MM/YYYY HH:mm")}</div>
                                        </div>
                                    }

                                    <Row>
                                        <Col className="mx-n2">
                                            <GoogleMapReact
                                                options={getMapOptions}
                                                style={{ height: "70vh", position: "relative", width: "100%" }}
                                                bootstrapURLKeys={{
                                                    key: globalConfig.googleMapKey,
                                                    libraries: ["places", "geometry", "drawing", "visualization"]
                                                }}
                                                defaultZoom={1}
                                                defaultCenter={[0, 0]}
                                                yesIWantToUseGoogleMapApiInternals
                                                onGoogleApiLoaded={({ map, maps }) => apiHasLoaded(map, maps)}
                                            >
                                                {markers
                                                    .filter((marker) => { return marker.latitude && marker.longitude; })
                                                    .reverse()
                                                    .map(function (marker, index) { return <AssetMarker key={index} lat={marker.latitude} lng={marker.longitude} text={marker.index} /> })
                                                }
                                            </GoogleMapReact>
                                        </Col>
                                        <Col className="mx-n2" lg={3} xs={4}>
                                            <ListGroup style={{ height: "70vh", width: "100%", overflow: "auto" }} >
                                                {markers.map((marker, index) => <ListGroup.Item key={index} className="py-0" style={cardStyle}>{marker.index}&nbsp;-&nbsp;{marker.compoundName}&nbsp;{marker.status}</ListGroup.Item>)}
                                            </ListGroup>
                                        </Col>
                                    </Row>

                                    {/* <div style={{ pageBreakAfter: "always" }}></div> */}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                }
            </Card>
        </Container>
    </>;
};

export default MultiAssetMapPage;