import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { NavLink, useLocation } from "react-router-dom";
import { Card, Col, Container, Nav, Row, Tab } from "react-bootstrap";
import api from "../../api";
import { Package, Smartphone, Users } from "react-feather";
import * as queryString from "query-string";
import LastLocation from "../../components/LastLocation";
import LastAttendance from "../../components/LastAttendance";
import DeviceDetail from "../../components/detail/DeviceDetail";
import Diagnostics from "../../components/detail/Diagnostics";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeartbeat } from "@fortawesome/free-solid-svg-icons";
import moment from "moment-timezone";
import RootTopBar from "../../components/navbar/RootTopBar";

let interval;

const AppEdit = () => {
    const location = useLocation()
    const { id } = queryString.parse(location.search);
    const [thing, setThing] = useState(null);
    const [device, setDevice] = useState(null);
    const [assetLoaded, setAssetLoaded] = useState(false);
    const [personLoaded, setPersonLoaded] = useState(false);
    const [assetType, setAssetType] = useState(null);

    useEffect(() => {
        getDevice();
        interval && clearInterval(interval)
        interval = setInterval(() => {
            getDevice();
        }, 5000);
    }, [id]);

    useEffect(() => {
        return () => {
            clearInterval(interval)
        };
    }, []);

    const getDevice = () => {
        api.get(`tags/${id}`).then(res => {
            setDevice(res.data);
            if (res.data.assetId) {
                getAsset(res.data.assetId);
                setAssetType('assets')
                setPersonLoaded(true);
            } else if (res.data.personId) {
                getPerson(res.data.personId);
                setAssetType('people')
                setAssetLoaded(true);
            } else {
                setAssetLoaded(true);
                setPersonLoaded(true);
            }
        })
    }

    const getAsset = (assetId) => {
        api.get(`assets/` + assetId)
            .then(res => {
                setThing(res.data);
                setAssetLoaded(true);
            });
    };

    const getPerson = (assetId) => {
        api.get(`people/` + assetId)
            .then(res => {
                setThing(res.data);
                setPersonLoaded(true);
            });
    };

    return (<>
        <Helmet defer={false} title={device?.serial || 'App'} />
        <RootTopBar data={device} />
        <Container fluid className="p-0">
            {device?.serial && <h1 className="h3 mb-3 d-flex align-items-center">
                <Smartphone size={18} />
                <span className="ms-2">{device.serial}</span>
            </h1>}
            {assetType && <h3 className="mb-3 h5">
                {assetType === 'assets' ? <Package size={18} /> : <Users size={18} />}
                <NavLink to={{ pathname: `/${assetType}/edit`, search: `?id=${thing?.id}` }} className="ms-2">{thing?.compoundName}</NavLink>
            </h3>}
            <Row>
                <Col md="4" xl="3">
                    {device?.lastReport && <Card className="mb-0 border-bottom">
                        <Card.Body>
                            <FontAwesomeIcon icon={faHeartbeat} size={'sm'} />
                            <span className="ms-2">{moment(device?.lastReport).format("DD/MM/YYYY HH:mm:ss")}</span>
                        </Card.Body>
                    </Card>}
                    {thing?.lastPosition ? (<LastLocation profile={thing} />) : null}
                    {thing?.lastAttendance ? (<LastAttendance profile={thing} />) : null}
                </Col>
                <Col md="8" xl="9" className="mt-3 mt-sm-0">
                    <div className="tab">
                        <Tab.Container id="left-tabs-example" defaultActiveKey="details">
                            <Nav variant="tabs">
                                <Nav.Item>
                                    <Nav.Link eventKey="details">Details</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="diagnostics">Raw Data</Nav.Link>
                                </Nav.Item>
                            </Nav>
                            <Tab.Content>
                                <Tab.Pane eventKey="details">
                                    <DeviceDetail setThing={(e) => setDevice(e)} thing={device} assetType={assetType} type={'tags'} />
                                </Tab.Pane>
                                <Tab.Pane eventKey="diagnostics">
                                    {device !== null && assetLoaded && personLoaded && <Diagnostics tags={[]} apps={[device]} root trackers={[]} type={1} />}
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </div>
                </Col>
            </Row>
        </Container>
    </>);
};

export default AppEdit;
