import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import SelectHeader from "../SelectHeader";
import { DebounceInput } from "react-debounce-input";
import { useSortBy, useTable } from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSort, faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";
import LoadingSpin from "../loadingSpin";

import { NavLink, useLocation } from "react-router-dom";

import { Col, Row, Spinner, Table } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import api from "../../api";
import moment from "moment-timezone";
import * as queryString from "query-string";
import useSrlHelper from "../../hooks/useSrlHelper";

const columns = [
  {
    Header: "Zone",
    accessor: "parentName",
    disableSortBy: true
  },
  {
    Header: "Start",
    accessor: "start"
  },
  {
    Header: "End",
    accessor: "end"
  },
  {
    Header: "Duration",
    accessor: "duration"
  },
  {
    Header: "Total",
    accessor: "rate",
    disableSortBy: true
  }
];

const TableAttendance = forwardRef(function _Child({
  id,
  curTab,
  duration,
  type = "asset",
  resetDuration,
  tableLoaded,
  emptyText,
  filterText,
  onChangeFilter
}, ref) {

  const [data, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [total, setTotal] = useState(null);
  const [filter, setFilter] = useState(filterText || "");
  const [limit, setLimit] = useState(10);
  const [init, setInit] = useState(false);
  const location = useLocation();
  const [tableSort, setSort] = useState(queryString.parse(location.search)?.sort || "");
  const [currentPage, setCurrentPage] = useState(1);
  const { getTableProps, getTableBodyProps, headerGroups, rows, allColumns, state: { sortBy } } = useTable({
    columns,
    data,
    manualSortBy: true
  }, useSortBy);
  useImperativeHandle(ref, () => ({
    handleSwitchPage
  }));

  const { childItemDisplayName } = useSrlHelper();

  useEffect(() => {
    if (id && curTab === "second") {
      if (!init || duration.length === 0) {
        setInit(true)
        if (filterText !== filter) {
          setFilter(filterText)
        } else {
          initRequest()
        }
      } else if (init) {
        if (filterText !== filter) {
          setFilter(filterText)
        }
      }
    }
  }, [curTab]);

  useEffect(() => {
    if (id && curTab === "second") {
      !init && initRequest();
      init && handleSwitchPage(1);
      onChangeFilter(filter)
    }
  }, [filter, limit]);

  const onChangeSelect = (e) => {
    setLimit(e.target.value);
  };

  const filterResults = (e) => {
    setFilter(e);
  };

  const handlePageClick = (data) => {
    handleSwitchPage(data.selected + 1);
  };

  useEffect(() => {
    if (sortBy) {
      onSortColumn(sortBy);
    }
  }, [sortBy]);

  useEffect(() => {
    if (!init) return;
    handleSwitchPage(1);
  }, [tableSort]);

  const onSortColumn = (sortItem) => {
    if (sortItem.length === 0) {
      setSort(null);
      return;
    }
    let sortParam = "";
    let column = allColumns.filter(item => item.id === sortItem[0].id)[0];
    if (column) {
      if (!sortItem[0].desc) {
        sortParam = `${column.id}.asc`;
      } else if (sortItem[0].desc) {
        sortParam = `${column.id}.desc`;
      }
    }
    setSort(sortParam);
  };

  const initRequest = () => {
    setLoading(true);
    if (type === "assets" || type === "people") {
      api.get(`${type}/attendances/${id}?page=1&limit=${limit}&filter=${filter}&startDate=${duration[0].tz("Europe/London").format("YYYY-MM-DD HH:mm")}&endDate=${duration[1].tz("Europe/London").format("YYYY-MM-DD HH:mm")}`).then(res => {
        const totalHeader = res.headers["x-total-count"];
        setTotal(totalHeader);
        setPageCount(Math.ceil(totalHeader / limit) || 1);
        setResults(res.data || []);
        setLoading(false);
        setInit(true);
        tableLoaded && tableLoaded(totalHeader);
        if (res.data && res.data.length > 0) {
          let end = moment(res.data[0].start).endOf("days");
          let start = moment(res.data[0].start).subtract(7, "day").startOf("days");
          resetDuration && resetDuration(start, end);
        } else {
          resetDuration && resetDuration(moment().subtract(7, "day").startOf("days"), moment().endOf("days"));
        }
      });
    } else if (type === "zone") {
      api.get(`zones/pastcontents/${id}?page=1&limit=${limit}&filter=${filter}&startDate=${duration[0].tz("Europe/London").format("YYYY-MM-DD HH:mm")}&endDate=${duration[1].tz("Europe/London").format("YYYY-MM-DD HH:mm")}`).then(res => {
        const totalHeader = res.headers["x-total-count"];
        setTotal(totalHeader);
        setPageCount(Math.ceil(totalHeader / limit) || 1);
        setResults(res.data || []);
        setLoading(false);
        setInit(true);
        tableLoaded && tableLoaded(totalHeader);
        if (res.data && res.data.length > 0) {
          let end = moment(res.data[0].start).endOf("days");
          let start = moment(res.data[0].start).subtract(7, "day").startOf("days");
          resetDuration && resetDuration(start, end);
        } else {
          resetDuration && resetDuration(moment().subtract(7, "day").startOf("days"), moment().endOf("days"));
        }
      });
    } else if (type === "assetsPast" || type === "peoplePast") {
      api.get(`${type === "assetsPast" ? "assets" : "people"}/pastcontents/${id}?page=1&limit=${limit}&filter=${filter}&startDate=${duration[0].tz("Europe/London").format("YYYY-MM-DD HH:mm")}&endDate=${duration[1].tz("Europe/London").format("YYYY-MM-DD HH:mm")}`).then(res => {
        const totalHeader = res.headers["x-total-count"];
        setTotal(totalHeader);
        setPageCount(Math.ceil(totalHeader / limit) || 1);
        setResults(res.data || []);
        setLoading(false);
        setInit(true);
        tableLoaded && tableLoaded(totalHeader);
        if (res.data && res.data.length > 0) {
          let end = moment(res.data[0].start).endOf("days");
          let start = moment(res.data[0].start).subtract(7, "day").startOf("days");
          resetDuration && resetDuration(start, end);
        } else {
          resetDuration && resetDuration(moment().subtract(7, "day").startOf("days"), moment().endOf("days"));
        }
      });
    }
  };

  const getDuration = (time) => {
    if (time <= 0) return 'Unknown'
    let currentTime = moment.duration(time, "seconds");
    if (currentTime.days() > 0) {
      let hours = currentTime.days() * 24 + currentTime.hours();
      let min = currentTime.minutes();
      return hours + ":" + min;
    }
    return moment({
      h: currentTime.hours(),
      m: currentTime.minutes(),
      s: currentTime.seconds()
    }).format("HH:mm");
  };

  const handleSwitchPage = (cur) => {
    setCurrentPage(cur);
    setLoading(true);
    if (type === "assets" || type === "people") {
      api.get(`${type}/attendances/${id}?page=${cur}&sort=${tableSort}&limit=${limit}&filter=${filter}&startDate=${duration[0].tz("Europe/London").format("YYYY-MM-DD HH:mm")}&endDate=${duration[1].tz("Europe/London").format("YYYY-MM-DD HH:mm")}`)
        .then(res => {
          initSort();
          const totalHeader = res.headers["x-total-count"];
          setTotal(totalHeader);
          setPageCount(Math.ceil(totalHeader / limit) || 1);
          setResults(res.data || []);
          setLoading(false);
          tableLoaded && tableLoaded(totalHeader);
        }).catch(() => {
          setTotal(0);
          initSort();
          setPageCount(1);
          setResults([]);
          setLoading(false);
          tableLoaded && tableLoaded(0);
        });
    } else if (type === "zone") {
      api.get(`zones/pastcontents/${id}?page=${cur}&sort=${tableSort}&limit=${limit}&filter=${filter}&startDate=${duration[0].tz("Europe/London").format("YYYY-MM-DD HH:mm")}&endDate=${duration[1].tz("Europe/London").format("YYYY-MM-DD HH:mm")}`)
        .then(res => {
          const totalHeader = res.headers["x-total-count"];
          setTotal(totalHeader);
          initSort();
          setPageCount(Math.ceil(totalHeader / limit) || 1);
          setResults(res.data || []);
          setLoading(false);
          tableLoaded && tableLoaded(totalHeader);
        }).catch(() => {
          setTotal(0);
          initSort();
          setPageCount(1);
          setResults([]);
          setLoading(false);
          tableLoaded && tableLoaded(0);
        });
    } else if (type === "assetsPast" || type === "peoplePast") {
      api.get(`${type === "assetsPast" ? "assets" : "people"}/pastcontents/${id}?page=1&sort=${tableSort}&limit=${limit}&filter=${filter}&startDate=${duration[0].tz("Europe/London").format("YYYY-MM-DD HH:mm")}&endDate=${duration[1].tz("Europe/London").format("YYYY-MM-DD HH:mm")}`).then(res => {
        const totalHeader = res.headers["x-total-count"];
        setTotal(totalHeader);
        initSort();
        setPageCount(Math.ceil(totalHeader / limit) || 1);
        setResults(res.data || []);
        setLoading(false);
        tableLoaded && tableLoaded(totalHeader);
      }).catch(() => {
        setTotal(0);
        initSort();
        setPageCount(1);
        setResults([]);
        setLoading(false);
        tableLoaded && tableLoaded(0);
      });
      ;
    }
    setInit(true);
  };

  const initSort = () => {
    if (tableSort) {
      let arr = tableSort.concat();
      let sortParamArr = arr.split(".");
      if (sortParamArr.length < 2) return;
      allColumns.forEach(item => {
        if (item.id === sortParamArr[0] && sortParamArr[1] === "desc") {
          item.toggleSortBy(true);
        } else if (item.id === sortParamArr[0] && sortParamArr[1] === "asc") {
          item.toggleSortBy(false);
        }
      });
    }
    return;
  };


  return (
    <React.Fragment>
      {(data.length > 0 || (data.length === 0 && filter !== "") || loading) && <>
        <Row className="d-flex justify-content-between w-100">
          <Col sm={12} md={6} className="d-flex align-items-center dataTables_length">
            <div className="d-flex align-items-center page-select-container">
              <span>Show</span>
              <SelectHeader limit={limit} onChange={onChangeSelect} />
              <span>entries</span>
            </div>
          </Col>
          <Col sm={12} md={6}
            className="d-flex dataTables_filter justify-content-lg-end justify-content-sm-center align-items-center">
            <span>Search:</span>
            <DebounceInput
              className="table-search debounceInput-search text-gray"
              minLength={0}
              debounceTimeout={500}
              value={filter}
              onChange={event => (filterResults(event.target.value))}
            />
          </Col>
        </Row>
        {loading && data.length === 0 ? <Spinner animation="border" /> : (
          data.length > 0 ? <>
            <Table responsive="sm" striped {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      // Add the sorting props to control sorting. For this example
                      // we can add them into the header props
                      <th {...column.getHeaderProps(column.getSortByToggleProps())} className={column.className}>
                        {(type !== "asset" && column.Header === "Zone") ? "Name" : column.render("Header")}
                        {/* Add a sort direction indicator */}
                        <span>
                          {!column.disableSortBy && <>{column.isSorted ? (
                            column.isSortedDesc ? (
                              <FontAwesomeIcon icon={faSortDown} className="ms-2" />
                            ) : (
                              <FontAwesomeIcon icon={faSortUp} className="ms-2" />
                            )
                          ) : (
                            <FontAwesomeIcon icon={faSort} className="ms-2" />
                          )}</>}
                        </span>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {loading && <tr className="table-spinner">
                  <td colSpan={allColumns.length}>
                    <LoadingSpin loading={loading} />
                  </td>
                </tr>}
                {rows.map((item, index) => {
                  return (
                    <tr key={index}>
                      {(type === "assets" || type === "people") && <td>
                        <div className="d-flex align-items-center">
                          <NavLink className="link-primary" to={{
                            pathname: `/${item.original.parentType === "asset" ? "assets" : "zones"}/edit`,
                            search: `?id=${item.original.parentId}`
                          }}>{item.values.parentName}</NavLink>
                          {item.original.parentHashTags && item.original.parentHashTags.map(item1 => <div key={item1}
                            className="ms-1 badge d-flex align-items-center bg-primary me-1">{item1}</div>)}
                        </div>
                      </td>}
                      {type === "zone" && <td>
                        <div className="d-flex align-items-center">
                          <NavLink className="link-primary" to={{
                            pathname: `/${item.original.childType === "person" ? "people" : `${item.original.childType}s`}/edit`,
                            search: `?id=${item.original.childId}`
                          }}>
                            {/* {item.original.childName + `${item.original.childReference ? ` (${item.original.childReference})` : ""}`} */}
                            {childItemDisplayName(item.original)}
                          </NavLink>
                          {item.original.childHashTags && item.original.childHashTags.map(item1 => <div key={item1}
                            className="ms-1 d-flex align-items-center badge bg-primary me-1">{item1}</div>)}
                        </div>
                      </td>}
                      {type === "assetsPast" && <td>
                        <div className="d-flex align-items-center">
                          <NavLink className="link-primary" to={{
                            pathname: `/${item.original.childType === "person" ? "people" : `${item.original.childType}s`}/edit`,
                            search: `?id=${item.original.childId}`
                          }}>{item.original.childName + `${item.original.childReference ? ` (${item.original.childReference})` : ""}`}</NavLink>
                          {item.original.childHashTags && item.original.childHashTags.map(item1 => <div key={item1}
                            className="ms-1 d-flex align-items-center badge bg-primary me-1">{item1}</div>)}
                        </div>
                      </td>}
                      <td>
                        <span>{moment(item.values.start).format("DD/MM/YY HH:mm")}</span>
                      </td>
                      <td>
                        <span>{item.values.end.indexOf('0001-01-01') > -1 ? 'Missed' : moment(item.values.end).format("DD/MM/YY HH:mm")}</span>
                      </td>
                      <td>
                        <span>{getDuration(item.values.duration)}</span>
                      </td>
                      <td>
                        <span>{item.values.rate > 0 ? item.values.rate : ""}</span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            <Row className="d-flex align-items-center">
              <Col md={5} sm={12}
                className="dataTables_info">{`Showing ${(currentPage - 1) * limit + 1} to ${(currentPage - 1) * limit + data.length} of ${total} entries`}</Col>
              <Col md={7} sm={12} className="dataTables_paginate d-md-flex justify-content-md-end">
                <ReactPaginate
                  previousLabel={"previous"}
                  nextLabel={"next"}
                  breakLabel={"..."}
                  pageCount={pageCount}
                  forcePage={currentPage - 1}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination m-0"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
              </Col>
            </Row>
          </> : <div
            className="mt-2 w-100 p-3 text-center mt-3 mb-3 bg-light">{"No data available"}</div>)}
      </>}
      {!loading && filter === "" && data.length === 0 && <div>
        <div>{(type !== "assetsPast" && type !== "peoplePast") ? emptyText : "No data available"}</div>
      </div>}
    </React.Fragment>
  );
});
export default TableAttendance;