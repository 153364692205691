import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { X } from "react-feather";
import { NavLink } from "react-router-dom";
import LastLocation from "../LastLocation";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SrlLastLocation from "../../srl/components/SrlLastLocation";
import useSrlHelper from "../../hooks/useSrlHelper";

const thingsArr = { asset: "assets", person: "people", zone: "zones", assets: 'assets', people: 'people', zones: 'zones' };
const InfoWindow = (props) => {
  const [zIndex, setZIndex] = useState(10000000);
  const { isSrl, itemDisplayName } = useSrlHelper();

  useEffect(() => {
    if (props.show.active) {
      setZIndex(10000001);
    } else {
      setZIndex(10000000);
    }
  }, [props.show.active]);

  const infoWindowStyle = {
    position: "absolute",
    bottom: "20px",
    left: "50%",
    transform: "translate(-50%)",
    minWidth: 240,
    boxShadow: "0 2px 7px 1px rgba(0, 0, 0, 0.3)",
    fontSize: 13,
    zIndex: zIndex,
    flexWrap: "nowrap",
    borderRadius: "8px",
    cursor: "default"
  };

  return !props.show.hide ? <div style={infoWindowStyle} className="bg-white marker-label" onClick={(e) => {
    e.stopPropagation();
    props.clickTarget(props.show);
  }}>
    <Card className="m-0">
      <Card.Body className="pb-0 pt-1 pe-1 ps-2">
        <X className="float-end cursor-pointer" size={18} onClick={(e) => props.onClose(e)} />
        <h5 className="mt-2 mb-0">
          <NavLink to={{
            pathname: `/${thingsArr[props.show.lastPosition.type]}/edit`,
            search: `?id=${props.show.lastPosition.id}`
          }}>
            {/* {itemDisplayName(props.show.lastPosition)} */}
            {isSrl() ? <>{itemDisplayName(props.show.lastPosition)}</> : <>{props.show.lastPosition.compoundName} {props.show.lastPosition?.reference ? `(${props.show.lastPosition.reference})` : ""}</>}

          </NavLink>
        </h5>
      </Card.Body>
    </Card>
    <div className="ps-2 pe-2">
      {
        isSrl() ?
          <>
            {props.show.lastPosition &&
              <Card className="mb-0 border-top">
                <Card.Body className={'p-0'}>
                  <div data-toggle="tooltip" title="" data-placement="right" className="text-nowrap">
                    <FontAwesomeIcon icon={faLink} fixedWidth className="me-1" /> SRL Fleet #: <b>{props.show.lastPosition.fleetNumber}</b>
                  </div>
                  <div data-toggle="tooltip" title="" data-placement="right" className="text-nowrap">
                    <FontAwesomeIcon icon={faLink} fixedWidth className="me-1" /> Customer Fleet #: <b>{props.show.lastPosition.suppString2}</b>
                  </div>
                </Card.Body>
              </Card>
            }
          </> :
          <>
          </>
      }
      {isSrl() ? <SrlLastLocation profile={props.show} markerLabel detail > </SrlLastLocation> : <LastLocation profile={props.show} markerLabel detail />}
    </div>
  </div> : null
};
export default InfoWindow

