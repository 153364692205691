import React from "react";
// all icons stored in front-end, this is the method to show icons
const IconPage = ({title, imageList, onSelectIcon}) => {
  const chooseImg = (item) => {
    onSelectIcon(item);
  }
  return <React.Fragment>
    <h3 className="mt-3 mb-2">{title}</h3>
    <div className="d-flex flex-wrap">
      {imageList.map((item, index) => (
        <img key={index} src={require(`./../../../assets/img/icon/${item}`).default} alt="" className="icon-item-img m-3 cursor-pointer" onClick={() => chooseImg(item)}/>
      ))}
    </div>
  </React.Fragment>
}
export default IconPage