import { Accordion, Button, Card, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const AttendanceLiveWidget = ({ name, width }) => {
    const navigate = useNavigate();

    return (
        <>
            <Col key={name} lg={(width / 100 * 12).toFixed(0)} className="mb-4">
                <Card className="flex-fill w-100 h-100" style={{ minHeight: "210px" }}>
                    <Card.Title className="p-3">Live Attendance Report</Card.Title>
                    <Card.Text className="p-3" as="div">
                        <Accordion defaultActiveKey="">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>Run a report to see the current attendance of all assets and people.</Accordion.Header>
                                <Accordion.Body>
                                    <p>An attendance is the record created that shows presence of an asset or a person within a zone or onboard a vehicle. An attendance remains live while the asset or person stays within the zone or onboard the vehicle.</p>
                                    <p>This report includes all current live attendances for assets and people.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Card.Text>
                    <Button variant="primary" onClick={() => navigate("/report/attendancelive")}>Show report</Button>
                </Card>
            </Col>
        </>
    );
};

export default AttendanceLiveWidget;
