import React, { useContext, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Button, Card, Container } from "react-bootstrap";
import CommonTable from "../../components/tables/CommonTable";
import NotyfContext from "../../contexts/NotyfContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import TaskTypeModal from "./TaskTypeModal";
import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { Bookmark } from "react-feather";

const TaskTypes = ({type}) => {
  const [resultCount, setResultCount] = useState(0);
  const [current, setOld] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [currentAction, setCurrent] = useState('');
  const childRef = useRef();
  const notify = useContext(NotyfContext);
  const { user } = useAuth()
  const navigate = useNavigate();

  useEffect(() => {
    if(type && user && !user[type]) {
      navigate('/')
    }
  }, [user, type])

  const columns = [
    {
      Header: "Name",
      accessor: "name",
      Cell: (cell) => <div>
        <span className="text-primary cursor-pointer text-nowrap" onClick={() => {setCurrent('Edit');setOld(cell.row.original);setShowModal(true)}}>{cell.value}</span>
      </div>
    },
    {
      Header: "Description",
      accessor: "description",
      className: 'w-75',
      Cell: (cell) => <div className="text-nowrap overflow-hidden" title={cell.value} style={{textOverflow: 'ellipsis', minWidth: 0}} >{cell.value}</div>
    },
  ];

  const btnClick = (name, id) => {
    setCurrent(name);
    setOld(id);
    setShowModal(true);
  }

  const handleSuccess =() => {
    notify.open({
      type: 'success',
      message: 'Changes Saved'
    })
    if (currentAction === 'Edit') {
      childRef.current.updateTable();
    } else {
      childRef.current.updateTable(1);
    }
    setShowModal(false);
  }

  return (
    <React.Fragment>
      <Helmet defer={false}  title="Task Types" />
      <Container fluid className="p-0">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h1 className="h3 mb-0"><Bookmark className={'me-2'} />Task Types {typeof(resultCount) === 'string' && `(${resultCount})`}</h1>
          <Button onClick={() => {setOld(null); setCurrent('Create'); setShowModal(true)}} ><FontAwesomeIcon icon={faPlus} fixedWidth className="me-1" />Create New</Button>
        </div>
        <Card className="bg-white p-3">
          <CommonTable cssClass="table-fixed" ref={childRef} setCountParent={setResultCount} btnClick={btnClick} apiName="tasks/types" columns={columns}/>
        </Card>
      </Container>
      <TaskTypeModal handleSuccess={handleSuccess} closeModal={() => setShowModal(false)} show={showModal} action={currentAction} current={current} apiName="tasks/types"/>
    </React.Fragment>
  )
};
export default TaskTypes;