import { useRef, useState } from "react";
import useTheme from "./useTheme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBatteryEmpty, faBatteryFull, faBatteryHalf, faBatteryQuarter, faBatteryThreeQuarters, faHourglassHalf, faPlug } from "@fortawesome/free-solid-svg-icons";
import moment from "moment-timezone";
import { MapPin, Moon } from "react-feather";
import { dateFromNow } from "../utils/staticMethods";
import useAuth from "./useAuth";
import { NavLink } from "react-router-dom";

const useSrlHelper = () => {
    const { theme } = useTheme();
    const [isSrlLocal] = useState(theme === "srl");
    const curTime = useRef(moment());
    const { user } = useAuth();


    // returns a function which returns the display name for a given asset item
    const itemDisplayName = (item) => {
        if (!(item && item["type"])) return <>&nbsp;</>;
        if (item.type?.includes("asset") && isSrlLocal) {

            if (item.fleetNumber === "OEM") {
                return item.suppString2 || item.fleetNumber;
            }
            else {
                return item.fleetNumber || "?";
            }
        }
        else {
            return item.compoundName || item.primaryName || "??";
        }
    };

    const assetItemDisplayNameWithRef = (asset) => {
        if (!asset) return "";
        if (asset.type?.includes("asset") && isSrlLocal) {
            if (asset.fleetNumber === "OEM") {
                return asset.suppString2 || asset.fleetNumber;
            }
            else {
                return asset.fleetNumber || "?";
            }
        }
        else {
            return asset.compoundName + (asset.reference ? (" " + asset.reference) : "");
        }
    };

    const childItemDisplayName = (childItem) => {
        if (!childItem) return "";
        var archivedString = "";
        if (childItem.childType?.includes("archived")){
            archivedString = " (archived)"
        }
        if (childItem.childType?.includes("asset") && isSrlLocal) {

            if (childItem.childFleetNumber === "OEM") {
                return (childItem.childSuppString2 || childItem.childFleetNumber) + archivedString;
            }
            else {
                return (childItem.childFleetNumber || "?") + archivedString;
            }
        }
        else {
            return childItem.childName + (childItem.childReference ? ("(" + childItem.childReference + ")") : "") + archivedString;
        }
    };

    const renderFleetNumber = (item) => {
        // used by MapDetail, renders both SRL and Customer fleet numbers, or just fleet number if not SRL
        // returns undefined if nothing to display
        if (!item) return undefined;
        if (item.type?.includes("asset") && isSrlLocal) {

            return <>
                <div className="p-1">SRL Fleet Number:&nbsp;{item.fleetNumber || ""}</div>
                <div className="p-1">Customer Fleet Number:&nbsp;{item.suppString2 || ""}</div>
            </>;
        }
        else if (item.type?.includes("asset")) {
            return <>Fleet Number:&nbsp;{item.fleetNumber || ""}</>;
        }
        else {
            return undefined;
        }
    };

    const linkStatusItemDisplayName = (row, cell) => {
        if (isSrlLocal) {
            var name = "";
            var fleetNumber = row.original.assetFleetNumber ?? "?";
            var customerFleetNumber = row.original.assetSuppString2 ?? "??";

            if (fleetNumber === "OEM") {
                name = customerFleetNumber;
            }
            else {
                name = fleetNumber;
            }

            return (<>
                {name}
                {row.original.assetType && row.original.assetType?.startsWith("archived") ? ` (archived)` : ""}
            </>);
        }
        else {
            return (
                <>
                    {row.original.assetCompoundName || (cell && cell.value)} {(row.original.assetReference || (cell && row.original[cell.column.reference])) ? `(${row.original.assetReference || row.original[cell.column.reference]})` : ""}
                    {row.original.assetType && row.original.assetType?.startsWith("archived") ? ` (archived)` : ""}
                </>
            );
        }
    };

    const deviceDetailAssetItemDisplayName = (asset) => {
        if (!asset) return <></>;
        if (isSrlLocal) {
            if (asset.fleetNumber === "OEM") {
                return asset.assetSuppString2 || asset.assetFleetNumber;
            }
            else {
                return asset.assetFleetNumber || "?";
            }
        }
        else {
            return (<>{`${asset.assetCompoundName} ${asset.assetReference ? `(${asset.assetReference})` : ""}`}</>);
        }
    };

    const getAssetColumnsForCommonTable = () => {
        var assetColumns = [];

        if (isSrlLocal) {
            assetColumns.push({
                Header: "Asset Owner",
                accessor: "suppString1",
                visibilityName: "asset-owner",
                type: "link",
                link: "/assets/edit",
                stateId: "id",
            });

            assetColumns.push({
                Header: "SRL Fleet #",
                accessor: "fleetNumber",
                visibilityName: "srl-fleet-number",
                type: "link",
                link: "/assets/edit",
                stateId: "id",
            });

            assetColumns.push({
                Header: "Customer Fleet #",
                accessor: "suppString2",
                visibilityName: "customer-fleet-number",
                type: "link",
                link: "/assets/edit",
                stateId: "id",
            });

            assetColumns.push({
                Header: "SRL Product Name",
                accessor: "category",
                visibilityName: "srl-product-name",
                type: "link",
                link: "/assets/edit",
                stateId: "id",
            });

            assetColumns.push({
                Header: <i>Customer Reference</i>,
                accessor: "primaryName",
                visibilityName: "cust-ref",
                type: "link",
                link: "/assets/edit",
                stateId: "id",
            });

            assetColumns.push({
                Header: "Asset Battery Status",
                accessor: "lastPosition.supply",
                visibilityName: "supply",
                Cell: (cell) => { return <div className="d-flex align-items-center">{renderSupply(cell.row?.original?.lastPosition?.supply)}</div> },
                // disableSortBy: true,
            });

            assetColumns.push({
                Header: "Last Seen",
                accessor: "lastSeen",
                visibilityName: "last-seen",
                Cell: (cell) => {
                    if (cell.value && cell.value.indexOf("0001-01-01") === -1) {
                        return <><MapPin width={14} height={14} className="me-1" />{curTime.current ? dateFromNow(cell.value, curTime.current) : dateFromNow(cell.value)}</>;
                    }
                    return <span><Moon width={14} height={14} className="me-1" /> Waiting...</span>;
                },
            });

            assetColumns.push({
                Header: "Location Coordinates",
                accessor: "lastPosition",
                visibilityName: "loc-coord",
                disableSortBy: true,
                Cell: (cell) => {
                    if (cell.value) {
                        var lat = Number(cell.value.latitude).toFixed(6);
                        var lng = Number(cell.value.longitude).toFixed(6);
                        return (
                            <>
                                {lat},{lng}
                            </>
                        );
                    }
                    else {
                        return (<>Waiting...</>);
                    }
                },
            });

            if (user?.role === "Root") {
                assetColumns.push({
                    Header: "Customer",
                    accessor: "customerName",
                    show: user?.role === "Root",
                    disableSortBy: true,
                    Cell: cell => (<NavLink
                        to={{ pathname: `/customers/edit`, search: `?id=${cell.row?.original?.customerId}` }}>{cell.value}</NavLink>)
                });
            }
            return assetColumns;
        }
        else {
            assetColumns = assetColumns.concat(
                [
                    {
                        Header: "Name",
                        accessor: "compoundName",
                        type: "link",
                        link: "/assets/edit",
                        stateId: "id",
                        disableSortBy: false,
                    },
                    {
                        Header: " ",
                        accessor: "",
                        disableSortBy: true
                    }
                ]
            );
        }
        return assetColumns;
    };

    const getAssetColumnsForSelectionTable = () => {
        var assetColumns = [];

        if (isSrlLocal) {

            assetColumns.push({
                Header: "Asset Owner",
                accessor: "suppString1",
                visibilityName: "asset-owner",
                type: "link",
                link: "/assets/edit",
                stateId: "id",
            });

            assetColumns.push({
                Header: "SRL Fleet #",
                accessor: "fleetNumber",
                visibilityName: "srl-fleet-number",
                type: "link",
                link: "/assets/edit",
                stateId: "id",
            });

            assetColumns.push({
                Header: "Customer Fleet #",
                accessor: "suppString2",
                visibilityName: "customer-fleet-number",
                type: "link",
                link: "/assets/edit",
                stateId: "id",
            });

            assetColumns.push({
                Header: "SRL Product Name",
                accessor: "category",
                visibilityName: "srl-product-name",
                type: "link",
                link: "/assets/edit",
                stateId: "id",
            });

            assetColumns.push({
                Header: "Customer Reference",
                accessor: "primaryName",
                visibilityName: "cust-ref",
                type: "link",
                link: "/assets/edit",
                stateId: "id",
            });

            assetColumns.push({
                Header: "Asset Battery",
                accessor: "lastPosition.supply",
                visibilityName: "supply",
                Cell: (cell) => { return <div className="d-flex align-items-center">{renderSupply(cell.row?.original?.lastPosition?.supply)}</div> },
                // disableSortBy: true,
            });

            assetColumns.push({
                Header: "Last Seen",
                accessor: "lastSeen",
                visibilityName: "last-seen",
                Cell: (cell) => {
                    if (cell.value && cell.value.indexOf("0001-01-01") === -1) {
                        return <><MapPin width={14} height={14} className="me-1" />{curTime.current ? dateFromNow(cell.value, curTime.current) : dateFromNow(cell.value)}</>;
                    }
                    return <span><Moon width={14} height={14} className="me-1" /> Waiting...</span>;
                },
            });

            assetColumns.push({
                Header: " ",
                accessor: "",
                disableSortBy: true
            });
        }
        else {
            assetColumns = assetColumns.concat([
                {
                    Header: "Name",
                    accessor: "compoundName",
                    type: "link",
                    link: "/assets/edit",
                    stateId: "id",
                    disableSortBy: false,
                },
                {
                    Header: " ",
                    accessor: "",
                    disableSortBy: true
                }
            ]);
        }

        return assetColumns;
    };

    const getAssetColumnsForRowSelection = () => {
        var assetColumns = [];

        if (isSrlLocal) {
            assetColumns.push({
                Header: " ",
                accessor: "",
                disableSortBy: true
            });

            assetColumns.push({
                Header: "Asset Owner",
                accessor: "suppString1",
                visibilityName: "asset-owner",
                // type: "link",
                // link: "/assets/edit",
                // stateId: "id",
            });

            assetColumns.push({
                Header: "SRL Fleet #",
                accessor: "fleetNumber",
                visibilityName: "srl-fleet-number",
                // type: "link",
                // link: "/assets/edit",
                // stateId: "id",
            });

            assetColumns.push({
                Header: "Customer Fleet #",
                accessor: "suppString2",
                visibilityName: "customer-fleet-number",
                // type: "link",
                // link: "/assets/edit",
                // stateId: "id",
            });

            assetColumns.push({
                Header: "SRL Product Name",
                accessor: "category",
                visibilityName: "srl-product-name",
                // type: "link",
                // link: "/assets/edit",
                // stateId: "id",
            });

            assetColumns.push({
                Header: "Customer Reference",
                accessor: "primaryName",
                visibilityName: "cust-ref",
                // type: "link",
                // link: "/assets/edit",
                // stateId: "id",
            });

        }
        else {
            assetColumns = assetColumns.concat(
                [
                    {
                        Header: " ",
                        accessor: "",
                        disableSortBy: true
                    },
                    {
                        Header: "Name",
                        accessor: "compoundName"
                    },
                    {
                        Header: "HashTags",
                        accessor: "hashTags",
                        disableSortBy: true
                    }
                ]
            );
        }
        return assetColumns;
    };

    const getAssetColumnsForBatteryLevelPage = () => {
        var assetColumns = [];

        if (isSrlLocal) {
            assetColumns.push({
                Header: "Asset Owner",
                accessor: "suppString1",
                visibilityName: "asset-owner",
                type: "link",
                link: "/assets/edit",
                stateId: "id",
            });

            assetColumns.push({
                Header: "SRL Fleet #",
                accessor: "fleetNumber",
                visibilityName: "srl-fleet-number",
                type: "link",
                link: "/assets/edit",
                stateId: "id",
            });

            assetColumns.push({
                Header: "Customer Fleet #",
                accessor: "suppString2",
                visibilityName: "customer-fleet-number",
                type: "link",
                link: "/assets/edit",
                stateId: "id",
            });

            assetColumns.push({
                Header: "SRL Product Name",
                accessor: "category",
                visibilityName: "srl-product-name",
                type: "link",
                link: "/assets/edit",
                stateId: "id",
            });

            assetColumns.push({
                Header: "Customer Reference",
                accessor: "primaryName",
                visibilityName: "cust-ref",
                type: "link",
                link: "/assets/edit",
                stateId: "id",
            });
        } else {
            assetColumns = assetColumns.concat([
                {
                    Header: "",
                    accessor: "icon",
                    type: "icon",
                    disableSortBy: true
                },
                {
                    Header: "Name (Ref)",
                    accessor: "compoundName",
                    type: "link",
                    link: `/assets/edit`,
                    stateId: "id"
                },
                {
                    Header: "Last Seen",
                    accessor: "lastSeen",
                    Cell: (cell) => {
                        if (cell.value && cell.value.indexOf("0001-01-01") === -1) {
                            return <><MapPin width={14} height={14} className="me-1" />{dateFromNow(cell.value)}</>;
                        }
                        return <span><Moon width={14} height={14} className="me-1" /> Waiting...</span>;
                    }
                },
                {
                    Header: "Supply",
                    accessor: "supply",
                    Cell: cell => (
                        <span>{cell.row.original?.lastPosition?.supply !== null ? cell.row.original.lastPosition.supply + ' Mv' : '-'}</span>
                    )
                },
                {
                    Header: "Hashtags",
                    accessor: "hashTags",
                    Cell: cell => (<div>
                        {cell.value && cell.value.map((tag, i) => <NavLink key={i} to={{ pathname: '/globalSearch', search: `?searchString=${encodeURIComponent('#' + tag)}` }}><span className='badge bg-primary me-1 my-1 cursor-pointer'>{tag}</span></NavLink>)}
                    </div>),
                    disableSortBy: true
                }
            ]
            );
        }

        return assetColumns;
    };

    const getAssetColumnsForNearByAssets = (setShow) => {
        var assetColumns = [];

        if (isSrlLocal) {
            assetColumns.push({
                Header: "SRL Fleet #",
                accessor: "fleetNumber",
                visibilityName: "srl-fleet-number",
                type: "link",
                link: "/assets/edit",
                stateId: "id",
            });

            assetColumns.push({
                Header: "Customer Fleet #",
                accessor: "suppString2",
                visibilityName: "customer-fleet-number",
                type: "link",
                link: "/assets/edit",
                stateId: "id",
            });

            assetColumns.push({
                Header: "SRL Product Name",
                accessor: "category",
                visibilityName: "srl-product-name",
                type: "link",
                link: "/assets/edit",
                stateId: "id",
            });

            assetColumns.push({
                Header: "Asset Battery",
                accessor: "lastPosition.supply",
                visibilityName: "supply",
                Cell: (cell) => {
                    let supply = cell.row?.original?.supply;

                    if (typeof (supply) === "number") {
                        let level = '';

                        if (supply === 0 || supply === 500) {
                            level = 'Disconnected';
                        } else if (supply === 1) {
                            level = 'Connected';
                        } else if (supply > 12250) {
                            level = 'High';
                        } else if (supply > 11500 && supply <= 12250) {
                            level = 'Medium';
                        } else if (supply > 0 && supply <= 11500) {
                            level = 'Low';
                        } else {
                            level = 'Waiting for data';
                        }

                        return (
                            <>
                                {level === "High" && <FontAwesomeIcon icon={faBatteryFull} className="srl-green" />}
                                {level === "Medium" && <FontAwesomeIcon icon={faBatteryHalf} className="srl-canary" />}
                                {level === "Low" && <FontAwesomeIcon icon={faBatteryQuarter} className="srl-red" />}
                                {level === "Connected" && <FontAwesomeIcon icon={faPlug} className="grey-500" />}
                                {level === "Disconnected" && <FontAwesomeIcon icon={faPlug} className="grey-500" />}
                                {level === "Waiting for data" && <FontAwesomeIcon icon={faHourglassHalf} className="grey-500" />}
                                <span>&nbsp;{level}</span>
                                {(["Low", "Medium", "High"].includes(level)) && supply && <span>&nbsp;({supply} Mv)</span>}
                            </>
                        );
                    }
                    else {
                        return <></>;
                    }
                },
                // disableSortBy: true,
            });
        }
        else {
            assetColumns = assetColumns.concat(
                [
                    {
                        Header: "",
                        accessor: "icon",
                        type: "icon",
                        width: 40,
                        headerStyle: {
                            width: "40px"
                        },
                        disableSortBy: true
                    }, {
                        Header: "Name",
                        accessor: "compoundName",
                        Cell: cell => <NavLink onClick={() => setShow(false)} to={{
                            pathname: `/assets/edit`,
                            search: `?id=${cell.row.original.id}`
                        }}>{`${cell.value} ${cell.row.original.reference ? `(${cell.row.original.reference})` : ""}`}</NavLink>
                    },
                    {
                        Header: "Speed",
                        accessor: "speed",
                        Cell: cell => {
                            return (
                                <span>{cell.value !== null && cell.value >= 0 ? Math.round((cell.value * 0.6214)) + " mph" : "-"}</span>
                            );
                        }
                    },
                    {
                        Header: "Supply",
                        accessor: "supply",
                        Cell: cell => (
                            <span>{cell.value ? cell.value + " Mv" : "-"}</span>
                        )
                    },
                    {
                        Header: "Battery",
                        accessor: "battery",
                        Cell: cell => {
                            if (cell.value !== null && cell.value !== "") {
                                if (cell.value > 75) {
                                    return <><FontAwesomeIcon icon={faBatteryFull} /> {cell.value}<span> %</span></>;
                                } else if (cell.value > 50 && cell.value <= 75) {
                                    return <><FontAwesomeIcon icon={faBatteryThreeQuarters} /> {cell.value} <span> %</span></>;
                                } else if (cell.value >= 25 && cell.value <= 50) {
                                    return <><FontAwesomeIcon icon={faBatteryHalf} /> {cell.value} <span> %</span></>;
                                } else {
                                    return <><FontAwesomeIcon icon={faBatteryEmpty} /> {cell.value} <span> %</span></>;
                                }
                            } else {
                                return "-";
                            }
                        }
                    }
                ]
            );
        }

        return assetColumns;
    };

    const getColumnsForMarkerModal = (setTableData) => {
        const thingsArr = { asset: "assets", person: "people", zone: "zones", assets: 'assets', people: 'people', zones: 'zones' };
        var columns = [];

        if (isSrlLocal) {
            // columns.push({
            //     Header: "Asset Owner",
            //     accessor: "suppString1",
            //     Cell: cell => <NavLink onClick={() => setTableData(null)} to={{
            //         pathname: `/${thingsArr[cell.row.original.type]}/edit`,
            //         search: `?id=${cell.row.original.id}`
            //     }}>
            //         {`${cell.value}`}
            //     </NavLink>,
            // });

            columns.push({
                Header: "SRL Fleet #",
                accessor: "fleetNumber",
                Cell: cell => <NavLink onClick={() => setTableData(null)} to={{
                    pathname: `/${thingsArr[cell.row.original.type]}/edit`,
                    search: `?id=${cell.row.original.id}`
                }}>
                    {`${cell.value}`}
                </NavLink>,
            });

            columns.push({
                Header: "Customer Fleet #",
                accessor: "suppString2",
                Cell: cell => <NavLink onClick={() => setTableData(null)} to={{
                    pathname: `/${thingsArr[cell.row.original.type]}/edit`,
                    search: `?id=${cell.row.original.id}`
                }}>
                    {`${cell.value}`}
                </NavLink>,
            });

            // columns.push({
            //     Header: "SRL Product Name",
            //     accessor: "category",
            //     Cell: cell => <NavLink onClick={() => setTableData(null)} to={{
            //         pathname: `/${thingsArr[cell.row.original.type]}/edit`,
            //         search: `?id=${cell.row.original.id}`
            //     }}>
            //         {`${cell.value}`}
            //     </NavLink>,
            // });

            // columns.push({
            //     Header: "Customer Reference",
            //     accessor: "primaryName",
            //     Cell: cell => <NavLink onClick={() => setTableData(null)} to={{
            //         pathname: `/${thingsArr[cell.row.original.type]}/edit`,
            //         search: `?id=${cell.row.original.id}`
            //     }}>
            //         {`${cell.value}`}
            //     </NavLink>,
            // });

            columns.push({
                Header: "Asset Battery Status",
                accessor: "supply",
                Cell: cell => <div className="d-flex align-items-center">{renderSupply(cell.value)}</div>,
            });
        }
        else {
            columns = columns.concat(
                [{
                    Header: "",
                    accessor: "icon",
                    type: "icon",
                    width: 40,
                    headerStyle: {
                        width: '40px'
                    },
                    disableSortBy: true
                }, {
                    Header: "Name",
                    accessor: "compoundName",
                    Cell: cell => <NavLink onClick={() => setTableData(null)} to={{
                        pathname: `/${thingsArr[cell.row.original.type]}/edit`,
                        search: `?id=${cell.row.original.id}`
                    }}>{`${cell.value} ${cell.row.original.reference ? `(${cell.row.original.reference})` : ""}`}</NavLink>
                }, {
                    Header: "Voltage",
                    accessor: "supply",
                    Cell: cell => <div>{cell.value && cell.value > 0 ? `${cell.value} Mv` : ''}</div>
                }]
            );
        }
        return columns;
    };

    const supplyLevels = {
        "waiting": {
            "title": "Waiting for data",
            "icon": faHourglassHalf,
            "className": "srl-carbon",
            "colour": "#272726",
        },
        "disconnected": {
            "title": "Disconnected",
            "icon": faPlug,
            "className": "srl-carbon",
            "colour": "#272726",
        },
        "connected": {
            "title": "Connected",
            "icon": faPlug,
            "className": "srl-carbon",
            "colour": "#272726",
        },
        "low": {
            "title": "Low",
            "icon": faBatteryQuarter,
            "className": "srl-red",
            "colour": "#e30513",
        },
        "medium": {
            "title": "Medium",
            "icon": faBatteryHalf,
            "className": "srl-canary",
            "colour": "#ffed00",
        },
        "high": {
            "title": "High",
            "icon": faBatteryFull,
            "className": "srl-green",
            "colour": "#2ea836",
        },
    };

    const getSupplyLevel = (supply) => {
        if (typeof (supply) !== "number") return undefined;

        if (supply === 0 || supply === 500) {
            return supplyLevels["disconnected"];
        } else if (supply === 1) {
            return supplyLevels["connected"];
        } else if (supply > 12250) {
            return supplyLevels["high"];
        } else if (supply >= 11750 && supply <= 12250) {
            return supplyLevels["medium"];
        } else if (supply > 0 && supply < 11750) {
            return supplyLevels["low"];
        } else {
            return supplyLevels["waiting"];
        }
    }

    const renderSupply = (supply, prefix = <></>, size) => {
        var supplyLevel = getSupplyLevel(supply);
        if (!supplyLevel) return <></>;
        var level = supplyLevel.title;
        return (
            <>
                <FontAwesomeIcon icon={supplyLevel.icon} size={size} className={`${supplyLevel.className} me-1`} />
                <div>{prefix}{level}</div>
                {(["Low", "Medium", "High"].includes(level)) && supply && <div>({supply} mV)</div>}
            </>
        );
    };

    const getBatteryIcon = (battery) => {
        if (typeof (battery) !== "number") return undefined;

        if (battery >= 10 && battery < 25) {
            return faBatteryQuarter;
        } else if (battery < 10) {
            return faBatteryEmpty;
        } else if (battery >= 25 && battery <= 50) {
            return faBatteryHalf;
        } else if (battery > 50 && battery <= 75) {
            return faBatteryThreeQuarters;
        } else {
            return faBatteryFull;
        }
    };

    const renderBattery = (battery) => {
        var batteryIcon = getBatteryIcon(battery);
        if (!batteryIcon) return <></>;

        return (
            <>
                <FontAwesomeIcon icon={getBatteryIcon(battery)} fixedWidth className="me-1" />{(isSrlLocal ? <>&nbsp;Tracker Battery:</> : "")} {`${battery} %`}
            </>
        )
    }

    return {
        // returns a bool indicating if the website is for SRL
        // can be use in controller as follows:
        // const isSrl = useIsSrl();
        isSrl: () => {
            return isSrlLocal;
        },
        itemDisplayName,
        assetItemDisplayNameWithRef,
        childItemDisplayName,
        linkStatusItemDisplayName,
        deviceDetailAssetItemDisplayName,
        renderFleetNumber,
        renderSupply,
        renderBattery,
        getAssetColumnsForCommonTable,
        getAssetColumnsForSelectionTable,
        getAssetColumnsForRowSelection,
        getAssetColumnsForBatteryLevelPage,
        getAssetColumnsForNearByAssets,
        getColumnsForMarkerModal,
    };
};

export default useSrlHelper;
