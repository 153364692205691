import React, { useEffect, useState } from "react";
import api from "../../api";
import { Grid, Link, Wifi } from "react-feather";
import { DebounceInput } from "react-debounce-input";
import { ListGroup, Spinner } from "react-bootstrap";

const ReaderSelector = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [showing, setShowing] = useState(false);
  const [current, setCurrent] = useState(null);
  const [firstLoad, setFirstLoad] = useState(false);
  const [defaultArray, setDefaultArray] = useState([]);

  const onSearch = (search) => {
    setLoading(true);
    api.get(`readers/positioned?page=1&filter=${search}`).then(res => {
      let filter = res.data
      setData(filter);
      setLoading(false);
      if(!firstLoad && props.showDefault) {
        props.onSelect && props.onSelect(filter[0] || null)
        setSearchString(filter[0]?.serial || '')
        setCurrent(filter[0] || null)
      }
      if(!firstLoad) {
        props.getAllReaders && props.getAllReaders(filter)
      }
      setDefaultArray(filter)
      setFirstLoad(true);
    }).catch(() => setLoading(false));
  };

  const onFilter = (str) => {
    if(str.length > 0) {
      let arr = defaultArray.filter(item => item.serial.toUpperCase().indexOf(str.toUpperCase()) > -1)
      setData(arr)
    } else {
      setData(defaultArray)
    }
  }

  useEffect(() => {
    if(!props.reader) return
    setCurrent(props.reader)
    setSearchString(props.reader.serial)
  }, [props.reader])

  useEffect(() => {
    setShowing(false);
  }, [props.showReader]);

  useEffect(() => {
    onSearch('')
  }, [])


  return <div style={{width: '200px'}} className="z-50 m-2 top-0 left-0 position-absolute mobile-style">
    <DebounceInput
      className="hashtag-input text-gray w-100"
      style={{ border: "1px solid #7f838e", padding: "4px 8px", fontSize: "12px", height: '32px' }}
      minLength={0}
      debounceTimeout={300}
      value={searchString}
      placeholder="Search readers"
      onClick={e => e.stopPropagation()}
      onFocus={(e) => {
        setShowing(true);
      }}
      onChange={event => {
        onFilter(event.target.value);
      }}
    />

    {loading &&
    <div className="w-100 bg-light d-flex justify-content-center p-2"><Spinner animation="border" /></div>}
    <div style={{ maxHeight: "500px" }} className="overflow-auto ms-1">
      {showing && <ListGroup>
        {data && data.length > 0 && data.map((item, index) => <ListGroup.Item
          onClick={(event) => {
            event.stopPropagation();
            setSearchString(item.floorPlanName);
            props.onSelect && props.onSelect(item);
            setShowing(false);
            setCurrent(item);
          }} key={index}
          className={`cursor-pointer list-group-hover ${current && item.id === current.id ? "bg-primary" : ""}`}>
          <Wifi size={14} />
          <span className="ms-1">{item.serial}</span>
          {item.wirepasNetworkId && !props.showMap && <Grid className="ms-2" size={14} />}
          {item.wirepas && !props.showMap && <Link className="ms-1" size={14} />}
        </ListGroup.Item>)}
      </ListGroup>}
    </div>
  </div>;
};
export default ReaderSelector;