import React, { useContext, useEffect, useState } from "react";
import CreateNewFloorPlan from "./CreateNewFloorPlan";
import useAuth from "../../hooks/useAuth";
import NotyfContext from "../../contexts/NotyfContext";
import { useLocation } from "react-router-dom";
import axios from "axios";
import api from "../../api";
import * as queryString from "query-string";
import { Button, Modal } from "react-bootstrap";

let source1 = null;

const FloorplanForm = () => {
  const [curFloor, setCurFloor] = useState(null);
  const [showDelete, setShowDelete] = useState(false);
  const [uploadFile, setUploadFile] = useState(null);
  const [pictureNew, setPictureNew] = useState(null);
  const [imageUploading, setUploading] = useState(false);
  const location = useLocation();
  const { id } = queryString.parse(location.search);
  const { user } = useAuth();
  const [customerOption, setCustomer] = useState([]);
  const notify = useContext(NotyfContext);

  useEffect(() => {
    if (id) {
      getFloorPlan(id)
      getPciNew(id)
    }
  }, [id]);

  useEffect(() => {
    if (user?.role === "Root") {
      getCustomers();
    }
  }, [user]);

  const getCustomers = () => {
    api.get(`customers?page=1&limit=999`, ).then(res => setCustomer(res.data)).catch(() => {
      setCustomer([]);
    });
  };


  const getFloorPlan = (id) => {
    api.get(`floorplans/${id}`).then(r => {
      setCurFloor(r.data)
    })
  }

  const getPciNew = (id) => {
    if (source1) {
      source1.cancel("request canceled");
    }
    source1 = axios.CancelToken.source();
    api.get(`files/floorplans/${id}`, {
      responseType: "arraybuffer",
      cancelToken: source1.token
    }).then(res => {
      let blob = new Blob([res.data], { type: "img/jpeg" });
      let url = (window.URL || window.webkitURL).createObjectURL(blob);
      setPictureNew(url);
      setUploading(false);
    }).catch(() => {
      setPictureNew(null);
      setUploading(false);
    });
  };

  useEffect(() => {
    return () => {
      if (source1) {
        source1.cancel("request canceled");
      }
    };
  }, []);

  const uploadPic = (id) => {
    let data = new FormData();
    data.append("uploadedFile", uploadFile[0]);
    let config = {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    };
    api.post(`files/floorplans/${id}`, data, config).then(() => {
      getPciNew(id);
    }).catch(r => {
      notify.open({
        type: "error",
        message: r
      });
      setUploading(false);
      setPictureNew(null);
    });
  };

  const handleSave = (data) => {
    if (curFloor) {
      api.put(`floorplans`, { ...data, id: curFloor.id }).then(res => {
        setCurFloor(null);
        setPictureNew(null);
        notify.open({
          type: 'success',
          message: 'Changes Saved'
        })
        window.history.back()
      });
    }
  };

  const onUpload = (center, name, customer) => {
    if (!uploadFile || uploadFile.length === 0) return;
    setUploading(true);
    if (curFloor) {
      setPictureNew(null);
      let obj = JSON.parse(JSON.stringify(curFloor));
      obj.northWestLatitude = center.lat().toString();
      obj.northWestLongitude = center.lng().toString();
      obj.southEastLatitude = null;
      obj.southWestLongitude = null;
      api.put(`floorplans`, obj).then(res => {
        setCurFloor(res.data);
      });
      api.delete(`files/floorplans/${curFloor.id}`).then(() => {
        uploadPic(curFloor.id);
      }).catch(r => uploadPic(curFloor.id));
    } else {
      let obj1 = {
        floorPlanName: name,
        northWestLatitude: center.lat().toString(),
        northWestLongitude: center.lng().toString()
      };
      if(customer) obj1.customerId = customer;
      api.post(`floorplans`, obj1).then(res => {
        setCurFloor(res.data);
        uploadPic(res.data?.id);
      });
    }
  };
  const onDelete = () => {
    setShowDelete(true);
  };

  const handleDelete = () => {
    pictureNew && api.delete(`files/floorplans/${curFloor?.id}`).then(() => setPictureNew(null))
    curFloor && api.delete(`floorplans/${curFloor.id}`).then(() => {
      setShowDelete(false);
      setCurFloor(null);
      setPictureNew(null);
      window.history.back()
    });
  };

  return <React.Fragment>
    <CreateNewFloorPlan id={id} uploading={imageUploading} customerOption={customerOption} handleSave={handleSave}
                        curFloor={curFloor} setUploadFile={(e) => setUploadFile(e)} onDelete={onDelete}
                        onHide={() => {
                          setPictureNew(false);
                          setCurFloor(null);
                          window.history.back();
                        }} picture={pictureNew} onUpload={onUpload} />
    <Modal show={showDelete} onHide={() => setShowDelete(false)}>
      <Modal.Header closeButton><Modal.Title>Please Confirm</Modal.Title></Modal.Header>
      <Modal.Body>
        <p>Are you sure that you wish to permanently delete this Floorplan?</p>
        <p>Deletions are not reversible.</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={() => handleDelete()}>Delete</Button>
        <Button variant="secondary" onClick={() => {
          setShowDelete(false);
        }}>Cancel</Button>
      </Modal.Footer>
    </Modal>
  </React.Fragment>
}
export default FloorplanForm