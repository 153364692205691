import React, { useContext } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { Alert, Button, Card, Form } from "react-bootstrap";
import api from "../../api";
import NotyfContext from "../../contexts/NotyfContext";

const NewCommand = ({apiName, type='New', onDelete, data, onUpdated, id}) => {
  const notify = useContext(NotyfContext);
  return (
    <Card>
      <Card.Body>
        <Formik
          enableReinitialize
          initialValues={{
            commandName: data?.commandName || '',
            commandString: data?.commandString || '',
            ackString: data?.ackString || '',
            type: data?.type || 'admin',
            submit: false
          }}
          validationSchema={Yup.object().shape({
            commandName: Yup.string()
              .max(255)
              .required("Customer Name is required"),
            commandString: Yup.string().max(255),
            type: Yup.string().max(255),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              if(type === 'New') {
                api.post(apiName, {
                  deviceProfileId: id,
                  commandName: values?.commandName,
                  commandString: values?.commandString,
                  ackString: values?.ackString,
                  type: values?.type,
                })
                  .then(() => {
                    notify.open({
                      type: "success",
                      message: "Change Saved"
                    });
                    onUpdated && onUpdated(1);
                  })
              } else {
                api.put(`${apiName}/${data.commandId}`, {
                  commandId: data.commandId,
                  deviceProfileId: id,
                  commandName: values?.commandName,
                  commandString: values?.commandString,
                  ackString: values?.ackString,
                  type: values?.type,
                })
                  .then(() => {
                    notify.open({
                      type: "success",
                      message: "Change Saved"
                    });
                    onUpdated && onUpdated()
                  })
              }

            } catch (error) {
              const message = error.message || "Something went wrong";
              setStatus({ success: false });
              setErrors({ submit: message });
              setSubmitting(false);
            }
          }}
        >
          {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
            <Form onSubmit={handleSubmit}>
              {errors.submit && (
                <Alert className="my-3" variant="danger">
                  <div className="alert-message">{errors.submit}</div>
                </Alert>
              )}

              <Form.Group className="mb-3">
                <Form.Label>Command Name</Form.Label>
                <Form.Control
                  size="md"
                  type="text"
                  name="commandName"
                  value={values.commandName}
                  isInvalid={Boolean(touched.commandName && errors.commandName)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {!!touched.commandName && (
                  <Form.Control.Feedback type="invalid">
                    {errors.commandName}
                  </Form.Control.Feedback>
                )}
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Type</Form.Label>
                <Form.Select
                  size="md"
                  name="type"
                  value={values.type}
                  isInvalid={Boolean(touched.type && errors.type)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                >
                  <option value={'admin'}>Admin</option>
                  <option value={'customer'}>Customer</option>
                </Form.Select>
                {!!touched.type && (
                  <Form.Control.Feedback type="invalid">
                    {errors.type}
                  </Form.Control.Feedback>
                )}
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Command String</Form.Label>
                <Form.Control
                  size="md"
                  type="text"
                  name="commandString"
                  value={values.commandString}
                  isInvalid={Boolean(touched.commandString && errors.commandString)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {!!touched.commandString && (
                  <Form.Control.Feedback type="invalid">
                    {errors.commandString}
                  </Form.Control.Feedback>
                )}
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>ACK String</Form.Label>
                <Form.Control
                  size="md"
                  type="text"
                  name="ackString"
                  value={values.ackString}
                  isInvalid={Boolean(touched.ackString && errors.ackString)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {!!touched.ackString && (
                  <Form.Control.Feedback type="invalid">
                    {errors.ackString}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              {type === 'New' && <div className="mt-3">
                <Button
                  type="submit"
                  variant="primary"
                  size="md"
                  disabled={isSubmitting}
                >
                  Save
                </Button>
              </div>}
              {type === 'Edit' && <div className="mt-3">
                <Button
                  type="submit"
                  variant="primary"
                  size="md"
                  disabled={isSubmitting}
                >
                  Save
                </Button>
                <Button
                  variant="danger"
                  className="ms-1"
                  size="md"
                  onClick={() => {onDelete && onDelete()}}
                >
                  Delete
                </Button>
              </div>}
            </Form>
          )}
        </Formik>
      </Card.Body>
    </Card>
  );
}

export default NewCommand;