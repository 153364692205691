import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Form, ListGroup, Modal } from "react-bootstrap";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ChevronRight } from "react-feather";
import DateTime from "react-datetime";
import api from "../api";
import NotyfContext from "../contexts/NotyfContext";
import moment from "moment-timezone";
import useAuth from "../hooks/useAuth";


const AdditionalInfo = ({apiName, setAsset, data}) => {

  const [value, setValue] = useState('');
  const [label, setLabel] = useState('');
  const [newLabel, setNewLabel] = useState('');
  const [newValue, setNewValue] = useState('');
  const [showAddModal, setShow] = useState(false);
  const [current, setCurrent] = useState(null);
  const [totalInfo, setTotalInfo] = useState({});
  const [showDelete, setShowDelete] = useState(false);
  const [errorText, setErrorText] = useState(null);
  const [errorTextValue, setErrorTextValue] = useState(null);
  const [showDeleteText, setShowDeleteText] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const notify = useContext(NotyfContext);
  const { user } = useAuth()

  // all info types here
  const infoObj = [{
    name: "textInfo",
    label: "Text",
  }, {
    name: "dateInfo",
    label: "Date",
  }, {
    name: "quantityInfo",
    label: "Quantity",
  }, {
    name: "currencyInfo",
    label: "Currency",
  }, {
    name: "weblinkInfo",
    label: "Weblink",
  }, {
    name: "emailInfo",
    label: "Email",
  }, {
    name: "phoneInfo",
    label: "Phone",
  }];

  useEffect(() => {
    if(!showAddModal) {
      setShowDelete(false);
      setValue('');
      setLabel('');
      setNewValue('');
      setNewLabel('');
      setErrorTextValue(null);
      setErrorText(null);
      setShowDeleteText(false);
      setCurrent(null);
    }
  }, [showAddModal])

  useEffect(() => {
    if(data) {
      let obj = {}
      for (let key in data) {
        let c = infoObj.filter(item => item.name === key)
        if(c.length > 0 && data[key] !== null) {
          obj[key] = data[key]
        }
      }
      setTotalInfo(obj);
    }
  }, [data])

  const isURL = (str) => {
    let reg=/[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+\.?/
    return reg.test(str);
  }

  const isEmail = (str) => {
    return /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(str);
  }

  const buttonValid = () => {
    if(!showDelete) {
      return value.toString().length > 0 && label.length > 0
    } else {
      return newValue.toString().length > 0 && newLabel.length > 0
    }
  }

  const onSave = () => {
    setErrorText(null)
    setErrorTextValue(null)
    if(current?.name === 'weblinkInfo' && ((value && !isURL(value)) || newValue && !isURL(newValue))) {
      setErrorTextValue('Invalid weblink')
      return;
    }
    if(current?.name === 'emailInfo' && ((value && !isEmail(value)) || newValue && !isEmail(newValue))) {
      setErrorTextValue('Invalid email address')
      return;
    }
    let info;
    info = JSON.parse(JSON.stringify(totalInfo))
    if (!showDelete) {
      for(let key in info) {
        if (info[key].filter(item => (item.label === label)).length > 0) {
          setErrorText('Value exists')
          return;
        }
      }
      if(info[current?.name]) {
        info[current?.name].push({label: label,  value: value})
      } else {
        info[current?.name] = [{label: label, value: value}]
      }
    } else {
      for(let key in info) {
        if (info[key].filter(item => (item.label === newLabel && newLabel !== label)).length > 0) {
          setErrorText('Value exists')
          return;
        }
      }
      let filter = info[current?.name].filter(item => item.label === label)
      if(filter.length > 0) {
        filter[0].label = newLabel
        filter[0].value = newValue
      }
    }
    handleUpdate(info)
  }

  const handleUpdate =(info) => {
    let myData = JSON.parse(JSON.stringify(data));
    myData = {...myData, ...info}
    api.put(`${apiName || 'assets'}`, myData)
      .then(res => {
        notify.open({
          type: 'success',
          message: 'Changes Saved'
        })
        setTotalInfo({})
        setAsset(res.data);
      });
    setShow(false);
  }

  const handleDelete = () => {
    let info;
    info = JSON.parse(JSON.stringify(totalInfo))
    let deleteIndex = 0
    info[current?.name].forEach((item, index) => {
      if(item.label === label) deleteIndex = index;
    })
    info[current?.name].splice(deleteIndex, 1)
    handleUpdate(info)
  }

  const handleClick = (item, item1) => {
    if(infoObj.filter(me => me.name === item)[0].name === 'emailInfo') {
      window.location.href = `mailto:${item1.value}`;
    } else if (infoObj.filter(me => me.name === item)[0].name === 'weblinkInfo') {
      window.open('https://' + item1.value)
    }
    return
  }

  return <React.Fragment>
   <div className="d-flex justify-content-between align-items-center mb-2">
      <span>Additional Information</span>
     { ((user?.editPeople && apiName === 'people') || (apiName === 'zones' && user?.editZones) || (user?.editAssets && apiName === 'assets') || user?.id === data?.id) && <Button size="sm" variant="link" onClick={() => setShow(true)}><FontAwesomeIcon icon={faPlus} fixedWidth
                                                                                      className="me-1" />Create
        New</Button>}
    </div>
    <Card className="border-secondary border-1">
      <Card.Body className="pb-1">
        {Object.keys(totalInfo).slice(0, showMore ? Object.keys(totalInfo).length : 3).map((item, index) => <div key={item}>
          {totalInfo[item].map((item1, index1) => <div onClick={() => {
            if((user?.editPeople && apiName === 'people') || (user?.editAssets && apiName === 'assets') || (apiName === 'zones' && user?.editZones) || user?.id === data?.id) {
              setCurrent(infoObj.filter(me => me.name === item)[0]);
              setShowDelete(true);
              setShow(true);
              setValue(item1.value);
              setLabel(item1.label);
              setNewValue(item1.value);
              setNewLabel(item1.label);
            }
          }} className="additional-content d-flex align-items-center justify-content-between cursor-pointer mb-3" key={index1}>
            <div>
              <span className="text-dark">{item1.label}:</span>
              <span onClick={() => handleClick(item, item1)} className={`ms-2 ${(infoObj.filter(me => me.name === item)[0].name === 'emailInfo' || infoObj.filter(me => me.name === item)[0].name === 'weblinkInfo') ? 'text-decoration-underline ' : ''}`}>{item !== 'dateInfo' ? item1.value : moment(item1.value).format("DD/MM/YYYY HH:mm") }</span>
            </div>
            {((user?.editPeople && apiName === 'people') || (apiName === 'zones' && user?.editZones) || (user?.editAssets && apiName === 'assets') || user?.id === data?.id) && <span className="text-primary hover-show-edit">Edit</span>}
          </div>)}
        </div>)}
        {Object.keys(totalInfo).length > 3 && <div className="d-flex justify-content-center">
          <span className="text-secondary cursor-pointer" onClick={() => setShowMore(!showMore)} >{showMore ? 'Show less' : 'Show more'}</span>
        </div>}
      </Card.Body>
    </Card>
    <Modal show={showAddModal} onHide={() => {setShow(false)}}>
      <Modal.Header closeButton>
        <Modal.Title>{!showDeleteText ? 'Additional Information' : 'Please Confirm'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Card>
          {!showDeleteText ? <Card.Body>
            {current ? <div>
              <h4>{current.label}</h4>
              <div className="mb-2">Name </div>
              <>
                <Form.Control type="text" defaultValue={label} onChange={e => {!showDelete ? setLabel(e.target.value) : setNewLabel(e.target.value)}} />
                {errorText && <div className="mt-1 text-danger">{errorText}</div>}
                <div className="mb-2 mt-3">{current.label}</div>
                {(current.name === 'textInfo' || current.name === 'weblinkInfo' || current.name === 'phoneInfo') && <Form.Control type="text" defaultValue={value} onChange={e => {!showDelete ? setValue(e.target.value) : setNewValue(e.target.value)}} />}
                {current.name === 'dateInfo' && <DateTime className="no-change-date-picker" inputProps={{ readOnly: true}} initialValue={moment(value)} onChange={e => {!showDelete ? setValue(e ? e.format() : '' ) : setNewValue(e.format())}} timeFormat="HH:mm" dateFormat="DD/MM/YY" />}
                {(current.name === 'quantityInfo' || current.name === 'currencyInfo') && <Form.Control type="number" defaultValue={value} onChange={e => {!showDelete ? setValue(e.target.value) : setNewValue(e.target.value)}} />}
                {current.name === 'emailInfo' && <Form.Control type="email" defaultValue={value} onChange={e => {!showDelete ? setValue(e.target.value) : setNewValue(e.target.value)}} />}
                {errorTextValue && <div className="mt-1 text-danger">{errorTextValue}</div>}
              </>
            </div> : <ListGroup>
              {infoObj.map((item, index) => <ListGroup.Item action key={index} onClick={() => setCurrent(item)}>
                <div className="d-flex justify-content-between align-items-center cursor-pointer">
                  <span>{item.label}</span>
                  <ChevronRight />
                </div>
              </ListGroup.Item>)}
            </ListGroup>}
          </Card.Body> : <div>
            Are you sure you want to delete?
          </div>}
        </Card>
      </Modal.Body>
      <Modal.Footer>
        {current && !showDeleteText && <>
          <Button disabled={!buttonValid()} onClick={() => {onSave()}}>Save</Button>
          {showDelete && <Button variant="danger" onClick={() => {setShowDeleteText(true)}}>Delete</Button>}
        </>}
        {current && showDeleteText && <Button onClick={() => handleDelete()} variant="danger">Delete</Button>}
        <Button variant="secondary" onClick={() => {setShow(false)}}>Cancel</Button>
      </Modal.Footer>
    </Modal>
  </React.Fragment>;
};
export default AdditionalInfo;