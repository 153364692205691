import React from "react";

import { Dropdown, ListGroup } from "react-bootstrap";
import { NavLink } from "react-router-dom";

const NavbarDropdown = ({
  children,
  count,
  showBadge,
  header,
  footer,
  icon: Icon,
}) => (
  <Dropdown className="me-2 nav-item" align="end">
    <Dropdown.Toggle as="a" className="nav-link nav-icon dropdown-toggle">
      <div className="position-relative">
        <Icon className="align-middle" size={18} />
        {showBadge && count > 0 &&  count < 999 ? <span className="indicator text-dark">{count}</span> : null}
      </div>
    </Dropdown.Toggle>
    <Dropdown.Menu drop="end" className="dropdown-menu-lg py-0">
      <div className="dropdown-menu-header position-relative">
        {(count > 0 && count < 999) ? count : ''} {header}
      </div>
      <ListGroup>{children}</ListGroup>
      <Dropdown.Item as="span" className="dropdown-menu-footer no-effect-button">
        <NavLink to={'/alerthistory'} className="text-muted">{footer}</NavLink>
      </Dropdown.Item>
    </Dropdown.Menu>
  </Dropdown>
);

export default NavbarDropdown;
