import React from "react";
import { Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckSquare,
  faClock,
  faExchangeAlt,
  faGlobe,
  faHourglassHalf,
  faTachometerAlt
} from "@fortawesome/free-solid-svg-icons";
import { dateFromNow } from "../../utils/staticMethods";
import useSrlHelper from "../../hooks/useSrlHelper";

const SrlLastLocation = ({ profile, detail, markerLabel, isPosition, apiName }) => {

  const item = profile?.lastPosition;
  const { renderSupply, renderBattery } = useSrlHelper();

  let speed = item && item.speed !== null ?
    <li data-toggle="tooltip" title="" data-placement="right">
      <FontAwesomeIcon icon={faTachometerAlt} fixedWidth className="me-1" /> Movement: {`${Math.round((item.speed * 0.6214))} mph`}
    </li> :
    <></>;

  var trackerBattery = item && item.battery !== null ?
    <li data-toggle="tooltip" title="" data-placement="right">
      {renderBattery(item?.battery)}
    </li> :
    <></>

  return (
    <Card className="mb-0 border-top">
      <Card.Body className={detail ? 'p-0' : ''}>
        <ul className={`list-unstyled ${markerLabel && 'mb-2'}`}>
          {!markerLabel && <li data-toggle="tooltip" title="" data-placement="right">
            <h6>TRACKER LAST CONTACT</h6>
          </li>}
          {!isPosition && <li data-toggle="tooltip" title="" data-placement="right">
            <FontAwesomeIcon icon={faClock} fixedWidth className="me-1" /> Reported: {dateFromNow(item?.dateTime)}
          </li>}
          {!isPosition && !markerLabel && item?.lastFix.indexOf('0001-01-01') < 0 && <li data-toggle="tooltip" title="" data-placement="right">
            <FontAwesomeIcon icon={faGlobe} fixedWidth className="me-1" /> GPS Fix: {item?.type === 'manual' ? ' manual' : dateFromNow(item?.lastFix)}
          </li>}
          {!isPosition && markerLabel && item?.lastFix.indexOf('0001-01-01') < 0 && <li data-toggle="tooltip" title="" data-placement="right">
            <FontAwesomeIcon icon={faGlobe} fixedWidth className="me-1" /> GPS Fix: {(item?.positionType === 'manual' || item?.type === 'manual') ? ' manual' : dateFromNow(item?.lastFix)}
          </li>}
          {speed}
          {!markerLabel && <>{trackerBattery}</>}
          {markerLabel && <li><div className="d-flex align-items-center">{renderSupply(item?.supply, <>Asset Battery:&nbsp;</>)}</div></li>}
          {profile?.lastCommand && !markerLabel && <li>
            <FontAwesomeIcon icon={faExchangeAlt} fixedWidth className="me-1" /> {profile?.lastCommand?.commandName} &nbsp;
            {profile?.lastCommand?.status === "Pending" && <FontAwesomeIcon icon={faHourglassHalf} className="me-1" />}
            {profile?.lastCommand?.status === "Confirmed" && <FontAwesomeIcon icon={faCheckSquare} className="me-1" />}
          </li>}
        </ul>
      </Card.Body>
    </Card>
  );
};

export default SrlLastLocation;
