import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import GoogleMapReact from "google-map-react";
import { Button, Modal } from "react-bootstrap";
import useAuth from "../../hooks/useAuth";
import { Image } from "react-feather";
import api from "../../api";
import SelectionTable from "../../components/tables/SelectionTable";
import { NavLink } from "react-router-dom";
import { globalConfig } from "../../config";
import { getDistance, radiusZoomLevel } from "../../utils/staticMethods";

let selectShape = null;
let drawingManager = null;
let centerPoint = null;
let listener1 = null;

let overlay1;

let FloorPlanEdit = ({ zone, onUpdate, tab, onComplete, geoDeleted }, ref) => {

  const [mapInstance, setInstance] = useState();
  const [firstLoad, setFirstLoad] = useState(true);
  const [mapApi, setApi] = useState();
  const [curFloor, setCurFloor] = useState(null);
  const [showDelete, setShowDelete] = useState(false);
  const [picture, setPicture] = useState(null);
  const { user } = useAuth();

  const getMapOptions = (maps) => {

    return {
      mapId: "90f87356969d889c",
      fullscreenControl: true,
      mapTypeControl: false,
      mapTypeId: maps.MapTypeId.ROADMAP,
      scaleControl: true,
      gestureHandling: "cooperative",
      streetViewControl: false,
    };
  };

  useEffect(() => {
    if(tab !== 'floorplan') return
    if (mapApi && mapInstance && zone.latitude && zone.longitude && !zone.polygon) {
      initLocation()
    } else if (mapApi && mapInstance && zone.latitude && zone.longitude && zone.polygon && zone.polygon.length > 0) {
      initPolygon()
    }
    if (mapApi && mapInstance) {
      initDrawPolygon();
    }
    if (zone?.id) {
      getFloorPlan(1);
    }
  }, [mapApi, zone.latitude, zone.longitude, zone.radius, tab]);

  useEffect(() => {
    return () => {
      mapApi && mapApi.event.removeListener(listener1);
      listener1 = null;
      selectShape && selectShape.setMap(null)
      selectShape = null
      overlay1 && overlay1.setMap(null)
      overlay1 = null
      setFirstLoad(true)
    };
  }, []);

  useEffect(() => {
    if (curFloor) getPci()
  }, [curFloor]);

  useEffect(() => {
    if(picture) initFloorPlan();
  }, [picture])

  const getPci = () => {
    api.get(`files/floorplans/${curFloor.id}`, {
      responseType: "arraybuffer"
    }).then(res => {
      let blob = new Blob([res.data], { type: "img/jpeg" });
      let url = (window.URL || window.webkitURL).createObjectURL(blob);
      setPicture(url);
    }).catch(() => setPicture(null));
  };

  const getFloorPlan = (p) => {
    api.get(`floorplans?parentId=${zone.id}&page=${p}`).then(r => {
      const totalHeader = Number(r.headers["x-total-count"])
      if(totalHeader === 0) {
        setFirstLoad(false)
        selectShape && selectShape.setMap(mapInstance)
      }
      if (r.data.length > 0) {
        setCurFloor(r.data[0]);
      }
      onComplete && onComplete(r.data.length)
    });
  };

  const initPolygon = () => {
    if (selectShape && zone.latitude && zone.longitude) {
      selectShape.setMap(null);
      selectShape = null;
    }
    let firstItem = zone.polygon[0].split(",");
    let minLat = { lat: Number(firstItem[1]), lng: Number(firstItem[0]) },
      maxLat = { lat: Number(firstItem[1]), lng: Number(firstItem[0]) },
      minLng = { lat: Number(firstItem[1]), lng: Number(firstItem[0]) },
      maxLng = { lat: Number(firstItem[1]), lng: Number(firstItem[0]) };
    let arr = zone.polygon.map(item => {
      let me = item.split(",");
      let lng = Number(me[0]);
      let lat = Number(me[1]);
      if (lat > maxLat.lat) maxLat = { lat, lng };
      if (lat < minLat.lat) minLat = { lat, lng };
      if (lng > maxLng.lng) maxLng = { lat, lng };
      if (lng < minLng.lng) minLng = { lat, lng };
      return { lng, lat };
    });
    let point1 = new mapApi.LatLng(minLat);
    let point2 = new mapApi.LatLng(maxLat);
    let point3 = new mapApi.LatLng(minLng);
    let point4 = new mapApi.LatLng(maxLng);
    let d1 = getDistance(point1, point2);
    let d2 = getDistance(point3, point4);
    let d = d1 > d2 ? d1 : d2;

    selectShape = new mapApi.Polygon({
      paths: arr,
      strokeColor: "#000000",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#242424",
      fillOpacity: 0.3,
      editable: true,
      draggable: true,
      type: "polygon"
    });
    !firstLoad && selectShape.setMap(mapInstance);

    listener1 = selectShape.addListener("dragend", (e) => {
      addCenterPointForPolygon();
    });
    let keys = Object.keys(radiusZoomLevel).reverse();
    let value = 1000000;
    let index = 0;
    for (let i = 0; i < keys.length; i++) {
      let v = Math.abs(radiusZoomLevel[keys[i]] - d);
      if (v < value) {
        value = v;
        index = keys[i];
      }
    }

    addCenterPointForPolygon(Number(zone.latitude), Number(zone.longitude));
    mapInstance.setZoom(Number(index));
    mapInstance.panTo(new mapApi.LatLng(Number(zone.latitude), Number(zone.longitude)))
  };

  const initLocation = () => {
    if (selectShape && zone.latitude && zone.longitude && zone.radius) {
      selectShape.setMap(null);
      selectShape = null;
      mapInstance.panTo({ lat: Number(zone.latitude), lng: Number(zone.longitude) });
    } else if (selectShape && (!zone.latitude || !zone.longitude || !zone.radius)) {
      selectShape.setMap(null);
      selectShape = null;
      return;
    }
    selectShape = new mapApi.Circle({
      strokeColor: "#000000",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#242424",
      fillOpacity: 0.3,
      type: "circle",
      center: new mapApi.LatLng(Number(zone.latitude), Number(zone.longitude)),
      radius: Number(zone.radius),
      editable: true
    });
    !firstLoad && selectShape.setMap(mapInstance)
    mapInstance.panTo(new mapApi.LatLng(Number(zone.latitude), Number(zone.longitude)))

    let keys = Object.keys(radiusZoomLevel).reverse();
    let value = 1000000;
    let index = 0;
    for (let i = 0; i < keys.length; i++) {
      let v = Math.abs(radiusZoomLevel[keys[i]] - Number(zone.radius) * 2);
      if (v < value) {
        value = v;
        index = keys[i];
      }
    }
    mapInstance.setZoom(Number(index));
  };


  const initDrawPolygon = () => {
    if (drawingManager) {
      drawingManager.setDrawingMode(null);
      drawingManager.setMap(null);
      drawingManager = null;
    }
    let polyOptions = {
      strokeWeight: 0,
      fillOpacity: 0.45,
      editable: true,
      draggable: true
    };
    drawingManager = mapApi.drawing.DrawingManager && new mapApi.drawing.DrawingManager({
      drawingModes: [
        mapApi.drawing.OverlayType.CIRCLE,
        mapApi.drawing.OverlayType.POLYGON,
        mapApi.drawing.OverlayType.RECTANGLE
      ],
      drawingControlOptions: {
        position: mapApi.ControlPosition.TOP_CENTER,
        drawingModes: [
          mapApi.drawing.OverlayType.CIRCLE,
          mapApi.drawing.OverlayType.POLYGON
        ]
      },
      polylineOptions: {
        editable: true,
        draggable: true
      },
      circleOptions: polyOptions,
      polygonOptions: polyOptions,
      map: mapInstance
    });

    mapApi.event.addListener(drawingManager, "overlaycomplete", function(e) {
      drawingManager.setDrawingMode(null);
      centerPoint && centerPoint.setMap(null);
      centerPoint = null;
      if (e.type !== mapApi.drawing.OverlayType.MARKER) {
        // Switch back to non-drawing mode after drawing a shape.
        let newShape = e.overlay;
        newShape.type = e.type;
        selectShape && selectShape.setMap(null);
        selectShape = newShape;
        if (newShape.type === mapApi.drawing.OverlayType.POLYGON) {
          addCenterPointForPolygon();
          listener1 = selectShape.addListener("dragend", (e) => {
            addCenterPointForPolygon();
          });
        } else {
          mapApi.event.removeListener(listener1);
          listener1 = null;
        }
      }
    });
  };

  const addCenterPointForPolygon = (lat, lng) => {
    if (centerPoint) {
      centerPoint.setMap(null);
      centerPoint = null;
    }
    if (lat && lng) {
      centerPoint = new mapApi.Marker({
        draggable: true,
        position: { lat, lng },
        map: mapInstance,
        icon: {
          path: mapApi.SymbolPath.CIRCLE,
          fillColor: "#00F",
          fillOpacity: 0.6,
          strokeColor: "#00A",
          strokeOpacity: 0.9,
          strokeWeight: 1,
          scale: 5
        }
      });
    } else {
      let v = selectShape.getPath();
      let pathArray = [];
      let bounds = new mapApi.LatLngBounds();
      for (let i = 0; i < v.getLength(); i++) {
        let xy = v.getAt(i);
        let filter = pathArray.filter(item => {
          let spit = item.split(",");
          return spit[0] === xy.lng().toString() && spit[1] === xy.lat().toString();
        });
        if (filter.length === 0) {
          pathArray.push(xy.lng() + "," + xy.lat());
          bounds.extend(new mapApi.LatLng(xy.lat(), xy.lng()));
        }
      }
      let end = pathArray[0].split(",");
      bounds.extend(new mapApi.LatLng(end[1], end[0]));
      let latitude = bounds.getCenter().lat();
      let longitude = bounds.getCenter().lng();
      centerPoint = new mapApi.Marker({
        draggable: true,
        position: { lat: latitude, lng: longitude },
        map: mapInstance,
        icon: {
          path: mapApi.SymbolPath.CIRCLE,
          fillColor: "#00F",
          fillOpacity: 0.6,
          strokeColor: "#00A",
          strokeOpacity: 0.9,
          strokeWeight: 1,
          scale: 5
        }
      });
    }
  };

  const apiHasLoaded = (map, maps) => {
    if (!map || !maps) return;
    window.mapApi = maps;
    window.mapInstance = map;
    setInstance(map);
    setApi(maps);
  };

  const initFloorPlan = () => {
    if (mapApi && mapInstance && picture) {
      mapInstance.setHeading(360 - curFloor?.rotation || 0)
      if(overlay1) {
        overlay1.setMap(null)
        overlay1 = null
      }
      let northWest= new mapApi.LatLng(curFloor ? (Number(curFloor.northWestLatitude || 51.5072)) : 51.5072, curFloor ? (Number(curFloor.northWestLongitude) || 0.1276) : 0.1276)
      let southEast= new mapApi.LatLng(curFloor ? (Number(curFloor.southEastLatitude || 51.5072)) : 51.5072, curFloor ? (Number(curFloor.southEastLongitude) || 0.1276) : 0.1276)
      overlay1 = new mapApi.OverlayView()
      overlay1.div = null;
      window.overlay = overlay1
      overlay1.image = picture;
      overlay1.draw = function() {
        const overlayProjection = this.getProjection();
        const se = overlayProjection.fromLatLngToDivPixel(
          southEast
        );
        const nw = overlayProjection.fromLatLngToDivPixel(
          northWest
        );
        if (this.div) {
          this.div.style.left = nw.x + "px";
          this.div.style.top = nw.y + "px";
          this.div.style.width = se.x - nw.x + "px";
          this.div.style.height = se.y - nw.y + "px";
        }
      }
      overlay1.onRemove = function() {
        if (this.div) {
          this.div.parentNode.removeChild(this.div);
        }
      }
      overlay1.onAdd = function() {
        this.div = document.createElement("div");
        this.div.id='whole-container';
        this.div.style.borderStyle = "none";
        this.div.style.borderWidth = "0px";
        this.div.style.position = "absolute";
        this.div.style.visibility = "visible";

        const img = document.createElement("img");

        img.src = this.image;
        img.style.width = "100%";
        img.style.height = "100%";
        img.style.position = "absolute";
        this.div.appendChild(img);
        const panes = this.getPanes();
        panes.overlayLayer.appendChild(this.div);
      }
      overlay1.setMap(mapInstance);
      if(firstLoad && selectShape) {
        setFirstLoad(false)
        selectShape.setMap(mapInstance)
      }
    }
  }

  const onSave = () => {
    let obj = JSON.parse(JSON.stringify(zone));
    if (selectShape && selectShape.type !== "polygon") {
      obj.latitude = selectShape.center.lat().toString();
      obj.longitude = selectShape.center.lng().toString();
      obj.radius = selectShape.radius.toString();
      obj.polygon = null;
    } else if (selectShape && selectShape.type === "polygon") {
      let v = selectShape.getPath();
      let bounds = new mapApi.LatLngBounds();
      let pathArray = [];
      for (let i = 0; i < v.getLength(); i++) {
        let xy = v.getAt(i);
        let filter = pathArray.filter(item => {
          let spit = item.split(",");
          return spit[0] === xy.lng().toString() && spit[1] === xy.lat().toString();
        });
        if (filter.length === 0) {
          pathArray.push(xy.lng() + "," + xy.lat());
          bounds.extend(new mapApi.LatLng(xy.lat(), xy.lng()));
        }
      }
      let end = pathArray[0].split(",");
      bounds.extend(new mapApi.LatLng(end[1], end[0]));
      obj.latitude = centerPoint.getPosition().lat().toString();
      obj.longitude = centerPoint.getPosition().lng().toString();
      obj.polygon = pathArray;
      obj.radius = null;
    }
    onUpdate(obj);
  };

  useImperativeHandle(ref, () => ({
    deletedMap: () => {
      if(selectShape) {
        selectShape.setMap(null);
        selectShape = null;
      }
      if(centerPoint) {
        centerPoint.setMap(null);
        centerPoint = null;
      }
    },
  }));

  const onCancel = () => {
    if (mapApi && mapInstance && selectShape && zone.radius) {
      initLocation();
    } else if (mapApi && mapInstance && selectShape && zone.polygon) {
      initPolygon();
    } else if (mapApi && mapInstance && selectShape && !zone.radius && !zone.polygon) {
      selectShape.setMap(null);
      selectShape = null;
      if(centerPoint) {
        centerPoint.setMap(null);
        centerPoint = null;
      }
    }
  };

  const onDelete = () => {
    setShowDelete(true);
  };

  const handleDelete = () => {
    if (selectShape) {
      selectShape.setMap(null);
      selectShape = null;
    }
    if (centerPoint) {
      centerPoint.setMap(null);
      centerPoint = null;
    }
    let obj = JSON.parse(JSON.stringify(zone));
    obj.latitude = null;
    obj.longitude = null;
    obj.radius = null;
    onUpdate(obj);
    setShowDelete(false);
    geoDeleted()
  };

  const columns = [
    {
      Header: "Name",
      accessor: "floorPlanName",
      Cell: cell => (
        <NavLink to={{pathname: `/floorplanview`, search:`?fId=${cell.row.original.id}`}}>{cell.value || "no name"}</NavLink>
      )
    },
    {
      Header: "",
      accessor: "id",
      Cell: cell => (
        curFloor?.id === cell.row.original.id ? <span>Viewing</span> : <Button onClick={() => handleView(cell.row.original)}>View</Button>
      )
    },
    {
      Header: " ",
      accessor: "",
      disableSortBy: true
    },
  ];

  const handleView = (c) => {
    setCurFloor(c)
  }

  const onReturnResult = (total, page, list) => {
    if(total > 0 && page === 1 && list.length > 0) {
      setCurFloor(list[0])
    } else if (total === 0) {
      setCurFloor(null);
      setPicture(null);
      overlay1 && overlay1.setMap(null)
      overlay1 = null
    }
  }

  return (
    <React.Fragment>
      <h4><Image /> Floorplan</h4>
      {user?.editZones && <>
        <div className="mt-2 mb-2 d-flex align-items-center">
          <span>Click on the map to add or move this geofence.</span>
          <Button onClick={() => onSave()} className="ms-1">Save</Button>
          <Button onClick={() => onCancel()} className="ms-2" variant="secondary">Cancel</Button>
          <Button onClick={() => onDelete()} className="ms-2" variant="danger">Delete</Button>
        </div>
      </>}
      <div className={`hidden-map floorplan-zone`} style={{ height: "500px", width: "100%" }}>
        <GoogleMapReact
          options={getMapOptions}
          id={'map-google'}
          bootstrapURLKeys={{
            key: globalConfig.googleMapKey,
            libraries: ["places", "geometry", "drawing", "visualization"]
          }}
          center={{
            lat: Number(zone.latitude) || 50,
            lng: Number(zone.longitude) || 10
          }}
          zoom={zone.latitude ? 15 : 5}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => apiHasLoaded(map, maps)}
        >
        </GoogleMapReact>
      </div>
      {zone.id && <div className="mt-2">
        <SelectionTable onReturnResult={onReturnResult} errorMessage={'No associated floorplans'} type={'floorplans'} buttonText={'Link Floorplan'} filterId={'parentId'} id={zone.id} entry={`floorplans`} apiName={'floorplans'} columns={columns}/>
      </div>}
      <Modal show={showDelete} onHide={() => setShowDelete(false)}>
        <Modal.Header closeButton><Modal.Title>Please Confirm</Modal.Title></Modal.Header>
        <Modal.Body>
          <p>Are you sure that you wish to permanently delete this Geofence?</p>
          <p>Deletions are not reversible.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => handleDelete()}>Delete</Button>
          <Button variant="secondary" onClick={() => setShowDelete(false)}>Cancel</Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};
export default FloorPlanEdit = forwardRef(FloorPlanEdit);