import React from "react";
import { Form } from "react-bootstrap";


const numPerPage = [
  {
    value: "10"
  },
  {
    value: "25"
  },
  {
    value: "50"
  },
  {
    value: "100"
  }
]
const SelectHeader = ({onChange, limit}) => {
  return (
    <Form.Select className="page-select" onChange={onChange} value={limit}>
      {numPerPage.map(item => (
        <option key={item.value} value={item.value}>{item.value}</option>
      ))}
    </Form.Select>
  )
}
export default SelectHeader