import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import PoweredBy from "../assets/img/poweredbytraclogik.png"
import useTheme from "../hooks/useTheme";

const Footer = () => {
  const { theme } = useTheme();

  return (
    <footer className="footer">
      <Container fluid>
        <Row className="text-muted">
          <Col xs="6" className="text-start">
            <ul className="list-inline">
              <li className="list-inline-item">
                <a target="_blank" rel="noreferrer" className="text-muted" href="https://www.traclogik.co.uk/Home/Contact">
                  Support
                </a>
              </li>
              <li className="list-inline-item">
                <a target="_blank" rel="noreferrer" className="text-muted" href="https://www.traclogik.co.uk/Home/Privacy">
                  Privacy
                </a>
              </li>
            </ul>
          </Col>
          <Col xs="6" className="text-end">
            {theme === "srl" ?
              <a target="_blank" rel="noreferrer" className="text-muted" href="https://www.traclogik.co.uk">
                <img src={PoweredBy} style={{ height: "30px" }} alt="powered by"></img>
              </a>
              :
              <p className="mb-0">
                &copy; {new Date().getFullYear()} -{" "}
                <span href="/" className="text-muted">
                  Telemetry Systems Ltd.
                </span>
              </p>
            }
          </Col>
        </Row>
      </Container>
    </footer>
  )
};

export default Footer;
