import { Card, Col, Container, Row } from "react-bootstrap"
import useTheme from "../../hooks/useTheme";
import loginBg from "../../assets/img/loginbg.mov";
import srlLogo from "../../assets/img/SRL-Telematics.png";

const AuthWrapper = ({ children, title, subTitle }) => {
    const { theme } = useTheme();

    return (<>
        <Row>
            <Col sm={{ span: 8, offset: 2 }}>
                <Container className={`d-flex flex-column p-3 ${theme === "srl" ? "wrapper-srl" : ""}`}>
                    
                    {theme === "srl" && (
                        <Row>
                            <Col sm={{ span: 8, offset: 2 }}>
                                <div>&nbsp;</div>
                                <img className="w-100" src={srlLogo} alt=""></img>
                            </Col>
                        </Row>
                    )}

                    {theme !== "srl" && (<video className="position-fixed min-vw-100 min-vh-100 video-background" autoPlay muted loop id="myVideo">
                        <source src={loginBg} type="video/mp4" />
                    </video>)}
                    <div className="text-center mt-4">
                        <h2 className="h2 white-text">{title}</h2>
                        <p className="lead white-text">{subTitle}</p>
                    </div>
                    <Card className="p-3">
                        {children}
                    </Card>
                </Container>
            </Col></Row>
    </>);
};

export default AuthWrapper;
