import React from "react";
import useAuth from "../hooks/useAuth";
import { Col, Container, Row } from "react-bootstrap";

const Content = ({ children }) => {
  const { user } = useAuth();
  return (
    <>
      <Container fluid className={`content position-relative ${user?.role === 'Root' ? 'pt-5' : ' mx-0 p-1'}`}>
        <Row className="p-0 m-0 p-md-2 m-md-0">
          <Col className="p-0 m-0">
            {children}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Content;
