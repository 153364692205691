import React, { useContext, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Button, Card, Container, Modal } from "react-bootstrap";
import CommonTable from "../../components/tables/CommonTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import useAuth from "../../hooks/useAuth";
import NewCustomer from "./NewCustomer";
import api from "../../api";
import NotyfContext from "../../contexts/NotyfContext";
import { setOtherSession, setSession } from "../../utils/jwt";
import { Users } from "react-feather";

const columns = [
  {
    Header: "",
    accessor: "icon",
    type: "action",
    returnParam: "id",
    action: [{name: 'Login', color: 'primary'}],
    customWidth: 100,
    disableSortBy: true
  },
  {
    Header: "Name (Ref)",
    accessor: "name",
    type: "link",
    link: "/customers/edit",
    stateId: "id"
  }
];

const Customers = () => {
  const [resultCount, setResultCount] = useState(0);
  const [showModal, setShow] = useState(false);
  const childRef = useRef();
  const { getProfile } = useAuth();
  const notify = useContext(NotyfContext);

  const onOperate = (name, id) => {
    api.post(`accounts/customerlogin`, {customerId : id}).then(res => {
      let token = res.data?.accessToken;
      localStorage.removeItem('recentItem')
      let token1 = localStorage.getItem('accessToken')
      setOtherSession(token1);
      setSession(token);
      getProfile()
    }).catch(err =>  notify.open({
      type: "error",
      message: "This customer has no administrators"
    }))
  }

  return (
    <React.Fragment>
      <Helmet defer={false}  title="Customers" />
      <Container fluid className="p-0">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h1 className="h3 mb-0"><Users className={'me-2'} />Customers {typeof(resultCount) === 'string' && `(${resultCount})`}</h1>
          <Button className="text-nowrap" onClick={() => setShow(true)}><FontAwesomeIcon icon={faPlus} fixedWidth className="me-1" />Create New</Button>
        </div>
        <Card className="bg-white p-3">
          <CommonTable ref={childRef} btnClick={onOperate} setCountParent={setResultCount} apiName="customers" columns={columns} />
        </Card>
      </Container>
      <Modal show={showModal} size={'xl'} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>New Customer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <NewCustomer apiName="customers" />
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};
export default Customers;