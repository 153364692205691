import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Info } from "react-feather";

const InfoBox = ({
    title,
    children
}) => {
    const [show, setShow] = useState(false);
    return (<>
        <Info onClick={() => { setShow(true); }} className="text-primary cursor-pointer" />
        <Modal show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title><Info />&nbsp;{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {children}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShow(false)}>Close</Button>
            </Modal.Footer>
        </Modal>
    </>);
};

export default InfoBox;
