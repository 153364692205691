import React, { useEffect, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import CommonTable from "../tables/CommonTable";
import {
  faListAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useSrlHelper from "../../hooks/useSrlHelper";

const NearByAssets = ({ result }) => {
  const [showModal, setShow] = useState(false);
  const [assets, setAssets] = useState([]);
  const assetsCurrent = useRef(assets);
  const { getAssetColumnsForNearByAssets } = useSrlHelper();
  const [tableColumns] = useState(getAssetColumnsForNearByAssets(setShow));

  const getNearByAssets = () => {

    let arr = result.filter(item => (item.type === "asset"));

    // Test to see if the incoming array is the same as the current array
    let currentStr = assetsCurrent.current.map((v) => { return v.id }).join('');
    let arrStr = arr.map((v) => { return v.id }).join('');
    if (currentStr === arrStr) return;

    //if (assetsCurrent.current.join() === arr.join()) return;
    setAssets(arr);
    assetsCurrent.current = arr;
  };

  useEffect(() => {
    getNearByAssets();
  }, [result]);

  return <React.Fragment>
    <div onClick={() => setShow(true)} className={`cursor-pointer z-50 m-1`}>
      <FontAwesomeIcon title={"Nearby Assets"} icon={faListAlt}
        size={"lg"} className="bg-black text-white mx-1"
        fixedWidth />
    </div>
    <Modal show={showModal} onHide={() => setShow(false)} size={'lg'}>
      <Modal.Header closeButton>
        <Modal.Title>Visible Assets</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CommonTable data={assets} columns={tableColumns} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant={"secondary"} onClick={() => setShow(false)}>Close</Button>
      </Modal.Footer>
    </Modal>
  </React.Fragment>;
};

export default NearByAssets;