import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "react-router-dom";
import { faCalendarCheck } from "@fortawesome/free-solid-svg-icons";
import api from "../../api";
import moment from "moment-timezone";
import CommonTable from "../../components/tables/CommonTable";
import { dateFromNow, downloadFile } from "../../utils/staticMethods";
import ExportButton from "../../components/ExportButton";
import useSrlHelper from "../../hooks/useSrlHelper";

const ZoneOverviewTab = ({ lastPosition, id, compoundName, icon, trackers }) => {

  const [count, setCountParent] = useState(0);
  const [filterResult, setFilter] = useState('');

  const [columns, setColumns] = useState([]);

  const { childItemDisplayName }  = useSrlHelper();

  useEffect(() => {
    var columns = [
      {
        Header: "Name",
        accessor: "childName",
        disableSortBy: true,
        Cell: cell => (
          <div className="d-flex align-items-center">
            <NavLink to={{
              pathname: `/${cell.row.original.childType === "person" ? "people" : `${cell.row.original.childType}s`}/edit`,
              search: `?id=${cell.row.original.childId}`
            }}>
              {childItemDisplayName(cell.row.original)}
            </NavLink>
            {
              cell.row.original.childHashTags &&
              cell.row.original.childHashTags.map(item =>
                <div key={item} className="ms-1 badge bg-primary me-1">{item}</div>
              )
            }
          </div>
        )
      },
      {
        Header: "Arrived",
        accessor: "id",
        Cell: cell => (
          <span>{dateFromNow(cell?.row?.original?.start)}</span>
        ),
        disableSortBy: true
      },
      {
        Header: "Time",
        accessor: "start",
        Cell: cell => (
          <span>{moment(cell?.value).format(("DD/MM/YY HH:mm"))}</span>
        )
      }
    ];

    setColumns(columns);
  }, []);

  const exportFile = () => {
    api.get(`zones/livecontents/${id}/export?filter=${filterResult}`, { responseType: "blob" }).then(res => downloadFile(res));
  }

  return (
    <React.Fragment>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h4>
          <FontAwesomeIcon icon={faCalendarCheck} fixedWidth className="fa-fw" />
          <span className="ms-1">Current Contents {typeof (count) === 'string' && `(${count})`}</span>
        </h4>
        {compoundName && <div>
          <ExportButton title={"Export"} count={count} onClick={() => exportFile()} />
          <NavLink to={{ pathname: "/report/attendance", search: `?id=${id}&name=${encodeURIComponent(compoundName)}&type=zone` }}><Button>View History</Button></NavLink>
        </div>}
      </div>
      <CommonTable filterResult={e => setFilter(e)} autoRefresh hideAuto bordered setCountParent={setCountParent} apiName={`zones/livecontents/${id}`} columns={columns} />
    </React.Fragment>
  );
};
export default ZoneOverviewTab;