import React, { useContext, useEffect, useRef, useState } from "react";
import api from "../../api";
import { Button, Modal } from "react-bootstrap";
import NotyfContext from "../../contexts/NotyfContext";
import CommonTable from "../tables/CommonTable";

const DeviceLinker = ({type, data, device, onHide }) => {
    const [tracker, setTracker] = useState([]);
    const [showModal, setShow] = useState(false);
    const [loading, setLoading] = useState(false)
    const notify = useContext(NotyfContext);
    const [showWarning, setShowWarning] = useState(false);
    const isUnmount = useRef(false)

    const columns = [
      {
        Header: "",
        accessor: "id",
        minWidth: 60,
        disableSortBy: true,
        Cell: cell => (
          <Button
            variant={cell.row.original.assetId || cell.row.original.personId || cell.row.original.zoneId ? "danger" : "success"}
            onClick={() => onOperate(cell.row.original.assetId || cell.row.original.personId || cell.row.original.zoneId ? "warn" : "edit", cell.value, cell.row.original)}>Link</Button>
        )
      },
      {
        Header: "#Serial",
        accessor: "serial"
      },
      {
        Header: "Model",
        accessor: "model"
      },
      {
        Header: "Last Seen",
        accessor: "lastReport",
        type: "dateFromNow"
      },
      {
        Header: "Linked",
        accessor: "elementId",
        type: "linkStatus",
        disableSortBy: true
      }
    ];

    useEffect(() => {
      if(!data?.id) {
        setShow(false)
        setTracker(null);
        return
      }
      getTracker();
      setShow(true)
    }, [data]);

    useEffect(() => {
      return () => {isUnmount.current = true}
    }, [])

    const getTracker = () => {
      api.get(`${type}/${device}/${data.id}`)
        .then(res => {
          if(isUnmount.current) return
          setTracker(res.data);
        }).catch(() => {
        if(isUnmount.current) return
        setTracker(null);
      });
    };

    const onOperate = (name, chooseId, rawData) => {
        if (name === "edit") {
          if(tracker) {
            api.post(`${device}/${tracker.id}/unlink/${tracker.assetId || tracker.personId || tracker.zoneId}`).then(() => {
              api.post(`${device}/${chooseId}/link/${data.id}`, { params: { parentId: data.id, id: chooseId } })
                .then(res => {
                  notify.open({
                    type: "success",
                    message: "Changes Saved"
                  });
                  onHide();
                }).catch(e => {
                notify.open({
                  type: "error",
                  message: e
                });
              });
            })
          } else {
            api.post(`${device}/${chooseId}/link/${data.id}`, { params: { parentId: data.id, id: chooseId } })
              .then(res => {
                notify.open({
                  type: "success",
                  message: "Changes Saved"
                });
                onHide();
              }).catch(e => {
              notify.open({
                type: "error",
                message: e
              });
            });
          }
        } else if (name === "warn") {
          setShowWarning(rawData);
        } else if (name === "relink") {
          setLoading(true)
          if(tracker) {
            api.post(`${device}/${tracker.id}/unlink/${tracker.assetId || tracker.personId || tracker.zoneId}`).then(() => {
              api.post(`${device}/${chooseId?.id}/unlink/${chooseId?.assetId || chooseId?.personId || chooseId?.zoneId}`)
                .then(res => {
                  api.post(`${device}/${chooseId?.id}/link/${data.id}`, { params: { parentId: data.id, id: chooseId?.id } })
                    .then(res => {
                      notify.open({
                        type: "success",
                        message: "Changes Saved"
                      });
                      setLoading(false)
                      onHide();
                      setShowWarning(null);
                    });
                }).catch(e => {
                setLoading(false)
                notify.open({
                  type: "error",
                  message: e
                });
              });
            })
          } else {
            api.post(`${device}/${chooseId?.id}/unlink/${chooseId?.assetId || chooseId?.personId || chooseId?.zoneId}`)
              .then(res => {
                api.post(`${device}/${chooseId?.id}/link/${data.id}`, { params: { parentId: data.id, id: chooseId?.id } })
                  .then(res => {
                    notify.open({
                      type: "success",
                      message: "Changes Saved"
                    });
                    setLoading(false)
                    setShowWarning(null)
                    onHide();
                  });
              }).catch(e => {
              setLoading(false)
              notify.open({
                type: "error",
                message: e
              });
            });
          }
        }
      setShow(false);
    };

    return <React.Fragment>
      <Modal show={showModal} size={"lg"} onHide={() => {onHide()}}>
        <Modal.Header closeButton>
          <Modal.Title>Link a Device</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {data &&
          <h1 className="h3 mb-3">{`${data.compoundName} ${data.reference ? `(${data.reference})` : ""}`}</h1>}
          {tracker && <div className="mb-3">
            <div className="mt-2">Serial: {tracker.serial}</div>
            <div>Model: {tracker.model}</div>
          </div>}
          <CommonTable btnClick={onOperate} apiName={device} columns={columns} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => onHide()}>Cancel</Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showWarning} onHide={() => {setShowWarning(null); onHide()}}>
        <Modal.Header closeButton>
          <Modal.Title>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure that you wish to link this device to {data?.compoundName}? This will unlink it
            from {showWarning?.assetCompoundName || showWarning?.personCompoundName || showWarning?.zoneCompoundName}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={loading} onClick={() => onOperate("relink", showWarning)}>{loading ? 'Linking..' : 'Link'}</Button>
          <Button variant="secondary" onClick={() => {setShowWarning(null); onHide()}}>Cancel</Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>;
  }
;
export default DeviceLinker;