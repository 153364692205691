import React from "react";

import { Card, Col, OverlayTrigger, ProgressBar, Tooltip } from "react-bootstrap";
import CommonTable from "../tables/CommonTable";
import { NavLink, useNavigate } from "react-router-dom";
import { Maximize } from "react-feather";

const CratesByCustomerWithAllocation = ({ width, name }) => {
  const navigate = useNavigate();

  const getType = (type) => {
    if (type === 'asset') {
      return '/assets/edit'
    } else if (type === 'person') {
      return '/people/edit'
    } else {
      return '/zones/edit'
    }
  }

  const getThreshold = (thresholds, type) => {
    return thresholds?.find(
      (currentValue, index, arr) => {
        return currentValue.hashtag === "crate" && currentValue.type === type
      }
    )?.value ?? "-"
  }

  const getPercentage = (thresholds, type) => {
    return thresholds?.find(
      (currentValue, index, arr) => {
        return currentValue.hashtag === "crate" && currentValue.type === type
      }
    )?.percentage ?? "0"
  }

  const columns = [
    {
      Header: "",
      accessor: "icon",
      type: "icon",
      headerStyle: {
        width: '40px'
      },
      disableSortBy: true
    },
    {
      Header: "Customer",
      accessor: "compoundName",
      disableSortBy: true,
      width: '200px',
      Cell: cell => (
        <NavLink to={{ pathname: getType(cell.row.original.type), search: `?id=${cell.row.original.id}` }}>{`${cell.value} ${cell.row.original.reference ? `(${cell.row.original.reference})` : ""}`}</NavLink>
      )
    },
    {
      Header: "Crates",
      accessor: "contentsCount",
      disableSortBy: true
    }
    ,
    {
      Header: "Max Allocation",
      accesor: "",
      disableSortBy: true,
      Cell: cell => (<div>{getThreshold(cell.row.original.thresholds, "allocationmax")}</div>)
    },
    {
      Header: "% Max Allocation",
      accessor: "",
      disableSortBy: true,
      className: 'w-35',
      Cell: cell => (
        <ProgressBar
          variant={`${getPercentage(cell.row.original.thresholds, 'allocationmax') > 100 ? 'danger' : 'primary'}`}
          now={getPercentage(cell.row.original.thresholds, "allocationmax")}
          label={`${getPercentage(cell.row.original.thresholds, "allocationmax")}%`}
        />)
    }
  ];


  return (
    <React.Fragment>
      <Col key={name} lg={(width / 100 * 12).toFixed(0)} className="mb-4">
        <Card className="flex-fill w-100 h-100" style={{ minHeight: "420px" }}>
          <div className="m-3 d-flex justify-content-between align-items-center">
            <h4 style={{ color: "#E0E0E0" }}>Crates By Customer with Max Allocation</h4>
            <div>
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="button-tooltip-1">Expand</Tooltip>}
              >
                <Maximize size={18} className="cursor-pointer" onClick={() => navigate(`/cratesByCustomerWithAllocation`)} />
              </OverlayTrigger>
            </div>
          </div>
          <CommonTable singlePage showRange={false} secFilterId={'childHashtag'} secId={'crate'} filterId={'parentHashtag'} id={'customer'} type="dashboard" apiName={`reports/distributionwiththresholds`} columns={columns} emptyMessage="No crates are at customer sites" />
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default CratesByCustomerWithAllocation;
