import React, { useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { imageSet } from "../pages/icons/AssetIcon/IconList";
import { getUrl } from "../utils/staticMethods";
import defaultImg from "../assets/img/icon/GPS/placeholder.png";
import api from "../api";

let timer = null
const SignalMonitoring = ({ detail, tracker, asset }) => {

  const [loading, setLoading] = useState(false);
  const [curTracker, setCurTracker] = useState(tracker);
  const [supplyLevel, setSupplyLevel] = useState(0); // 0=Waiting, 1=Low, 2=Medium, 3=High
  const [supplyLevels] = useState([
    {
      "value": "Waiting for data",
      "className": "srl-carbon",
      "colour": "#272726"
    },
    {
      "value": "Disconnected",
      "className": "srl-carbon",
      "colour": "#272726"
    },
    {
      "value": "Connected",
      "className": "srl-carbon",
      "colour": "#272726"
    },
    {
      "value": "Low",
      "className": "srl-red",
      "colour": "#e30513"
    },
    {
      "value": "Medium",
      "className": "srl-canary",
      "colour": "#ffed00"
    },
    {
      "value": "High",
      "className": "srl-green",
      "colour": "#2ea836"
    },
  ]);

  const sendCommand = () => {
    api.post(`trackers/${tracker.id}/signalmonitoring/activate`).then(r => {
      setLoading(true)
      timer = setInterval(() => {
        getTracker()
      }, 5000)
    })
  }

  useEffect(() => {
    return () => clearInterval(timer)
  }, [])

  const getTracker = () => {
    api.get(`assets/trackers/${asset.id}`)
      .then(res => {
        if (res.data.signalMonitoringEnabled) clearInterval(timer)
        setCurTracker(res.data);
      }).catch(() => {
        setCurTracker({});
      });
  };

  useEffect(() => {
    var level = 0;  // Waiting for data

    if (asset) {
      let supply = asset.lastPosition?.supply;

      if (typeof (supply) === "number") {
        if (supply === 0 || supply === 500) {
          level = 1; // Disconnected
        } else if (supply === 1) {
          level = 2; // Connected
        } else if (supply > 0 && supply <= 11500) {
          level = 3; // Low
        } else if (supply > 11500 && supply <= 12250) {
          level = 4; // Medium
        } else if (supply > 12250) {
          level = 5; // High
        }
      }
    }

    setSupplyLevel(level);
  }, [asset]);

  return <React.Fragment>
    <Card className="mb-0">
      <Card.Body className={`${detail ? 'p-0' : ''} border-top`}>
        <ul className="list-unstyled col-sm-12">
          <li data-toggle="tooltip" title="" data-placement="right">
            <h6>LIVE ASSET STATUS</h6>
          </li>
          <li className="d-flex justify-content-around align-items-center">
            {curTracker?.showSignalMonitoring && !curTracker.signalMonitoringEnabled && <div className="d-flex flex-column">
              <img alt="profile" style={{ width: '80px' }} src={imageSet[getUrl(curTracker.assetIcon)] ? require("../assets/img/icon/" + imageSet[getUrl('trafficlight.png')]).default : defaultImg} />
              {loading ? <Button className="mt-3" disabled variant="secondary">Waiting...</Button> : <Button onClick={() => sendCommand()} className="mt-3" variant={'success'}>Activate</Button>}
            </div>}
            {
              curTracker?.signalMonitoringEnabled &&
              <div className="d-flex flex-column align-items-center align-content-start flex-wrap mb-1 px-3">
                <img alt="profile" style={{ width: '80px' }} src={imageSet[getUrl(asset.icon)] ? require("../assets/img/icon/" + imageSet[getUrl(asset.icon)]).default : defaultImg}  />
                {asset.icon?.indexOf("red") >= 0 && <span className="srl-red mt-2">RED SIGNAL</span>}
                {asset.icon?.indexOf("green") >= 0 && <span className="srl-green mt-2">GREEN SIGNAL</span>}
                {asset.icon?.indexOf("none") >= 0 && <span className="srl-carbon mt-2">SIGNAL OFF</span>}
              </div>
            }
            <div className="d-flex flex-column align-items-center align-content-start flex-wrap mb-1 px-3">
              <div className={`${supplyLevels[supplyLevel].className}`}>
                <svg width={100} height={60} fill={`${supplyLevels[supplyLevel].colour}`}>
                  <rect width="90" height="40" x="0" y="10" rx="6" ry="6" />
                  <rect width="10" height="20" x="86" y="20" rx="6" ry="6" />
                  <svg x="5" y="15" width="80" height={30} >
                    <rect x="0" y="0" width="80" height="30" rx="3" ry="3" style={{ opacity: 0.7 }} fill="white" />
                    {
                      (supplyLevel >= 3) &&
                      <text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle" fontSize={14} fontWeight={500} fill="#272726" >{asset.lastPosition?.supply} mV</text>
                    }
                  </svg>
                </svg>
              </div>

              <div className="d-flex flex-column align-items-center">
                <div><span className="srl-carbon">ASSET BATTERY:</span></div>
                <div><b className="srl-carbon">{`${supplyLevels[supplyLevel].value}`}</b></div>
              </div>
            </div>
          </li>
        </ul>
      </Card.Body>
    </Card>
  </React.Fragment>
}
export default SignalMonitoring