import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Button, Card, Container } from "react-bootstrap";
import CommonTable from "../../components/tables/CommonTable";
import { NavLink, useLocation } from "react-router-dom";
import * as queryString from "query-string";
import DeviceLinker from "../../components/deviceInfo/DeviceLinker";
import useAuth from "../../hooks/useAuth";
import useSrlHelper from "../../hooks/useSrlHelper";

const DeviceLinkerPage = () => {

  const [resultCount, setResultCount] = useState(0);
  const [obj, setObj] = useState(null);
  const location = useLocation();
  const { type, linker } = queryString.parse(location.search);
  const { user } = useAuth();
  const { isSrl } = useSrlHelper();

  const getTitle = () => {
    if (type === 'zones') {
      return 'Zones'
    } else if (type === 'people') {
      return 'People'
    } else {
      return 'Assets'
    }
  }

  const getDeviceName = () => {
    if (linker === 'tags') {
      return 'Tag'
    } else if (linker === 'readers') {
      return 'Reader'
    } else {
      return 'Tracker'
    }
  }

  const getDevice = () => {
    if (linker === 'tags') {
      return 'editTags'
    } else if (linker === 'readers') {
      return 'editReaders'
    } else {
      return 'editTrackers'
    }
  }

  const handleClick = (v) => {
    setObj(v)
  }

  const [columns, setColumns] = useState([]);

  useEffect(() => {
    var elementColumns = [];

    if (isSrl()) {
      elementColumns.push(
        {
          Header: "",
          accessor: "id",
          Cell: cell => ((type === 'assets' || type === 'people') && <div>
            <Button disabled={user && !user[getDevice()]} onClick={() => handleClick(cell.row.original)} variant={'success'}>Link a {getDeviceName()}</Button>
          </div>),
          disableSortBy: true
        }
      );

      if (type === "assets") {
        elementColumns.push({
          Header: "Asset Owner",
          accessor: "suppString1",
          visibilityName: "asset-owner",
          type: "link",
          link: "/assets/edit",
          stateId: "id",
        });

        elementColumns.push({
          Header: "SRL Fleet #",
          accessor: "fleetNumber",
          visibilityName: "srl-fleet-number",
          type: "link",
          link: "/assets/edit",
          stateId: "id",
        });

        elementColumns.push({
          Header: "Customer Fleet #",
          accessor: "suppString2",
          visibilityName: "customer-fleet-number",
          type: "link",
          link: "/assets/edit",
          stateId: "id",
        });

        elementColumns.push({
          Header: "SRL Product Name",
          accessor: "category",
          visibilityName: "srl-product-name",
          type: "link",
          link: "/assets/edit",
          stateId: "id",
        });

        elementColumns.push({
          Header: "Customer Reference",
          accessor: "primaryName",
          visibilityName: "cust-ref",
          type: "link",
          link: "/assets/edit",
          stateId: "id",
        });

      }
      else {
        elementColumns = elementColumns.concat(
          [
            {
              Header: "",
              accessor: "icon",
              type: "icon",
              disableSortBy: true
            },
            {
              Header: "Name (Ref)",
              accessor: "compoundName",
              type: "link",
              link: `/${type}/edit`,
              stateId: "id"
            },
            {
              Header: `${type === "zones" ? "Contents" : "Last Seen"}`,
              accessor: `${type === "zones" ? "contentsCount" : "lastSeen"}`,
              type: `${type === "zones" ? "" : "dateFromNow"}`
            },
            {
              Header: "Hashtags",
              accessor: "hashTags",
              Cell: cell => (<div>
                {cell.value && cell.value.map((tag, i) => <NavLink key={i} to={{ pathname: '/globalSearch', search: `?searchString=${encodeURIComponent('#' + tag)}` }}><span className='badge bg-primary me-1 my-1 cursor-pointer'>{tag}</span></NavLink>)}
              </div>),
              disableSortBy: true
            }
          ]
        );
      }

    } else {
      elementColumns.concat(
        [
          {
            Header: "",
            accessor: "id",
            Cell: cell => ((type === 'assets' || type === 'people') && <div>
              <Button disabled={user && !user[getDevice()]} onClick={() => handleClick(cell.row.original)} variant={'success'}>Link a {getDeviceName()}</Button>
            </div>),
            disableSortBy: true
          },
          {
            Header: "",
            accessor: "icon",
            type: "icon",
            disableSortBy: true
          },
          {
            Header: "Name (Ref)",
            accessor: "compoundName",
            type: "link",
            link: `/${type}/edit`,
            stateId: "id"
          },
          {
            Header: `${type === "zones" ? "Contents" : "Last Seen"}`,
            accessor: `${type === "zones" ? "contentsCount" : "lastSeen"}`,
            type: `${type === "zones" ? "" : "dateFromNow"}`
          },
          {
            Header: "Hashtags",
            accessor: "hashTags",
            Cell: cell => (<div>
              {cell.value && cell.value.map((tag, i) => <NavLink key={i} to={{ pathname: '/globalSearch', search: `?searchString=${encodeURIComponent('#' + tag)}` }}><span className='badge bg-primary me-1 my-1 cursor-pointer'>{tag}</span></NavLink>)}
            </div>),
            disableSortBy: true
          }
        ]
      );
    }

    setColumns(elementColumns);
  }, [type]);


  return <React.Fragment>
    <Helmet defer={false} title={`Assets`} />
    <Container fluid className="p-0">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h1 className="h3 mb-0">{getTitle(type)} {typeof (resultCount) === 'string' && `(${resultCount})`}</h1>
      </div>
      <Card className="bg-white p-3">
        <CommonTable setCountParent={setResultCount} apiName={type} columns={columns} />
      </Card>
    </Container>
    <DeviceLinker id={obj?.id} type={type} data={obj} device={linker} onHide={() => setObj(null)} />
  </React.Fragment>
}
export default DeviceLinkerPage