import React, { useEffect, useRef, useState } from "react";
import { Alert, Card, Col, Table } from "react-bootstrap";
import { Pie } from "react-chartjs-2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare } from "@fortawesome/free-solid-svg-icons";
import api from "../../api";
import { NavLink } from "react-router-dom";


const ContentsCountWidget = ({ name, width }) => {
    const [tableData, setTableData] = useState();
    const [pieData, setPieData] = useState();
    const [error, setError] = useState(undefined);
    const loadPromise = useRef(null);
    const controller = new AbortController();

    const loadData = () => {
        if (loadPromise.current === null) {
            // setTableData([]);
            // setPieData({});
            setError(undefined);
            loadPromise.current = api.get('zones', { signal: controller.signal })
                .then((response) => {
                    if (response.status === 200) {
                        loadTableData(response.data);
                        loadPieData(response.data);
                    }
                    else {
                        setError(response.message);
                    }
                })
                .catch((reason) => {
                    setError(reason.message ?? reason ?? "error");
                })
                .finally(() => {
                    loadPromise.current = null;
                });
        }
    };

    const loadTableData = (data) => {
        let tableDataIn = [];

        data.forEach((value, index) => {
            tableDataIn.push({
                id: value.id,
                name: value.compoundName,
                count: value.contentsCount
            });
        });

        // tableDataIn.push({ name: "Unassigned", count: data.unassignedCount });
        // tableDataIn.push({ name: "TOTAL", count: data.assignedCount + data.unassignedCount });

        setTableData(tableDataIn);
    };

    const loadPieData = (data) => {
        var labelArr = [];
        var dataArr = [];
        var backgroundColorArr = [];

        data.forEach((value, index) => {
            labelArr.push(value.compoundName);
            dataArr.push(value.contentsCount);
            backgroundColorArr.push(colours[index % 4]);
        });

        // labelArr.push("Unassigned");
        // dataArr.push(data.unassignedCount);
        // backgroundColorArr.push("#F0AD4E");

        var pieDataIn = {
            labels: labelArr,
            datasets: [
                {
                    data: dataArr,
                    backgroundColor: backgroundColorArr,
                }
            ]
        };
        setPieData(pieDataIn);
    };

    useEffect(() => {
        loadData();

        let intervalId = setInterval(() => { loadData(); }, 5000);

        return (() => {
            controller.abort();

            if (intervalId) {
                clearInterval(intervalId);
            }
        });
    }, []);


    const colours = [
        "#3B82EC",
        "#F0AD4E",
        "#D9534F",
        "#E8EAED",
    ];

    const options = {
        tooltips: {
            callbacks: {
                label: function (tooltipItem, data) {
                    let dataset = data.datasets[tooltipItem.datasetIndex];
                    let name = data.labels[tooltipItem.index]?.label;
                    let currentValue = dataset.data[tooltipItem.index];
                    return name + ': ' + currentValue;
                }
            }
        },
        plugins: {
            datalabels: {
                display: false
            }
        },
        animation: {
            animateRotate: true,
            animateScale: false,
        },
        maintainAspectRatio: false,
        cutoutPercentage: 70,
        legend: {
            display: false
        }
    };

    const getNavLinkAttributes = (row) => {
        return ({
            to: `/zones/edit?id=${row.id}`
        });
    };

    return (
        <>
            <Col key={name} lg={(width / 100 * 12).toFixed(0)} className="mb-4">
                <Card className="flex-fill w-100 h-100" style={{ minHeight: "210px" }}>
                    <Card.Title className="p-3 my-0"><h4 className="m-0">Zone Contents Count</h4></Card.Title>
                    <Card.Text className="p-3 pt-0 mt-0" as="div">
                        {
                            error ?
                                <>
                                    <Alert variant="danger">
                                        <Alert.Heading>Unable to load data</Alert.Heading>&nbsp;
                                        <div>({error})</div>
                                    </Alert>
                                </>
                                :
                                tableData && pieData &&
                                <>
                                    <div className="chart chart-xs pb-3">
                                        <Pie data={pieData} options={options} />
                                    </div>
                                    <Table striped responsive="sm" className="dataTable">
                                        <thead>
                                            <tr role="row">
                                                <th>Zone Name</th>
                                                <th>Contents Count</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {tableData.map((row, i) => {
                                                return (
                                                    <tr key={row.name} className="default_row">
                                                        <td>
                                                            <FontAwesomeIcon icon={faSquare} style={{ color: pieData.datasets[0].backgroundColor[i] }} />&nbsp;
                                                            <NavLink {...getNavLinkAttributes(row)}>{row.name}</NavLink>
                                                        </td>
                                                        <td>{row.count}</td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </Table>
                                </>
                        }
                    </Card.Text>
                </Card>
            </Col>
        </>
    );
};

export default ContentsCountWidget;
