import React, { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useAuth from "../../hooks/useAuth";
import { Button, Modal } from "react-bootstrap";
import api from "../../api";
import NotyfContext from "../../contexts/NotyfContext";
import { faHeart } from "@fortawesome/free-regular-svg-icons";
import { faHeart as faChecked } from "@fortawesome/free-solid-svg-icons";

// Add asset/person/zone to favourite
const FavouriteModal = ({id, type}) => {

  const { user, updateProfile } = useAuth();
  const [isFavourite, setFavourite] = useState(false);
  const [show, setShow] = useState(false);
  const notify = useContext(NotyfContext);

  const onSave = () => {
    setShow(true);
  }

  // eslint-disable-next-line no-extend-native
  Array.prototype.remove = function(val) {
    let index = this.indexOf(val);
    if (index > -1) {
      this.splice(index, 1);
    }
  };

  const onSaveChange = () => {
    let userCopy = JSON.parse(JSON.stringify(user));
    if (isFavourite) {
      api.delete(`accounts/favourites/${type}/${id}`).then(()=> {
        notify.open({
          type: "success",
          message: "Changes Saved"
        });
        if(type === 'assets') {
          userCopy.favouriteAssets.remove(id);
        } else if (type === 'people') {
          userCopy.favouritePeople.remove(id);
        } else if (type === 'zones') {
          userCopy.favouriteZones.remove(id);
        }
        updateProfile(userCopy);
      })
    } else {
      api.post(`accounts/favourites/${type}/${id}`).then(() => {
        notify.open({
          type: "success",
          message: "Changes Saved"
        });
        if(type === 'assets') {
          if(!userCopy.favouriteAssets) userCopy.favouriteAssets = []
          userCopy.favouriteAssets.push(id);
        } else if (type === 'people') {
          if(!userCopy.favouritePeople) userCopy.favouritePeople = []
          userCopy.favouritePeople.push(id);
        } else if (type === 'zones') {
          if(!userCopy.favouriteZones) userCopy.favouriteZones = []
          userCopy.favouriteZones.push(id);
        }
        updateProfile(userCopy);
      })
    }
    setShow(false);
  }
  useEffect(() => {
    if (user) {
      if((user.favouriteAssets && user.favouriteAssets.includes(id)) || (user.favouritePeople && user.favouritePeople.includes(id)) || (user.favouriteZones && user.favouriteZones.includes(id))) {
        setFavourite(true);
      } else {
        setFavourite(false);
      }
    }
  }, [user])

  return <React.Fragment>
    {!isFavourite ? <FontAwesomeIcon className="ms-4 text-gray cursor-pointer fa-regular" icon={faHeart} onClick={() => onSave()} /> : <FontAwesomeIcon onClick={() => onSave()} className="ms-4 text-primary cursor-pointer fa-solid" icon={faChecked} />}
    <Modal show={show} onHide={() => setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>
          Please Confirm
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {`Would you like to ${isFavourite ? 'remove' : 'add'} this ${isFavourite ? 'from' : 'to'} your favourites ? `}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={()=> onSaveChange()}>Confirm</Button>
        <Button variant="secondary" onClick={()=>setShow(false)}>Cancel</Button>
      </Modal.Footer>
    </Modal>
  </React.Fragment>
}
export default FavouriteModal