import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { Card, Container, ProgressBar } from "react-bootstrap";
import CommonTable from "../../components/tables/CommonTable";
import { NavLink } from "react-router-dom";

const CratesByCustomerWithAllocationPage = () => {

  const [resultCount, setResultCount] = useState(0);

  const getType = (type) => {
    if (type === 'asset') {
      return '/assets/edit'
    } else if (type === 'person') {
      return '/people/edit'
    } else {
      return '/zones/edit'
    }
  }

  const getThreshold = (thresholds, type) => {
    return thresholds?.find(
      (currentValue, index, arr) => {
        return currentValue.hashtag === "crate" && currentValue.type === type
      }
    )?.value ?? "-"
  }

  const getPercentage = (thresholds, type) => {
    return thresholds?.find(
      (currentValue, index, arr) => {
        return currentValue.hashtag === "crate" && currentValue.type === type
      }
    )?.percentage ?? "0"
  }

  const columns = [
    {
      Header: "",
      accessor: "icon",
      type: "icon",
      headerStyle: {
        width: '40px'
      },
      disableSortBy: true
    },
    {
      Header: "Customer",
      accessor: "compoundName",
      width: '200px',
      Cell: cell => (
        <NavLink to={{ pathname: getType(cell.row.original.type), search: `?id=${cell.row.original.id}` }}>{`${cell.value} ${cell.row.original.reference ? `(${cell.row.original.reference})` : ""}`}</NavLink>
      )
    },
    {
      Header: "Crates",
      accessor: "contentsCount",
    },
    {
      Header: "Max Allocation",
      accesor: "",
      disableSortBy: true,
      Cell: cell => (<div>{getThreshold(cell.row.original.thresholds, "allocationmax")}</div>)
    },
    {
      Header: "% Max Allocation",
      accessor: "",
      disableSortBy: true,
      className: 'w-35',
      Cell: cell => (
        <ProgressBar
          variant={`${getPercentage(cell.row.original.thresholds, 'allocationmax') > 100 ? 'danger' : 'primary'}`}
          now={getPercentage(cell.row.original.thresholds, "allocationmax")}
          label={`${getPercentage(cell.row.original.thresholds, "allocationmax")}%`}
        />)
    }
  ];

  return <React.Fragment>
    <Helmet defer={false} title="Crates By Customer with Max Allocation" />
    <Container fluid className="p-0">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h1 className="h3 mb-0">Crates By Customer with Max Allocation {typeof (resultCount) === 'string' && `(${resultCount})`}</h1>
      </div>
      <Card className="bg-white p-3">
        <CommonTable showRange={false} secFilterId={'childHashtag'} secId={'crate'} filterId={'parentHashtag'} id={'customer'} setCountParent={e => {
          setResultCount(e);
        }} apiName={`reports/distributionwiththresholds`} columns={columns} emptyMessage="No crates are at customer sites" showExport={true} />
      </Card>
    </Container>
  </React.Fragment>
}
export default CratesByCustomerWithAllocationPage