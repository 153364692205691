import React from "react";
import { matchPath } from "react-router-dom";

import SidebarNavListItem from "./SidebarNavListItem";
import SidebarNavList from "./SidebarNavList";
import useAuth from "../../hooks/useAuth";

const ReduceChildRoutes = (props) => {
  const { items, page, depth, currentRoute } = props;
  const { user } = useAuth();

  const checkHideMenu = (page) => {
    if(page.type && user[page.type] === false) {
      return true
    }
    return false
  }

  if (page.children) {
    const open = page.href
      ? !!matchPath(
        {
          path: page.href,
          end: false
        },
        currentRoute
      )
      : false;

      items.push(
        <SidebarNavListItem
          depth={depth}
          icon={page.icon}
          key={page.title}
          badge={page.badge}
          open={!!open}
          title={page.title}
          href={page.href}
          permission={page.permission}
          hide={checkHideMenu(page)}
        >
          <SidebarNavList depth={depth + 1} pages={page.children} />
        </SidebarNavListItem>
      );

  } else {
      items.push(
        <SidebarNavListItem
          depth={depth}
          href={page.href}
          icon={page.icon}
          key={page.title}
          badge={page.badge}
          title={page.title}
          permission={page.permission}
          hide={checkHideMenu(page)}
        />
      );
  }

  return items;
};

export default ReduceChildRoutes;
