import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import GoogleMapReact from "google-map-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import { Button, Modal } from "react-bootstrap";
import useAuth from "../../hooks/useAuth";
import SearchBox from "../maps/components/SearchBox";
import { globalConfig } from "../../config";
import { getDistance, radiusZoomLevel } from "../../utils/staticMethods";

let selectShape = null;
let drawingManager = null;
let centerPoint = null;
let listener1 = null;

let Geofence = ({ zone, onUpdate, tab, geoDeleted }, ref) => {

  const [mapInstance, setInstance] = useState();
  const [mapApi, setApi] = useState();
  const [showDelete, setShowDelete] = useState(false);
  const { user } = useAuth();
  useEffect(() => {
    if (tab !== "geofence") return;
    if (mapApi && mapInstance && zone.latitude && zone.longitude && !zone.polygon) {
      initLocation();
    } else if (mapApi && mapInstance && zone.latitude && zone.longitude && zone.polygon && zone.polygon.length > 0) {
      initPolygon();
    }
    if (mapApi && mapInstance) {
      initDrawPolygon();
    }
  }, [mapApi, zone.latitude, zone.longitude, zone.radius, tab]);

  useEffect(() => {
    return () => {
      mapApi && mapApi.event.removeListener(listener1);
      listener1 = null;
    };
  }, []);

  useImperativeHandle(ref, () => ({
    deletedMap: () => {
      if(selectShape) {
        selectShape.setMap(null);
        selectShape = null;
      }
      if(centerPoint) {
        centerPoint.setMap(null);
        centerPoint = null;
      }
    },
  }));

  const initPolygon = () => {
    if (selectShape && zone.latitude && zone.longitude) {
      selectShape.setMap(null);
      selectShape = null;
    }
    let firstItem = zone.polygon[0].split(",");
    let minLat = { lat: Number(firstItem[1]), lng: Number(firstItem[0]) },
      maxLat = { lat: Number(firstItem[1]), lng: Number(firstItem[0]) },
      minLng = { lat: Number(firstItem[1]), lng: Number(firstItem[0]) },
      maxLng = { lat: Number(firstItem[1]), lng: Number(firstItem[0]) };
    let arr = zone.polygon.map(item => {
      let me = item.split(",");
      let lng = Number(me[0]);
      let lat = Number(me[1]);
      if (lat > maxLat.lat) maxLat = { lat, lng };
      if (lat < minLat.lat) minLat = { lat, lng };
      if (lng > maxLng.lng) maxLng = { lat, lng };
      if (lng < minLng.lng) minLng = { lat, lng };
      return { lng, lat };
    });
    let point1 = new mapApi.LatLng(minLat);
    let point2 = new mapApi.LatLng(maxLat);
    let point3 = new mapApi.LatLng(minLng);
    let point4 = new mapApi.LatLng(maxLng);
    let d1 = getDistance(point1, point2);
    let d2 = getDistance(point3, point4);
    let d = d1 > d2 ? d1 : d2;

    selectShape = new mapApi.Polygon({
      paths: arr,
      strokeColor: "#000000",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#242424",
      fillOpacity: 0.3,
      editable: true,
      draggable: true,
      type: "polygon"
    });
    selectShape.setMap(mapInstance);

    listener1 = selectShape.addListener("dragend", (e) => {
      addCenterPointForPolygon();
    });
    mapInstance.panTo({ lat: Number(zone.latitude), lng: Number(zone.longitude) });
    let keys = Object.keys(radiusZoomLevel).reverse();
    let value = 1000000;
    let index = 0;
    for (let i = 0; i < keys.length; i++) {
      let v = Math.abs(radiusZoomLevel[keys[i]] - d);
      if (v < value) {
        value = v;
        index = keys[i];
      }
    }
    addCenterPointForPolygon(Number(zone.latitude), Number(zone.longitude));
    mapInstance.setZoom(Number(index));
  };

  const initLocation = () => {
    if (selectShape && zone.latitude && zone.longitude && zone.radius) {
      selectShape.setMap(null);
      selectShape = null;
      mapInstance.panTo({ lat: Number(zone.latitude), lng: Number(zone.longitude) });
    } else if (selectShape && (!zone.latitude || !zone.longitude || !zone.radius)) {
      selectShape.setMap(null);
      selectShape = null;
      return;
    }
    selectShape = new mapApi.Circle({
      strokeColor: "#000000",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#242424",
      fillOpacity: 0.3,
      type: "circle",
      map: mapInstance,
      center: new mapApi.LatLng(Number(zone.latitude), Number(zone.longitude)),
      radius: Number(zone.radius),
      editable: true
    });

    mapInstance.panTo({ lat: Number(zone.latitude), lng: Number(zone.longitude) });
    let keys = Object.keys(radiusZoomLevel).reverse();
    let value = 1000000;
    let index = 0;
    for (let i = 0; i < keys.length; i++) {
      let v = Math.abs(radiusZoomLevel[keys[i]] - Number(zone.radius) * 2);
      if (v < value) {
        value = v;
        index = keys[i];
      }
    }
    mapInstance.setZoom(Number(index) -1);
  };

  const initDrawPolygon = () => {
    if (drawingManager) {
      drawingManager.setDrawingMode(null);
      drawingManager.setMap(null);
      drawingManager = null;
    }
    let polyOptions = {
      strokeWeight: 0,
      fillOpacity: 0.45,
      editable: true,
      draggable: true
    };
    drawingManager = mapApi.drawing.DrawingManager && new mapApi.drawing.DrawingManager({
      drawingModes: [
        mapApi.drawing.OverlayType.CIRCLE,
        mapApi.drawing.OverlayType.POLYGON,
        mapApi.drawing.OverlayType.RECTANGLE
      ],
      drawingControlOptions: {
        position: mapApi.ControlPosition.TOP_CENTER,
        drawingModes: [
          mapApi.drawing.OverlayType.CIRCLE,
          mapApi.drawing.OverlayType.POLYGON
        ]
      },
      polylineOptions: {
        editable: true,
        draggable: true
      },
      circleOptions: polyOptions,
      polygonOptions: polyOptions,
      map: mapInstance
    });

    mapApi.event.addListener(drawingManager, "overlaycomplete", function(e) {
      drawingManager.setDrawingMode(null);
      centerPoint && centerPoint.setMap(null);
      centerPoint = null;
      if (e.type !== mapApi.drawing.OverlayType.MARKER) {
        // Switch back to non-drawing mode after drawing a shape.
        let newShape = e.overlay;
        newShape.type = e.type;
        selectShape && selectShape.setMap(null);
        selectShape = newShape;
        if (newShape.type === mapApi.drawing.OverlayType.POLYGON) {
          addCenterPointForPolygon();
          listener1 = selectShape.addListener("dragend", (e) => {
            addCenterPointForPolygon();
          });
        } else {
          mapApi.event.removeListener(listener1);
          listener1 = null;
        }
      }
    });
  };

  const addCenterPointForPolygon = (lat, lng) => {
    if (centerPoint) {
      centerPoint.setMap(null);
      centerPoint = null;
    }
    if (lat && lng) {
      centerPoint = new mapApi.Marker({
        draggable: true,
        position: { lat, lng },
        map: mapInstance,
        icon: {
          path: mapApi.SymbolPath.CIRCLE,
          fillColor: "#00F",
          fillOpacity: 0.6,
          strokeColor: "#00A",
          strokeOpacity: 0.9,
          strokeWeight: 1,
          scale: 5
        }
      });
    } else {
      let v = selectShape.getPath();
      let pathArray = [];
      let bounds = new mapApi.LatLngBounds();
      for (let i = 0; i < v.getLength(); i++) {
        let xy = v.getAt(i);
        let filter = pathArray.filter(item => {
          let spit = item.split(",");
          return spit[0] === xy.lng().toString() && spit[1] === xy.lat().toString();
        });
        if (filter.length === 0) {
          pathArray.push(xy.lng() + "," + xy.lat());
          bounds.extend(new mapApi.LatLng(xy.lat(), xy.lng()));
        }
      }
      let end = pathArray[0].split(",");
      bounds.extend(new mapApi.LatLng(end[1], end[0]));
      let latitude = bounds.getCenter().lat();
      let longitude = bounds.getCenter().lng();
      centerPoint = new mapApi.Marker({
        draggable: true,
        position: { lat: latitude, lng: longitude },
        map: mapInstance,
        icon: {
          path: mapApi.SymbolPath.CIRCLE,
          fillColor: "#00F",
          fillOpacity: 0.6,
          strokeColor: "#00A",
          strokeOpacity: 0.9,
          strokeWeight: 1,
          scale: 5
        }
      });
    }
  };

  const apiHasLoaded = (map, maps) => {
    if (!map || !maps) return;
    window.mapApi = maps;
    window.mapInstance = map;
    setInstance(map);
    setApi(maps);
    map.setOptions({
      fullscreenControl: true,
      mapTypeControl: true,
      mapTypeId: maps.MapTypeId.ROADMAP,
      scaleControl: true,
      gestureHandling: "cooperative",
      streetViewControl: true,
      mapTypeControlOptions: {
        position: maps.ControlPosition.LEFT_BOTTOM
      }
    });
  };


  const onSave = () => {
    let obj = JSON.parse(JSON.stringify(zone));
    if (selectShape && selectShape.type !== "polygon") {
      obj.latitude = selectShape.center.lat().toString();
      obj.longitude = selectShape.center.lng().toString();
      obj.radius = selectShape.radius.toString();
      obj.polygon = null;
    } else if (selectShape && selectShape.type === "polygon") {
      let v = selectShape.getPath();
      let bounds = new mapApi.LatLngBounds();
      let pathArray = [];
      for (let i = 0; i < v.getLength(); i++) {
        let xy = v.getAt(i);
        let filter = pathArray.filter(item => {
          let spit = item.split(",");
          return spit[0] === xy.lng().toString() && spit[1] === xy.lat().toString();
        });
        if (filter.length === 0) {
          pathArray.push(xy.lng() + "," + xy.lat());
          bounds.extend(new mapApi.LatLng(xy.lat(), xy.lng()));
        }
      }
      let end = pathArray[0].split(",");
      bounds.extend(new mapApi.LatLng(end[1], end[0]));
      obj.latitude = centerPoint.getPosition().lat().toString();
      obj.longitude = centerPoint.getPosition().lng().toString();
      obj.polygon = pathArray;
      obj.radius = null;
    }
    onUpdate(obj);
  };

  const onCancel = () => {
    if (mapApi && mapInstance && selectShape && zone.radius) {
      initLocation();
    } else if (mapApi && mapInstance && selectShape && zone.polygon) {
      initPolygon();
    } else if (mapApi && mapInstance && selectShape && !zone.radius && !zone.polygon) {
      selectShape.setMap(null);
      selectShape = null;
      if(centerPoint) {
        centerPoint.setMap(null);
        centerPoint = null;
      }
    }
  };

  const onDelete = () => {
    setShowDelete(true);
  };

  const handleDelete = () => {
    if (selectShape) {
      selectShape.setMap(null);
      selectShape = null;
    }
    if (centerPoint) {
      centerPoint.setMap(null);
      centerPoint = null;
    }
    let obj = JSON.parse(JSON.stringify(zone));
    obj.latitude = null;
    obj.longitude = null;
    obj.radius = null;
    obj.polygon = null;
    onUpdate(obj);
    setShowDelete(false);
    geoDeleted()
  };

  return (
    <React.Fragment>
      <h4><FontAwesomeIcon icon={faGlobe} /> GPS Geofence</h4>
      {user?.editZones && <div className="mt-2 mb-2 d-flex align-items-center">
        <span>Click on the map to add or move this zone.</span>
        <Button onClick={() => onSave()} className="ms-1">Save</Button>
        <Button onClick={() => onCancel()} className="ms-2" variant="secondary">Cancel</Button>
        <Button onClick={() => onDelete()} className="ms-2" variant="danger">Delete</Button>
      </div>}
      <div style={{ height: 500, width: "100%" }}>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: globalConfig.googleMapKey,
            libraries: ["places", "geometry", "drawing", "visualization"]
          }}
          center={{
            lat: Number(zone.latitude) || 50,
            lng: Number(zone.longitude) || 10
          }}
          zoom={zone.latitude ? 15 : 5}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => apiHasLoaded(map, maps)}
        >
          {mapInstance && mapApi && <SearchBox map={mapInstance} mapApi={mapApi} />}
        </GoogleMapReact>
      </div>
      <Modal show={showDelete} onHide={() => setShowDelete(false)}>
        <Modal.Header closeButton><Modal.Title>Please Confirm</Modal.Title></Modal.Header>
        <Modal.Body>
          <p>Are you sure that you wish to permanently delete this Geofence?</p>
          <p>Deletions are not reversible.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => handleDelete()}>Delete</Button>
          <Button variant="secondary" onClick={() => setShowDelete(false)}>Cancel</Button>
        </Modal.Footer>

      </Modal>
    </React.Fragment>
  );
};
export default Geofence = forwardRef(Geofence);