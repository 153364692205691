import { Card } from "react-bootstrap";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignInAlt, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { faClock, faDotCircle } from "@fortawesome/free-regular-svg-icons";
import moment from "moment-timezone";
import { NavLink } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import { Package, Radio, Wifi } from "react-feather";
import { dateFromNow } from "../utils/staticMethods";

const LastAttendance = ({ profile, title, detail }) => {

  const lastAttendance = profile.lastAttendance || {};
  const { user } = useAuth();

  return <Card className={`mb-0 ${(!title && !detail) ? 'border-top' : ''}`}>
    <Card.Body className={detail ? 'p-0' : ''}>
      <ul className="list-unstyled col-sm-12">
        {title ? <li data-toggle="tooltip" title="" data-placement="right">
          <h6>{title}</h6>
        </li> : <li data-toggle="tooltip" title="" data-placement="right">
          <h6>{lastAttendance.completionStatus ? "LAST ATTENDANCE" : "LIVE ATTENDANCE"}</h6>
        </li>}
        <li data-toggle="tooltip" title="" data-placement="right">
          {lastAttendance.parentType === "zone" ? <FontAwesomeIcon icon={faDotCircle} fixedWidth
            className="me-1" /> : <Package size={16} className={'me-1'} />}&nbsp;
          <NavLink to={{ pathname: `/${lastAttendance.parentType === 'person' ? 'people' : lastAttendance.parentType + 's'}/edit`, search: `?id=${lastAttendance.parentId}` }}
            state={{ id: lastAttendance.parentId }}>{lastAttendance.parentName}</NavLink>
        </li>
        <li data-toggle="tooltip" title="" data-placement="right">
          <FontAwesomeIcon icon={faSignInAlt} fixedWidth className="me-1" />&nbsp;
          <span>Arrived: {moment(lastAttendance.start).format("HH:mm")}</span>
          {lastAttendance.shift && <span>{moment(lastAttendance.shift.start).format("HH:mm")}</span>}
        </li>
        {!lastAttendance.completionStatus && lastAttendance.shift && <li>
          <FontAwesomeIcon icon={faSignOutAlt} fixedWidth className="me-1" />&nbsp;
          <span>Due Out: {moment(lastAttendance.shift.end).format("HH:mm")}</span>
        </li>}
        {!lastAttendance.completionStatus ? <>{!title && <li>
          <FontAwesomeIcon icon={faClock} fixedWidth className="me-1" />&nbsp;
          <span>{dateFromNow(lastAttendance.start)}</span>
        </li>}</> : <>
          <li>
            <FontAwesomeIcon icon={faSignOutAlt} fixedWidth className="me-1" />&nbsp;
            <span>Departed: {lastAttendance.end.indexOf('0001-01-01') > -1 ? 'Missed' : moment(lastAttendance.end).format("HH:mm")}</span>
            {lastAttendance.shift && <span>{moment(lastAttendance.shift.end).format("HH:mm")}</span>}
          </li>
          {!title && <li>
            <FontAwesomeIcon icon={faClock} fixedWidth className="me-1" />&nbsp;
            <span>{dateFromNow(lastAttendance.end)}</span>
          </li>}
        </>}
        {lastAttendance.deviceName && <>
          <li data-toggle="tooltip" title="" data-placement="right">
            {lastAttendance.deviceType === "reader" ? <Wifi size={16} fixedWidth className="me-1" /> : <Radio size={16} fixedWidth className="me-1" />}&nbsp;
            {user?.role === 'Root' ? <NavLink to={{ pathname: `/${lastAttendance.deviceType + 's'}/edit`, search: `?id=${lastAttendance.deviceId}` }} >{lastAttendance.deviceName}</NavLink> : <span>{lastAttendance.deviceName}</span>}
          </li>
          {lastAttendance.lastReport && lastAttendance.lastReport.indexOf('0001-01-01') < 0 && <li data-toggle="tooltip" title="" data-placement="right">
            <FontAwesomeIcon icon={faClock} fixedWidth className="me-1" />&nbsp;
            <span>{dateFromNow(lastAttendance.lastReport)}</span>
          </li>}
        </>}
      </ul>
    </Card.Body>
  </Card>;
};
export default LastAttendance;