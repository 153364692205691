import React from "react";

import SidebarNavList from "./SidebarNavList";
import useAuth from "../../hooks/useAuth";

const SidebarNavSection = (props) => {
  const { title, pages, type, className, ...rest } = props;
  const { user } = useAuth()

  return (
    <React.Fragment {...rest}>
      {title && user[type] !== false && <li className="sidebar-header">{title}</li>}
      <SidebarNavList pages={pages} depth={0} />
    </React.Fragment>
  );
};

export default SidebarNavSection;
