import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";

import ResetPassword from "../../components/auth/ResetPassword";
import "../../assets/scss/3-components/_signIn.scss";
import "../../assets/scss/3-components/_video.scss";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import AuthWrapper from "../../components/auth/AuthWrapper";
import { globalConfig } from "../../config";

const ResetPasswordPage = () => {

  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    if (isAuthenticated) navigate('/');
  }, [isAuthenticated,]);

  return (
    <React.Fragment>
      <Helmet defer={false} title={globalConfig.productName.toUpperCase().concat(" Reset Password")} />
      <AuthWrapper title={"Reset password"} subTitle={"Enter your email to reset your password"}>
          <div className="m-sm-1">
            <div className="mb-4">Please enter your email address. A password reset email will be sent to this address, if
              it is registered.
            </div>
            <ResetPassword />
          </div>
      </AuthWrapper>
    </React.Fragment>
  );
};

export default ResetPasswordPage;
