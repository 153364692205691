import React from "react";
import SrlAssets from "../srl/pages/SrlAssets";
import Assets from "../pages/assets/Assets";
import useSrlHelper from "../hooks/useSrlHelper";

const AssetsWrapper = () => {
    const { isSrl } = useSrlHelper();

    return (
        <>
            {
                (isSrl()) ?
                    <>
                        <SrlAssets></SrlAssets>
                    </> :
                    <>
                        <Assets></Assets>
                    </>
            }
        </>
    );
};

export default AssetsWrapper;
