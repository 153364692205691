import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import { Alert, Button, Card, Form, Modal } from "react-bootstrap";
import api from "../../api";
import { getName } from "../../utils/staticMethods";

const SrlNewAsset = (props) => {
  const { showModal, closeModal, categories } = props;
  const [apiName] = useState("assets");
  const navigate = useNavigate();

  return (
    <Modal show={showModal} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>{`New ${getName(apiName)}`}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Card className="shadow-none">
          <Card.Body>
            <Formik
              initialValues={{
                submit: false,
                assetOwner: "SRL",
                fleetNumber: "",
                productName: "",
                customerFleetNumber: "",
              }}
              validationSchema={Yup.object().shape({
                assetOwner: Yup.string()
                  .max(255)
                  .matches(/^[^#]*$/, "'#' is a protected character used for hashtag searches and cannot be used here")
                  .required("Asset Owner is required"),
                fleetNumber: Yup.string()
                  .max(255)
                  .matches(/^[^#]*$/, "'#' is a protected character used for hashtag searches and cannot be used here")
                  .required("SRL Fleet Number is required"),
                productName: Yup.string()
                  .max(255).
                  required("SRL Product Name is required"),
              })}
              onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                try {
                  api.post(apiName, {
                    primaryName: "",
                    secondaryName: "",
                    suppString1: values.assetOwner,
                    fleetNumber: values.fleetNumber,
                    category: values.productName,
                    suppString2: values.customerFleetNumber,
                  })
                    .then(res => {
                      navigate(`/${apiName}/edit?id=${res.data.id}`);
                    })
                } catch (error) {
                  const message = error.message || "Something went wrong";
                  setStatus({ success: false });
                  setErrors({ submit: message });
                  setSubmitting(false);
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
              }) => (
                <Form onSubmit={handleSubmit}>
                  {errors.submit && (
                    <Alert className="my-3" variant="danger">
                      <div className="alert-message">{errors.submit}</div>
                    </Alert>
                  )}

                  <Form.Group className="mb-3">
                    <Form.Label>Asset Owner</Form.Label>
                    <Form.Control
                      size="md"
                      type="text"
                      name="assetOwner"
                      value={values.assetOwner}
                      isInvalid={Boolean(touched.assetOwner && errors.assetOwner)}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {!!touched.assetOwner && (
                      <Form.Control.Feedback type="invalid">
                        {errors.assetOwner}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>SRL Fleet Number</Form.Label>
                    <Form.Control
                      size="md"
                      type="text"
                      name="fleetNumber"
                      value={values.fleetNumber}
                      isInvalid={Boolean(touched.fleetNumber && errors.fleetNumber)}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {!!touched.fleetNumber && (
                      <Form.Control.Feedback type="invalid">
                        {errors.fleetNumber}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formGroupHashtag">
                    <Form.Label>SRL Product Name</Form.Label>
                    <Form.Select name="productName" value={values.productName} onChange={handleChange} onBlur={handleChange} >
                      <option value="">please select</option>
                      {categories.map((v, i) => { return (<option key={v.name} value={v.name}>{v.name}</option>) })}
                    </Form.Select>
                    {touched.productName && <><span className="text-danger">{errors.productName}</span></>}
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Customer Fleet Number</Form.Label>
                    <Form.Control
                      size="md"
                      type="text"
                      name="customerFleetNumber"
                      value={values.customerFleetNumber}
                      isInvalid={Boolean(touched.customerFleetNumber && errors.customerFleetNumber)}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {!!touched.customerFleetNumber && (
                      <Form.Control.Feedback type="invalid">
                        {errors.customerFleetNumber}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>


                  <div className="mt-3">
                    <Button
                      type="submit"
                      variant="primary"
                      size="md"
                      disabled={isSubmitting}
                    >
                      Next
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </Card.Body>
        </Card>
      </Modal.Body>
    </Modal>
  );
}

export default SrlNewAsset;