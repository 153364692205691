import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import { Alert, Button, Card, Form } from "react-bootstrap";
import api from "../../api";
import NotyfContext from "../../contexts/NotyfContext";

const NewDevice = ({apiName, type='create', onDelete, data, onUpdated}) => {

  const navigate = useNavigate();
  const notify = useContext(NotyfContext);

  return (
    <Card>
      <Card.Body>
        <Formik
          enableReinitialize
          initialValues={{
            productName: data?.productName || '',
            model: data?.model || '',
            id: data?.id || null,
            submit: false
          }}
          validationSchema={Yup.object().shape({
            productName: Yup.string()
              .max(255)
              .required("Customer Name is required"),
            model: Yup.string().max(255),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              if(type === 'create') {
                api.post(apiName, {
                  productName: values.productName,
                  model: values.model,
                })
                  .then(res => {
                    navigate(`/${apiName}/edit?id=${res.data.id}`);
                  })
              } else {
                api.put(apiName, {
                  productName: values.productName,
                  model: values.model,
                  id: data.id
                })
                  .then(res => {
                    notify.open({
                      type: "success",
                      message: "Changes Saved"
                    });
                    onUpdated && onUpdated(res.data)
                  })
              }

            } catch (error) {
              const message = error.message || "Something went wrong";
              setStatus({ success: false });
              setErrors({ submit: message });
              setSubmitting(false);
            }
          }}
        >
          {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
            <Form onSubmit={handleSubmit}>
              {errors.submit && (
                <Alert className="my-3" variant="danger">
                  <div className="alert-message">{errors.submit}</div>
                </Alert>
              )}

              <Form.Group className="mb-3">
                <Form.Label>Product Name</Form.Label>
                <Form.Control
                  size="md"
                  type="text"
                  name="productName"
                  value={values.productName}
                  isInvalid={Boolean(touched.productName && errors.productName)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {!!touched.productName && (
                  <Form.Control.Feedback type="invalid">
                    {errors.productName}
                  </Form.Control.Feedback>
                )}
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Manufacturers Model Name</Form.Label>
                <Form.Control
                  size="md"
                  type="text"
                  name="model"
                  value={values.model}
                  isInvalid={Boolean(touched.model && errors.model)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {!!touched.model && (
                  <Form.Control.Feedback type="invalid">
                    {errors.model}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              {type === 'create' && <div className="mt-3">
                <Button
                  type="submit"
                  variant="primary"
                  size="md"
                  disabled={isSubmitting}
                >
                  Next
                </Button>
              </div>}
              {type === 'edit' && <div className="mt-3">
                <Button
                  type="submit"
                  variant="primary"
                  size="md"
                  disabled={isSubmitting}
                >
                  Save
                </Button>
                <Button
                  variant="danger"
                  className="ms-1"
                  size="md"
                  onClick={() => {onDelete && onDelete()}}
                >
                  Delete
                </Button>
              </div>}
            </Form>
          )}
        </Formik>
      </Card.Body>
    </Card>
  );
}

export default NewDevice;