import React, { forwardRef } from "react";
import { NavLink } from "react-router-dom";

import { Badge, Collapse } from "react-bootstrap";
import useAuth from "../../hooks/useAuth";

const CustomRouterLink = forwardRef((props, ref) => (
  <React.Fragment ref={ref}>
    <NavLink {...props} />
  </React.Fragment>
));

const SidebarNavListItem = (props) => {
  const {
    title,
    href,
    depth = 0,
    children,
    icon: Icon,
    badge,
    open: openProp = false,
    permission,
    hide
  } = props;

  const [open, setOpen] = React.useState(openProp);
  const { user } = useAuth();

  const handleToggle = () => {
    setOpen((state) => !state);
  };

  if (permission && user?.role && permission !== user.role) {
    return null;
  }

  if (children && !hide) {
     return (
      <li className={`sidebar-item ${open ? "active" : ""}`}>
        <span
          className={`sidebar-link cursor-pointer ${open ? "" : "collapsed"}`}
          data-bs-toggle="collapse"
          aria-expanded={open ? "true" : "false"}
          depth={depth}
          onClick={handleToggle}
        >
          {Icon && <Icon className="feather align-middle" />}{" "}
          <span className="align-middle" depth={depth}>
            {title}
          </span>
          {badge && (
            <Badge className="badge-sidebar-primary" bg="" size={18}>
              {badge}
            </Badge>
          )}
          <div />
        </span>
        <Collapse in={open}>
          <ul className="sidebar-dropdown list-unstyled">{children}</ul>
        </Collapse>
      </li>
    );
  }
  else if (!children && !hide) {
    return (
      <li className="sidebar-item">
        <CustomRouterLink
          depth={depth}
          to={href}
          activeclassname="active"
          className="sidebar-link"
        >
          {Icon && <Icon className="feather align-middle" />}{" "}
          <span className="align-middle" depth={depth}>
          {title}
        </span>
          {badge && (
            <Badge className="badge-sidebar-primary" bg="" size={18}>
              {badge}
            </Badge>
          )}
        </CustomRouterLink>
      </li>
    );
  }
  return null
};

export default SidebarNavListItem;
