import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { Card, Container } from "react-bootstrap";
import CommonTable from "../../components/tables/CommonTable";
import { NavLink, useLocation } from "react-router-dom";
import * as queryString from "query-string";
import { MapPin, Moon } from "react-feather";
import { dateFromNow } from "../../utils/staticMethods";

const DeploymentPage = () => {

  const [resultCount, setResultCount] = useState(0);
  const location = useLocation();
  const { type } = queryString.parse(location.search);

  const getName = (type) => {
    if(type === 'Deployed') {
      return 'reports/deployedassets'
    } else if (type === 'Depots') {
      return 'reports/depots'
    } else if (type === 'Other') {
      return 'reports/otherassets'
    }
  }

  const apiName = getName(type);

  const columns = type === 'Depots' ? [
    {
      Header: "",
      accessor: "icon",
      type: "icon",
      disableSortBy: true
    },
    {
      Header: "Name (Ref)",
      accessor: "compoundName",
      type: "link",
      link: `/zones/edit`,
      stateId: "id"
    },
    {
      Header: "Contents",
      accessor: "contentsCount",
      disableSortBy: true
    },
    {
      Header: "Hashtags",
      accessor: "hashTags",
      type: "hashtag",
      Cell: cell => (<div>
        {cell.value && cell.value.map((tag, i) =>  <NavLink key={i} to={{pathname: '/globalSearch', search: `?searchString=${encodeURIComponent('#' + tag)}`}}><span className='badge bg-primary me-1 my-1 cursor-pointer'>{tag}</span></NavLink>)}
      </div>),
      disableSortBy: true
    }
  ] : [
    {
      Header: "",
      accessor: "icon",
      type: "icon",
      disableSortBy: true
    },
    {
      Header: "Name (Ref)",
      accessor: "compoundName",
      type: "link",
      link: `/assets/edit`,
      stateId: "id"
    },
    {
      Header: "Last Seen",
      accessor: "lastSeen",
      Cell: (cell) => {
        if (cell.value && cell.value.indexOf("0001-01-01") === -1) {
          return <><MapPin width={14} height={14} className="me-1" />{dateFromNow(cell.value)}</>;
        }
        return <span><Moon width={14} height={14} className="me-1" /> Waiting...</span>;
      }
    },
    {
      Header: "Hashtags",
      accessor: "hashTags",
      Cell: cell => (<div>
        {cell.value && cell.value.map((tag, i) =>  <NavLink key={i} to={{pathname: '/globalSearch', search: `?searchString=${encodeURIComponent('#' + tag)}`}}><span className='badge bg-primary me-1 my-1 cursor-pointer'>{tag}</span></NavLink>)}
      </div>),
      disableSortBy: true
    }
  ]

  return  <React.Fragment>
    <Helmet defer={false}  title={type} />
    <Container fluid className="p-0">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h1 className="h3 mb-0">{type} {typeof(resultCount) === 'string' && `(${resultCount})`}</h1>
      </div>
      <Card className="bg-white p-3">
        <CommonTable setCountParent={setResultCount} apiName={apiName} columns={columns}/>
      </Card>
    </Container>
  </React.Fragment>
}
export default DeploymentPage